@charset "UTF-8";
@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto-Light.ttf) format("truetype");
  font-weight: 200; }

@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto-Regular.ttf) format("truetype");
  font-weight: normal; }

@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto-LightItalic.ttf) format("truetype");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto-Bold.ttf) format("truetype");
  font-weight: bold; }

@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto-Medium.ttf) format("truetype");
  font-weight: 500; }

@font-face {
  font-family: Arial;
  src: url(../fonts/ARIALN.TTF) format("truetype");
  font-weight: normal; }

@font-face {
  font-family: Arial;
  src: url(../fonts/ARIALNB.TTF) format("truetype");
  font-weight: bold; }

img {
  max-width: 100%; }

a, .about-shops span {
  transition-duration: .2s;
  color: #00adee; }
  a:hover, .about-shops span:hover {
    color: #00adee; }
  a:focus, .about-shops span:focus {
    text-decoration: none;
    color: inherit;
    outline: none; }

h1 {
  margin-top: 0;
  margin-bottom: 19px;
  font-size: 26px;
  font-weight: bold; }

h2 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px; }

html {
  overflow-x: hidden;
  width: 100%; }

p {
  font-size: 16px;
  line-height: 24px;
  color: #333;
  letter-spacing: 0; }

iframe {
  border: none; }

* {
  outline: none; }

input[type="text"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
input[type="tel"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
input[type="search"]:-ms-input-placeholder {
  color: #777; }

.container {
  width: 1200px; }

.inherits {
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  text-decoration: inherit; }

.caption {
  font-weight: bold;
  font-size: 26px;
  line-height: 1.5;
  letter-spacing: 0; }

button {
  transition-duration: .1s; }

.flex {
  display: flex; }
  .flex > * {
    display: inline-block; }

.overflow {
  overflow: hidden; }

body {
  position: relative;
  font-family: Roboto, Arial, sans-serif;
  letter-spacing: .02em; }

.fa-instagram:hover {
  background: url(../img/instagramm-bg.png) center no-repeat; }

.fa-google-plus:hover {
  background-color: #bb3b02; }

.fa-odnoklassniki:hover {
  background-color: #ff8226; }

.fa-twitter:hover {
  background-color: #00adee; }

.fa-youtube-play:hover {
  background-color: red; }

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="password"],
input[type="search"] {
  width: 100%;
  height: 34px;
  border: 1px solid #999;
  border-radius: 3px;
  font-weight: normal;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 13px;
  color: #333; }

.btn.btn--blue-transparent {
  display: inline-block;
  height: 40px;
  padding: 0  42px 0 55px;
  color: #fff;
  line-height: 40px;
  background: url(../img/buy-cart.png) 16px 7px no-repeat;
  background-color: #39b455;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  border: none;
  white-space: nowrap; }
  .btn.btn--blue-transparent:hover {
    background-color: #32a14b;
    text-decoration: none;
    color: #fff; }

label {
  font-weight: normal;
  position: relative; }

.red {
  color: #ed3b4d; }

.ui-selectmenu-button.ui-button {
  background-color: #fff;
  width: 100%;
  height: 34px;
  border-color: #e6e6e6;
  color: #333;
  font-size: 13px;
  line-height: 21px;
  padding-left: 17px; }
  .ui-selectmenu-button.ui-button:active {
    color: #333; }
  .ui-selectmenu-button.ui-button .ui-selectmenu-icon.ui-icon {
    background: url(../img/popup-sprites.png) 3px -13px no-repeat; }

.ui-menu .ui-menu-item-wrapper {
  height: 35px;
  line-height: 29px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 17px; }

.ui-state-active, .ui-widget-content .ui-state-active {
  background-color: #fafafa;
  color: #00adee;
  height: 37px;
  border-color: #d0cfcf;
  line-height: 29px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 17px; }

.form-button {
  width: 100%;
  display: inline-block;
  background-color: #00adee;
  color: #fff;
  border: 1px solid #00adee;
  border-radius: 5px;
  height: 34px;
  line-height: 32px;
  font-size: 13px;
  text-transform: uppercase;
  transition-duration: .2s;
  text-align: center; }
  .form-button:hover {
    background-color: #fff;
    text-decoration: none;
    color: #00adee; }
  .form-button:active {
    box-shadow: inset 4px 4px 2px 0 rgba(0, 0, 0, 0.2); }

.text-16 {
  font-size: 16px; }

.request {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0;
  text-align: center; }
  .request__icon {
    width: 47px;
    height: 47px;
    margin-bottom: 25px;
    background: url(../img/form-request.png) center no-repeat; }

.checkbox {
  font-size: 13px;
  line-height: 18px;
  padding-left: 18px;
  cursor: pointer;
  user-select: none; }
  .checkbox a, .checkbox .about-shops span, .about-shops .checkbox span {
    color: #00adee;
    text-decoration: underline; }
    .checkbox a:hover, .checkbox .about-shops span:hover, .about-shops .checkbox span:hover {
      text-decoration: none; }

.check {
  position: absolute;
  left: 0;
  top: 3px;
  display: inline-block;
  width: 11px;
  height: 11px;
  vertical-align: middle;
  border: 1px solid #333; }

input:checked + .check::before {
  content: '';
  display: block;
  width: 15px;
  height: 15px;
  position: absolute;
  bottom: -4px;
  left: -1px;
  background: url(../img/popup-sprites.png) 0 0 no-repeat; }

.columns {
  columns: 3; }

.forget {
  font-size: 16px;
  color: #00adee;
  text-decoration: underline; }
  .forget:hover {
    text-decoration: none;
    color: #00adee; }

.overton-sm {
  display: none; }

#content {
  position: relative; }
  #content > * {
    transition-duration: .2s;
    will-change: transform; }
  #content.content_270 {
    overflow: hidden; }

.card-mini .price.red {
  color: #ed3b4d; }

.green {
  color: #39b455; }

.cart-green {
  display: inline-block;
  width: 28px;
  height: 25px;
  background: url(../img/mob-cart.png) center no-repeat; }

@media all and (max-width: 1199px) {
  .container {
    width: 1024px; } }

@media all and (max-width: 1024px) {
  .overton-sm {
    display: block; }
  #content.content_270 > * {
    transform: translate(285px, 0); } }

@media all and (max-width: 1023px) {
  html.html-over {
    overflow: hidden; }
  .container {
    width: 768px; }
  h1 {
    font-size: 24px; } }

@media all and (max-width: 767px) {
  .container {
    width: 480px; } }

@media all and (max-width: 479px) {
  .container {
    width: 320px; } }

.header {
  margin-bottom: 20px;
  position: relative;
  z-index: 20; }
  .header-top {
    padding-top: 7px;
    padding-bottom: 7px;
    border-bottom: 1px solid #e6e6e6;
    background-color: #fafafa; }
    .header-top__links {
      width: 570px;
      display: inline-flex;
      justify-content: space-between; }
      .header-top__links li, .header-top__links .support-wrapper p, .support-wrapper .header-top__links p {
        padding-left: 12px;
        padding-right: 12px; }
      .header-top__links a, .header-top__links .about-shops span, .about-shops .header-top__links span {
        color: #777;
        text-decoration: underline; }
        .header-top__links a:hover, .header-top__links .about-shops span:hover, .about-shops .header-top__links span:hover {
          text-decoration: none; }
    .header-top a, .header-top .about-shops span, .about-shops .header-top span {
      font-size: 12px; }
    .header-top .flex {
      justify-content: space-between;
      align-items: center; }
    .header-top .list-inline {
      margin: 0; }
  .header__mail {
    width: 270px;
    color: #00adee;
    text-decoration: underline;
    text-decoration-style: dashed;
    line-height: 20px; }
    .header__mail:hover {
      color: #00adee;
      text-decoration: none; }
    .header__mail .fa {
      margin-right: 10px;
      font-size: 20px;
      vertical-align: middle;
      position: relative;
      top: -2px; }
  .header__socials {
    width: 270px;
    text-align: right; }
    .header__socials a i, .header__socials .about-shops span i, .about-shops .header__socials span i {
      transition-duration: .2s;
      display: inline-block;
      width: 25px;
      height: 25px;
      border-radius: 5px;
      line-height: 25px;
      text-align: center;
      color: #777;
      font-size: 15px; }
      .header__socials a i:hover, .header__socials .about-shops span i:hover, .about-shops .header__socials span i:hover {
        color: #fff; }
  .header__vk:hover {
    background-color: #3d5e8b; }
  .header__vk::before {
    display: inline-block;
    content: 'B';
    font-family: Arial;
    font-weight: bold;
    font-style: normal;
    font-size: 15px; }
  .header__facebook:hover {
    background-color: #2d409f; }
  .header__facebook::before {
    display: inline-block;
    content: 'f';
    font-family: Arial;
    font-style: normal; }
  .header__youtube-play:hover {
    background-color: red; }

.header-middle {
  padding-top: 28px; }
  .header-middle__links {
    margin: 0 30px; }
    .header-middle__links li, .header-middle__links .support-wrapper p, .support-wrapper .header-middle__links p {
      padding-left: 12px;
      padding-right: 12px; }
      .header-middle__links li a, .header-middle__links .support-wrapper p a, .support-wrapper .header-middle__links p a, .header-middle__links li .about-shops span, .about-shops .header-middle__links li span, .header-middle__links .support-wrapper p .about-shops span, .about-shops .header-middle__links .support-wrapper p span, .support-wrapper .header-middle__links p .about-shops span, .about-shops .support-wrapper .header-middle__links p span {
        display: block;
        color: #333;
        letter-spacing: .02em;
        transition-duration: 0s; }
        .header-middle__links li a:hover, .header-middle__links .support-wrapper p a:hover, .support-wrapper .header-middle__links p a:hover, .header-middle__links li .about-shops span:hover, .about-shops .header-middle__links li span:hover, .header-middle__links .support-wrapper p .about-shops span:hover, .about-shops .header-middle__links .support-wrapper p span:hover, .support-wrapper .header-middle__links p .about-shops span:hover, .about-shops .support-wrapper .header-middle__links p span:hover {
          color: #00adee;
          text-decoration: none; }

.header__logo {
  width: 272px;
  height: 43px;
  margin-left: -2px;
  background: url(../pictures/header-logo.jpg) center no-repeat; }

.header__icon {
  width: 22px;
  height: 25px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  background: url(../img/header-sprites.png) 0 0; }

.header__call-back {
  position: relative;
  top: -7px;
  padding-left: 70px;
  background: url(../img/header-phone.png) 35px 10px no-repeat;
  cursor: pointer;
  max-width: 257px; }
  .header__call-back:hover .text {
    border-bottom-color: transparent; }
  .header__call-back .text {
    display: inline-block;
    position: relative;
    transition-duration: .2s;
    top: -5px;
    border-bottom: 1px dashed #00adee; }

.header__phone {
  white-space: nowrap;
  font-size: 24px;
  font-weight: bold; }

.header__about .header__icon {
  background-position: 0 0;
  height: 23px; }

.header__about:hover .header__icon {
  background-position: 0 -30px; }

.header__sales .header__icon {
  background-position: -31px 0; }

.header__sales:hover .header__icon {
  background-position: -31px -29px; }

.header__buy .header__icon {
  background-position: -62px 0; }

.header__buy:hover .header__icon {
  background-position: -62px -28px; }

.header__publications .header__icon {
  background-position: -93px 0; }

.header__publications:hover .header__icon {
  background-position: -94px -28px; }

.header__news .header__icon {
  background-position: -124px 0;
  width: 25px;
  height: 24px; }

.header__news:hover .header__icon {
  background-position: -124px -30px; }

.header__contacts .header__icon {
  background-position: -157px 0;
  width: 24px; }

.header__contacts:hover .header__icon {
  background-position: -158px -28px; }

.header-bottom {
  padding-top: 18px; }

.header-catalog {
  margin-right: 31px;
  position: relative;
  float: left; }
  .header-catalog__label {
    z-index: 60;
    position: relative;
    width: 268px;
    height: 40px;
    line-height: 40px;
    padding-left: 28px;
    background-color: #00adee;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    border-radius: 4px;
    cursor: pointer;
    user-select: none; }
    .header-catalog__label a:hover, .header-catalog__label .about-shops span:hover, .about-shops .header-catalog__label span:hover {
      text-decoration: none;
      color: #fff; }
    .header-catalog__label .triangle {
      position: absolute;
      top: 15px;
      right: 33px;
      z-index: 10; }

.active .triangle {
  transform: scale(1, -1); }

.triangle {
  display: block;
  transition-duration: .2s;
  width: 0;
  height: 0;
  border-top: 12px solid #fff;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent; }

.active + .header-nav {
  left: -1px; }

.header-nav {
  width: 270px;
  position: absolute;
  top: -1px;
  left: -11111px;
  z-index: 50;
  background-color: #fff;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2); }
  .header-nav > ul {
    position: relative;
    margin: 0;
    z-index: 60;
    width: 270px;
    padding-top: 41px;
    float: left;
    box-shadow: 3px 0 3px rgba(0, 0, 0, 0.2); }
    .header-nav > ul > li:not(:last-child), .support-wrapper .header-nav > ul > p:not(:last-child) {
      border-bottom: 1px solid #e6e6e6; }
    .header-nav > ul > li > a, .support-wrapper .header-nav > ul > p > a, .about-shops .header-nav > ul > li > span, .support-wrapper .about-shops .header-nav > ul > p > span, .about-shops .support-wrapper .header-nav > ul > p > span {
      padding-left: 23px;
      position: relative;
      display: block;
      height: 44px;
      line-height: 44px;
      color: #333;
      background-color: #fff;
      z-index: 60; }
      .header-nav > ul > li > a::after, .support-wrapper .header-nav > ul > p > a::after, .about-shops .header-nav > ul > li > span::after, .support-wrapper .about-shops .header-nav > ul > p > span::after, .about-shops .support-wrapper .header-nav > ul > p > span::after {
        position: absolute;
        width: 3px;
        height: calc(100% + 1px);
        top: 0;
        right: -3px;
        content: '';
        display: block;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.2), transparent); }
      .header-nav > ul > li > a:hover, .support-wrapper .header-nav > ul > p > a:hover, .about-shops .header-nav > ul > li > span:hover, .support-wrapper .about-shops .header-nav > ul > p > span:hover, .about-shops .support-wrapper .header-nav > ul > p > span:hover {
        text-decoration: none;
        background-color: #fafafa; }
      .header-nav > ul > li > a .fa, .support-wrapper .header-nav > ul > p > a .fa, .about-shops .header-nav > ul > li > span .fa, .support-wrapper .about-shops .header-nav > ul > p > span .fa, .about-shops .support-wrapper .header-nav > ul > p > span .fa {
        font-size: 20px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        line-height: 44px;
        width: 44px;
        text-align: center; }

li:hover .header-category, .support-wrapper p:hover .header-category {
  left: 100%;
  opacity: 1; }

.header-category {
  position: absolute;
  top: 0;
  left: -9999px;
  width: 600px;
  min-height: 100%;
  background-color: #fff;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
  z-index: -10;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  opacity: 0; }
  .header-category__item {
    display: inline-block;
    width: 147px;
    margin-bottom: 10px;
    float: left;
    margin-left: 23px;
    margin-right: 30px;
    padding-top: 38px; }
    .header-category__item .caption {
      margin-bottom: 12px;
      min-height: 40px;
      font-size: 14px;
      line-height: 18px; }
    .header-category__item li, .header-category__item .support-wrapper p, .support-wrapper .header-category__item p {
      height: 40px;
      line-height: 40px; }
      .header-category__item li:last-child, .header-category__item .support-wrapper p:last-child, .support-wrapper .header-category__item p:last-child {
        border-bottom: 1px solid #e6e6e6; }
      .header-category__item li a, .header-category__item .support-wrapper p a, .support-wrapper .header-category__item p a, .header-category__item li .about-shops span, .about-shops .header-category__item li span, .header-category__item .support-wrapper p .about-shops span, .about-shops .header-category__item .support-wrapper p span, .support-wrapper .header-category__item p .about-shops span, .about-shops .support-wrapper .header-category__item p span {
        display: inline-block;
        vertical-align: top;
        line-height: 18px;
        color: #333; }
    .header-category__item a:hover, .header-category__item .about-shops span:hover, .about-shops .header-category__item span:hover {
      text-decoration: none;
      color: #00adee; }

.caption {
  display: block;
  font-weight: bold;
  color: #333; }

.header__search {
  width: 570px;
  float: left;
  position: relative;
  margin-right: 32px; }
  .header__search input {
    height: 40px;
    border: 2px solid #00adee;
    padding-left: 22px;
    padding-right: 100px; }
  .header__search button {
    position: absolute;
    right: 2px;
    top: 0;
    height: 40px;
    width: 98px;
    background: url(../img/search.png) center no-repeat;
    background-color: #00adee;
    border: none; }

.header-account {
  float: left;
  width: 148px;
  padding-left: 44px;
  background-color: #00adee;
  color: #fff;
  border-radius: 4px;
  padding-top: 4px;
  position: relative;
  height: 40px;
  user-select: none;
  cursor: pointer; }
  .header-account .caption {
    color: #fff;
    font-size: 16px;
    line-height: 1;
    width: auto;
    border-bottom: 1px solid #fff;
    display: inline-block; }
  .header-account .text {
    font-size: 12px;
    line-height: 1; }
  .header-account .user {
    position: absolute;
    top: 7px;
    left: 10px; }

.active .header-login {
  display: block; }

.user {
  width: 23px;
  height: 23px;
  display: inline-block;
  background: url(../img/header-sprites2.png) 0 0 no-repeat; }

.header-login {
  position: absolute;
  top: 47px;
  width: 220px;
  padding: 20px;
  left: 0;
  z-index: 10;
  background-color: #fff;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
  display: none; }
  .header-login__button {
    display: inline-block;
    text-align: center;
    line-height: 38px;
    width: 100%;
    margin-bottom: 14px;
    height: 40px;
    border: 1px solid #00adee;
    color: #00adee;
    background-color: #fff;
    font-size: 16px;
    font-weight: bold;
    border-radius: 3px;
    transition-duration: .2s; }
    .header-login__button a, .header-login__button .about-shops span, .about-shops .header-login__button span {
      display: inline-block;
      width: 100%;
      text-align: center;
      line-height: 35px;
      vertical-align: top; }
    .header-login__button:hover {
      background-color: #00adee;
      text-decoration: none;
      color: #fff; }
      .header-login__button:hover a, .header-login__button:hover .about-shops span, .about-shops .header-login__button:hover span {
        color: #fff;
        text-decoration: none; }
  .header-login .text {
    color: #777;
    margin-bottom: 4px; }
  .header-login__link {
    display: inline-block;
    text-align: center;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    border: 1px solid #00adee;
    background-color: #00adee;
    height: 40px;
    line-height: 38px;
    width: 100%;
    border-radius: 3px; }
    .header-login__link:hover {
      background-color: #fff;
      color: #00adee;
      text-decoration: none; }

.header-actions {
  margin-left: 20px;
  float: left; }
  .header-actions a, .header-actions .about-shops span, .about-shops .header-actions span {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    border: 1px solid #00adee;
    border-radius: 3px; }
    .header-actions a:first-child, .header-actions .about-shops span:first-child, .about-shops .header-actions span:first-child {
      margin-right: 12px; }
    .header-actions a .count, .header-actions .about-shops span .count, .about-shops .header-actions span .count {
      display: inline-block;
      height: 22px;
      position: absolute;
      top: -7px;
      right: -7px;
      line-height: 23px;
      color: #fff;
      background-color: #39b455;
      border-radius: 11px;
      padding-left: 7px;
      padding-right: 7px; }

.ham {
  height: 40px;
  width: 40px;
  top: -2px;
  border-radius: 3px;
  background-color: #00adee;
  line-height: 7px;
  text-align: center;
  position: relative; }
  .ham__bar {
    position: absolute;
    left: 9px;
    display: inline-block;
    width: 22px;
    height: 2px;
    background-color: #fff;
    transition-duration: .2s; }
    .ham__bar:first-child {
      top: 12px; }
    .ham__bar:nth-child(2) {
      top: 19px; }
    .ham__bar:nth-child(3) {
      top: 26px; }

.active .ham__bar:first-child {
  transform: rotate(45deg) translate(6px, 4px);
  width: 20px; }

.active .ham__bar:nth-child(2) {
  transform: scale(0, 1); }

.active .ham__bar:nth-child(3) {
  transform: rotate(-45deg) translate(6px, -4px);
  width: 20px; }

.header-button {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding-left: 28px;
  padding-right: 28px;
  border-radius: 4px;
  color: #fff;
  background-color: #39b455; }
  .header-button:hover {
    text-decoration: none;
    color: #fff; }

.heart, .cart {
  width: 30px;
  height: 30px;
  display: inline-block;
  vertical-align: middle;
  background: url(../img/header-sprites2.png) -28px 2px no-repeat; }

.cart {
  background-position: -61px 2px; }

.header-account + .header-account {
  display: none !important; }

@media all and (max-width: 1199px) {
  .header-actions {
    margin-left: 17px; }
  .header__mail {
    width: 179px; }
  .header-middle .flex {
    align-items: center; }
  .header-middle__links {
    margin-left: 5px;
    margin-right: 5px; }
    .header-middle__links li, .header-middle__links .support-wrapper p, .support-wrapper .header-middle__links p {
      padding-left: 5px;
      padding-right: 5px; }
  .header__call-back {
    padding-left: 35px;
    background-position: 0 9px;
    position: static; }
  .header__phone {
    font-size: 24px; }
  .header__logo {
    background-position: center;
    background-size: contain; }
  .header__search {
    width: 401px; } }

@media all and (max-width: 1024px) {
  .header__toggle-search {
    display: inline-block;
    float: left;
    width: 40px;
    height: 40px;
    margin-right: 12px;
    background: url(../img/search.png) center no-repeat;
    background-color: #00adee;
    border-radius: 3px; }
  .header-top {
    display: none; }
  .header-middle {
    padding-top: 23px;
    padding-bottom: 13px; }
    .header-middle .overton-sm {
      width: 205px;
      position: relative;
      z-index: 10; }
    .header-middle__links {
      display: none; }
  .header__call-back {
    display: none; }
  .header-middle .flex {
    justify-content: space-between; }
  .header-account {
    display: none; }
  .header-actions_sm .header-account {
    display: inline-block;
    width: 40px;
    padding-left: 40px;
    height: 40px; }
  .header-login {
    left: auto;
    right: 0; }
  .header-account .user {
    left: 9px; }
  .header-actions {
    margin-left: 12px; }
  .header-bottom .header-actions {
    display: none; }
  .header-catalog {
    display: none; }
  .header__search {
    width: 100%;
    display: none; }
  .header-nav > ul > li > a::after, .support-wrapper .header-nav > ul > p > a::after, .about-shops .header-nav > ul > li > span::after, .support-wrapper .about-shops .header-nav > ul > p > span::after, .about-shops .support-wrapper .header-nav > ul > p > span::after {
    display: none; }
  .header-menu_sm {
    width: 272px;
    position: absolute;
    top: 53px;
    left: -15px; }
  .mobile-cart {
    position: relative;
    padding: 16px 8px 22px 65px;
    background-color: #fafafa;
    line-height: 18px;
    text-align: left;
    box-shadow: -1px 0 0 0 rgba(51, 51, 51, 0.1) inset, -2px 0 0 0 rgba(51, 51, 51, 0.09) inset, -3px 0 0 0 rgba(51, 51, 51, 0.08) inset, -4px 0 0 0 rgba(51, 51, 51, 0.07) inset, -5px 0 0 0 rgba(51, 51, 51, 0.06) inset, -6px 0 0 0 rgba(51, 51, 51, 0.05) inset, -7px 0 0 0 rgba(51, 51, 51, 0.04) inset, -8px 0 0 0 rgba(51, 51, 51, 0.03) inset, -9px 0 0 0 rgba(51, 51, 51, 0.02) inset, -10px 0 0 0 rgba(51, 51, 51, 0.01) inset; }
    .mobile-cart__row span {
      white-space: nowrap; }
    .mobile-cart__row span:first-child {
      width: 100px;
      display: inline-block; }
    .mobile-cart .green {
      font-weight: bold; }
    .mobile-cart .cart-green {
      position: absolute;
      top: 20px;
      left: 18px; }
    .mobile-cart .header-button {
      margin-top: 18px; }
  .mobile__catalog-switcher {
    height: 41px;
    padding-left: 23px;
    line-height: 40px;
    border-top: 1px solid #fff;
    background-color: #00adee;
    color: #fff;
    font-weight: bold;
    box-shadow: -1px 0 0 0 rgba(51, 51, 51, 0.1) inset, -2px 0 0 0 rgba(51, 51, 51, 0.09) inset, -3px 0 0 0 rgba(51, 51, 51, 0.08) inset, -4px 0 0 0 rgba(51, 51, 51, 0.07) inset, -5px 0 0 0 rgba(51, 51, 51, 0.06) inset, -6px 0 0 0 rgba(51, 51, 51, 0.05) inset, -7px 0 0 0 rgba(51, 51, 51, 0.04) inset, -8px 0 0 0 rgba(51, 51, 51, 0.03) inset, -9px 0 0 0 rgba(51, 51, 51, 0.02) inset, -10px 0 0 0 rgba(51, 51, 51, 0.01) inset; }
    .mobile__catalog-switcher.active::before {
      display: inline-block;
      content: '';
      margin-right: 11px;
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-right: 7px solid #fff; }
  .active + .header-menu_sm {
    transform: scale(1, 1);
    transform-origin: 0 0; }
  .header-menu_sm {
    overflow: auto;
    position: absolute;
    transform: scale(0, 1);
    transform-origin: 0 0;
    transition-duration: .2s; }
    .header-menu_sm .header-nav {
      display: block;
      position: static;
      border-right: none;
      width: 100%; }
      .header-menu_sm .header-nav > ul {
        box-shadow: none;
        width: 100%; }
      .header-menu_sm .header-nav > ul > li, .header-menu_sm .support-wrapper .header-nav > ul > p, .support-wrapper .header-menu_sm .header-nav > ul > p {
        box-shadow: -1px 0 0 0 rgba(51, 51, 51, 0.1) inset, -2px 0 0 0 rgba(51, 51, 51, 0.09) inset, -3px 0 0 0 rgba(51, 51, 51, 0.08) inset, -4px 0 0 0 rgba(51, 51, 51, 0.07) inset, -5px 0 0 0 rgba(51, 51, 51, 0.06) inset, -6px 0 0 0 rgba(51, 51, 51, 0.05) inset, -7px 0 0 0 rgba(51, 51, 51, 0.04) inset, -8px 0 0 0 rgba(51, 51, 51, 0.03) inset, -9px 0 0 0 rgba(51, 51, 51, 0.02) inset, -10px 0 0 0 rgba(51, 51, 51, 0.01) inset;
        border-bottom: none; }
        .header-menu_sm .header-nav > ul > li > a, .header-menu_sm .support-wrapper .header-nav > ul > p > a, .support-wrapper .header-menu_sm .header-nav > ul > p > a, .header-menu_sm .about-shops .header-nav > ul > li > span, .about-shops .header-menu_sm .header-nav > ul > li > span, .header-menu_sm .support-wrapper .about-shops .header-nav > ul > p > span, .about-shops .header-menu_sm .support-wrapper .header-nav > ul > p > span, .support-wrapper .header-menu_sm .about-shops .header-nav > ul > p > span, .about-shops .support-wrapper .header-menu_sm .header-nav > ul > p > span {
          background: transparent;
          border-bottom: 1px solid rgba(55, 55, 55, 0.2);
          transition-duration: 0s; }
          .header-menu_sm .header-nav > ul > li > a.active, .header-menu_sm .support-wrapper .header-nav > ul > p > a.active, .support-wrapper .header-menu_sm .header-nav > ul > p > a.active, .header-menu_sm .about-shops .header-nav > ul > li > span.active, .about-shops .header-menu_sm .header-nav > ul > li > span.active, .header-menu_sm .support-wrapper .about-shops .header-nav > ul > p > span.active, .about-shops .header-menu_sm .support-wrapper .header-nav > ul > p > span.active, .support-wrapper .header-menu_sm .about-shops .header-nav > ul > p > span.active, .about-shops .support-wrapper .header-menu_sm .header-nav > ul > p > span.active {
            background-color: #fafafa;
            box-shadow: -1px 0 0 0 rgba(51, 51, 51, 0.1) inset, -2px 0 0 0 rgba(51, 51, 51, 0.09) inset, -3px 0 0 0 rgba(51, 51, 51, 0.08) inset, -4px 0 0 0 rgba(51, 51, 51, 0.07) inset, -5px 0 0 0 rgba(51, 51, 51, 0.06) inset, -6px 0 0 0 rgba(51, 51, 51, 0.05) inset, -7px 0 0 0 rgba(51, 51, 51, 0.04) inset, -8px 0 0 0 rgba(51, 51, 51, 0.03) inset, -9px 0 0 0 rgba(51, 51, 51, 0.02) inset, -10px 0 0 0 rgba(51, 51, 51, 0.01) inset;
            padding-left: 0;
            text-align: center; }
            .header-menu_sm .header-nav > ul > li > a.active .fa, .header-menu_sm .support-wrapper .header-nav > ul > p > a.active .fa, .support-wrapper .header-menu_sm .header-nav > ul > p > a.active .fa, .header-menu_sm .about-shops .header-nav > ul > li > span.active .fa, .about-shops .header-menu_sm .header-nav > ul > li > span.active .fa, .header-menu_sm .support-wrapper .about-shops .header-nav > ul > p > span.active .fa, .about-shops .header-menu_sm .support-wrapper .header-nav > ul > p > span.active .fa, .support-wrapper .header-menu_sm .about-shops .header-nav > ul > p > span.active .fa, .about-shops .support-wrapper .header-menu_sm .header-nav > ul > p > span.active .fa {
              display: none; }
            .header-menu_sm .header-nav > ul > li > a.active::before, .header-menu_sm .support-wrapper .header-nav > ul > p > a.active::before, .support-wrapper .header-menu_sm .header-nav > ul > p > a.active::before, .header-menu_sm .about-shops .header-nav > ul > li > span.active::before, .about-shops .header-menu_sm .header-nav > ul > li > span.active::before, .header-menu_sm .support-wrapper .about-shops .header-nav > ul > p > span.active::before, .about-shops .header-menu_sm .support-wrapper .header-nav > ul > p > span.active::before, .support-wrapper .header-menu_sm .about-shops .header-nav > ul > p > span.active::before, .about-shops .support-wrapper .header-menu_sm .header-nav > ul > p > span.active::before {
              display: inline-block;
              content: '\f104';
              font-family: FontAwesome;
              width: 44px;
              font-size: 20px; }
      .header-menu_sm .header-nav .header-category {
        position: relative;
        display: none;
        left: 0;
        width: 100%;
        opacity: 1;
        box-shadow: -1px 0 0 0 rgba(51, 51, 51, 0.1) inset, -2px 0 0 0 rgba(51, 51, 51, 0.09) inset, -3px 0 0 0 rgba(51, 51, 51, 0.08) inset, -4px 0 0 0 rgba(51, 51, 51, 0.07) inset, -5px 0 0 0 rgba(51, 51, 51, 0.06) inset, -6px 0 0 0 rgba(51, 51, 51, 0.05) inset, -7px 0 0 0 rgba(51, 51, 51, 0.04) inset, -8px 0 0 0 rgba(51, 51, 51, 0.03) inset, -9px 0 0 0 rgba(51, 51, 51, 0.02) inset, -10px 0 0 0 rgba(51, 51, 51, 0.01) inset;
        z-index: 10; }
        .header-menu_sm .header-nav .header-category__item {
          padding-top: 10px;
          width: 202px; }
      .header-menu_sm .header-nav::after {
        display: table;
        clear: both;
        content: ''; }
    .header-menu_sm .header__socials {
      padding-left: 12px;
      background-color: #313131;
      width: 100%;
      padding-top: 10px;
      padding-bottom: 18px;
      margin: 0;
      text-align: left; }
      .header-menu_sm .header__socials a, .header-menu_sm .header__socials .about-shops span, .about-shops .header-menu_sm .header__socials span {
        display: inline-block;
        background-color: #00adee;
        width: 26px;
        height: 26px;
        border-radius: 4px; }
        .header-menu_sm .header__socials a i, .header-menu_sm .header__socials .about-shops span i, .about-shops .header-menu_sm .header__socials span i {
          color: #fff; }
    .header-menu_sm .header__call-back {
      display: block;
      padding-top: 17px;
      padding-left: 51px;
      background-image: url(../img/phone-sm.png);
      background-position: 17px 25px;
      background-color: #313131;
      color: #fff;
      max-width: none; }
    .header-menu_sm .header-middle__links {
      display: block;
      background-color: #00adee;
      margin: 0;
      box-shadow: -1px 0 0 0 rgba(51, 51, 51, 0.1) inset, -2px 0 0 0 rgba(51, 51, 51, 0.09) inset, -3px 0 0 0 rgba(51, 51, 51, 0.08) inset, -4px 0 0 0 rgba(51, 51, 51, 0.07) inset, -5px 0 0 0 rgba(51, 51, 51, 0.06) inset, -6px 0 0 0 rgba(51, 51, 51, 0.05) inset, -7px 0 0 0 rgba(51, 51, 51, 0.04) inset, -8px 0 0 0 rgba(51, 51, 51, 0.03) inset, -9px 0 0 0 rgba(51, 51, 51, 0.02) inset, -10px 0 0 0 rgba(51, 51, 51, 0.01) inset; }
      .header-menu_sm .header-middle__links li, .header-menu_sm .header-middle__links .support-wrapper p, .support-wrapper .header-menu_sm .header-middle__links p {
        display: block;
        height: 37px;
        line-height: 37px; }
        .header-menu_sm .header-middle__links li .header__icon, .header-menu_sm .header-middle__links .support-wrapper p .header__icon, .support-wrapper .header-menu_sm .header-middle__links p .header__icon {
          display: inline-block;
          margin-right: 13px;
          width: 25px;
          vertical-align: middle;
          background: url(../img/sm-sprites-header.png) 0 0 no-repeat; }
        .header-menu_sm .header-middle__links li a, .header-menu_sm .header-middle__links .support-wrapper p a, .support-wrapper .header-menu_sm .header-middle__links p a, .header-menu_sm .header-middle__links li .about-shops span, .about-shops .header-menu_sm .header-middle__links li span, .header-menu_sm .header-middle__links .support-wrapper p .about-shops span, .about-shops .header-menu_sm .header-middle__links .support-wrapper p span, .support-wrapper .header-menu_sm .header-middle__links p .about-shops span, .about-shops .support-wrapper .header-menu_sm .header-middle__links p span {
          color: #fff;
          border-bottom: 1px solid #5fbde1; }
        .header-menu_sm .header-middle__links li .header__sales .header__icon, .header-menu_sm .header-middle__links .support-wrapper p .header__sales .header__icon, .support-wrapper .header-menu_sm .header-middle__links p .header__sales .header__icon {
          background-position: 0 -41px; }
        .header-menu_sm .header-middle__links li .header__buy .header__icon, .header-menu_sm .header-middle__links .support-wrapper p .header__buy .header__icon, .support-wrapper .header-menu_sm .header-middle__links p .header__buy .header__icon {
          background-position: 0 -80px; }
        .header-menu_sm .header-middle__links li .header__publications .header__icon, .header-menu_sm .header-middle__links .support-wrapper p .header__publications .header__icon, .support-wrapper .header-menu_sm .header-middle__links p .header__publications .header__icon {
          background-position: 0 -118px; }
        .header-menu_sm .header-middle__links li .header__news .header__icon, .header-menu_sm .header-middle__links .support-wrapper p .header__news .header__icon, .support-wrapper .header-menu_sm .header-middle__links p .header__news .header__icon {
          background-position: 0 -196px; }
        .header-menu_sm .header-middle__links li .header__contacts .header__icon, .header-menu_sm .header-middle__links .support-wrapper p .header__contacts .header__icon, .support-wrapper .header-menu_sm .header-middle__links p .header__contacts .header__icon {
          background-position: 0 -235px; }
  .header-nav > ul {
    padding-top: 0; } }

@media all and (max-width: 1023px) {
  .header-middle .overton-sm:first-child {
    width: 42px; }
  .header-category {
    width: 410px; } }

@media all and (max-width: 767px) {
  .header-bottom {
    padding: 0; }
  .header-account > .text {
    font-size: 0; }
  .header__logo {
    width: 125px;
    position: relative;
    left: 25px; }
  .header-actions {
    margin-left: 18px; }
  .header-actions a:first-child, .header-actions .about-shops span:first-child, .about-shops .header-actions span:first-child {
    margin-right: 18px; }
  .header__toggle-search {
    position: absolute;
    top: -1px;
    left: -230px; }
  .header-middle .overton-sm {
    width: 160px; }
  .header-category {
    width: 188px;
    max-height: 100%;
    overflow: auto; } }

@media all and (max-width: 1024px) {
  .header-menu_sm .header-nav .header-category {
    position: static;
    left: 100%; }
  .header-menu_sm {
    overflow-x: visible;
    overflow-y: auto;
    max-height: calc(100vh - 79px); } }

@media all and (max-width: 479px) {
  .header-menu_sm {
    max-height: calc(100vh - 109px); }
  .header-middle .flex {
    position: relative;
    left: 10px; }
  .header-middle {
    padding-top: 55px; }
  .header__logo {
    position: absolute;
    top: -36px;
    left: 50%;
    width: 126px;
    margin-left: -63px;
    height: 20px; }
  .header-account .caption {
    font-size: 0; }
  .header__toggle-search {
    position: static;
    margin-right: 19px; }
  .overton-sm.overton-sm_last {
    width: 230px; }
  .ham {
    top: -1px; }
  .header-actions a.header__favorites .count, .header-actions .about-shops span.header__favorites .count, .about-shops .header-actions span.header__favorites .count {
    background-color: #ff765b; }
  .header-actions a.header__cart .count, .header-actions .about-shops span.header__cart .count, .about-shops .header-actions span.header__cart .count {
    background-color: #ed3b4d; }
  .header-login {
    right: -88px; } }

.popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 100;
  text-align: center;
  line-height: 100vh; }

.popup-container {
  vertical-align: middle;
  line-height: 1.5;
  display: inline-block;
  position: relative;
  width: 320px;
  max-height: 100%;
  overflow: auto;
  text-align: left;
  will-change: transform;
  padding: 36px 20px 27px;
  border-radius: 5px;
  border: 1px solid #333;
  background-color: #fff; }

.popup__close {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 35px;
  background: url(../img/close.png) center no-repeat;
  cursor: pointer; }

.popup__caption {
  margin-bottom: 12px;
  font-size: 20px;
  text-align: center;
  letter-spacing: 0; }

.popup__form .text-right {
  position: relative;
  z-index: 10;
  margin-bottom: -15px; }

.popup__form label {
  display: block;
  margin-bottom: 20px;
  font-size: 16px;
  letter-spacing: 0; }

.popup__form .checkbox {
  font-size: 13px; }

.popup__form textarea {
  width: 100%;
  height: 55px;
  resize: none;
  border-radius: 4px;
  font-size: 13px;
  line-height: 18px;
  padding: 7px 13px; }

.invalid {
  border-color: #ed3b4d !important; }
  .invalid-text {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    font-size: 12px;
    color: #ed3b4d;
    letter-spacing: 0; }
  .invalid + .invalid-text {
    display: block; }

#callback {
  display: none; }

#login {
  display: none; }

.capcha {
  line-height: 57px;
  text-align: right;
  margin-bottom: 15px; }
  .capcha__img {
    float: left; }
  .capcha__refresh {
    font-size: 16px;
    color: #00adee;
    cursor: pointer;
    display: block;
    user-select: none;
    text-decoration: underline; }
    .capcha__refresh:hover {
      text-decoration: none; }
    .capcha__refresh::before {
      display: inline-block;
      content: '';
      vertical-align: middle;
      position: relative;
      top: -2px;
      margin-right: 10px;
      background: url(../img/capcha.png) center no-repeat;
      width: 18px;
      height: 18px; }

.footer-subscribe {
  height: 88px;
  line-height: 89px;
  background-color: #00adee;
  color: #fff;
  position: relative; }
  .footer-subscribe .text {
    width: 467px;
    float: left;
    position: relative;
    top: 15px;
    display: inline-block;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0;
    vertical-align: middle; }
  .footer-subscribe__form {
    display: inline-block;
    vertical-align: middle; }
    .footer-subscribe__form label {
      height: 40px;
      float: left;
      width: 490px;
      background-color: #fff;
      border-radius: 4px;
      vertical-align: middle; }
      .footer-subscribe__form label input {
        border: none;
        height: 22px;
        margin-left: 24px;
        width: 215px;
        position: relative;
        top: -24px;
        border-left: 1px solid #00adee;
        border-radius: 0;
        vertical-align: middle; }
    .footer-subscribe__form .footer__envelope {
      position: relative;
      top: -24px;
      margin-left: 30px; }
    .footer-subscribe__form button {
      float: left;
      height: 40px;
      line-height: 36px;
      margin-left: 10px;
      padding-left: 54px;
      padding-right: 54px;
      background-color: #00adee;
      border: 2px solid #fff;
      border-radius: 4px; }

.footer__envelope {
  display: inline-block;
  width: 30px;
  height: 21px;
  vertical-align: middle;
  background: url(../img/footer-envelope.png) center no-repeat; }

.footer {
  color: #939393; }
  .footer a, .footer .about-shops span, .about-shops .footer span {
    color: #939393; }
  .footer-main {
    background: url(../pictures/footer-bg.jpg) center no-repeat;
    -webkit-background-size: cover;
    background-size: cover; }
  .footer-top {
    padding-top: 35px;
    padding-bottom: 30px; }
  .footer__caption {
    margin-bottom: 25px;
    font-size: 16px;
    text-transform: uppercase; }
  .footer__list {
    margin: 0; }
    .footer__list li:not(:last-child), .footer__list .support-wrapper p:not(:last-child), .support-wrapper .footer__list p:not(:last-child) {
      margin-bottom: 10px; }
  .footer-item {
    float: left;
    width: 270px; }
    .footer-item_2 {
      width: 330px; }
    .footer-item_3 {
      width: 370px;
      padding-left: 15px; }
    .footer-item_4 {
      width: 200px; }
  .footer__phone {
    margin-bottom: 12px; }
    .footer__phone a, .footer__phone .about-shops span, .about-shops .footer__phone span {
      display: inline-block;
      width: auto;
      margin-bottom: 11px;
      text-decoration: none;
      color: #fff;
      font-size: 18px;
      line-height: 20px;
      border-bottom: 1px dashed #fff; }
      .footer__phone a:hover, .footer__phone .about-shops span:hover, .about-shops .footer__phone span:hover {
        border-color: transparent; }
  .footer__socials {
    margin: 0 0 35px -2px; }
    .footer__socials li, .footer__socials .support-wrapper p, .support-wrapper .footer__socials p {
      padding-left: 2px;
      padding-right: 19px; }
    .footer__socials i {
      display: inline-block;
      color: #fff;
      background-color: #00adee;
      width: 25px;
      height: 25px;
      border-radius: 5px;
      text-align: center;
      line-height: 25px; }
  .footer__icon {
    display: inline-block;
    vertical-align: middle;
    height: 28px;
    width: 51px;
    background-position: center;
    background-repeat: no-repeat; }
    .footer__icon:not(:last-child) {
      margin-right: 17px; }
  .footer__visa {
    background-image: url(../img/footer-visa.png); }
  .footer__master {
    background-image: url(../img/footer-master-card.png); }
  .footer__card {
    background-image: url(../img/footer-card.png); }

.footer-bottom {
  height: 39px;
  line-height: 39px;
  background-color: rgba(0, 0, 0, 0.4); }
  .footer-bottom .text-right {
    float: right; }
    .footer-bottom .text-right a, .footer-bottom .text-right .about-shops span, .about-shops .footer-bottom .text-right span {
      margin-left: 4px;
      color: #fff;
      display: inline-block;
      line-height: 16px;
      border-bottom: 1px dashed #fff; }
      .footer-bottom .text-right a:hover, .footer-bottom .text-right .about-shops span:hover, .about-shops .footer-bottom .text-right span:hover {
        text-decoration: none;
        border-bottom-color: transparent; }
  .footer-bottom .text-left {
    float: left; }

.footer__scroll-top {
  padding-right: 20px;
  display: block;
  z-index: 10;
  font-size: 0;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer; }
  .footer__scroll-top .fa {
    font-size: 20px;
    margin-right: 20px;
    vertical-align: middle; }

@media all and (min-width: 1500px) {
  .footer__scroll-top {
    font-size: 14px; } }

@media all and (max-width: 1140px) {
  .footer__scroll-top {
    display: none !important; } }

.scroll-top {
  display: none;
  width: 0;
  height: 0;
  position: sticky;
  bottom: 10px;
  right: 10px;
  z-index: 50; }
  .scroll-top button {
    width: 49px;
    position: absolute;
    left: -49px;
    top: -49px;
    height: 49px;
    padding-top: 0px;
    font-size: 30px;
    color: #fff;
    border-radius: 50%;
    background-color: #b5b4b4;
    text-align: center;
    border: none; }
    .scroll-top button:hover {
      background-color: #777; }

@media all and (max-width: 1199px) {
  .footer-subscribe .text {
    width: 380px; }
  .footer-subscribe__form label {
    width: 387px; }
  .footer-subscribe__form button {
    margin-left: 25px; }
  .footer-item {
    width: 210px; }
    .footer-item_2 {
      width: 300px; }
    .footer-item_3 {
      width: 275px; }
    .footer-item_4 {
      width: 200px; } }

@media all and (max-width: 1024px) {
  .footer-bottom__links {
    margin-left: 52px;
    float: left; }
    .footer-bottom__links a, .footer-bottom__links .about-shops span, .about-shops .footer-bottom__links span {
      font-size: 12px; } }

@media all and (max-width: 1023px) {
  .footer__socials li, .footer__socials .support-wrapper p, .support-wrapper .footer__socials p {
    padding-right: 9px; }
  .footer__icon:not(:last-child) {
    margin-right: 0; }
  .footer__caption {
    line-height: 18px; }
  .footer-subscribe .text {
    width: 310px;
    font-size: 16px;
    line-height: 18px; }
  .footer-subscribe__form label {
    width: 200px; }
  .footer-subscribe__form .footer__envelope {
    display: none; }
  .footer-subscribe__form label input {
    width: 150px;
    border-left: none;
    margin-left: 0;
    padding-left: 20px; }
  .footer-item {
    width: 25%; }
    .footer-item_4 {
      padding-left: 30px; }
  .footer__socials i {
    margin-bottom: 5px; } }

@media all and (max-width: 767px) {
  .footer {
    text-align: center; }
    .footer-top {
      background-position: center bottom;
      background-size: auto; }
    .footer-subscribe {
      height: auto;
      text-align: center; }
      .footer-subscribe .text {
        margin-bottom: 10px;
        width: 100%; }
  .footer-subscribe__form label {
    width: 150px;
    display: inline-block; }
    .footer-subscribe__form label input {
      width: 100%;
      font-size: 12px; }
  .footer-subscribe__form button {
    padding-left: 20px;
    padding-right: 20px; }
  .footer-item {
    width: 100%; }
  .footer-item_1 .footer__caption::after,
  .footer-item_2 .footer__caption::after {
    display: inline-block;
    vertical-align: middle;
    content: '+';
    font-size: 20px;
    margin-left: 10px;
    color: #fff; }
  .footer__list {
    display: none; }
  .footer__caption {
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 14px; }
    .footer__caption.active {
      color: #fff; }
      .footer__caption.active::after {
        content: '–'; }
  .active + .footer__list {
    display: block; }
  .footer-top {
    padding-top: 20px; }
  .footer-item_2 {
    padding-bottom: 13px;
    border-bottom: 1px solid #676767; }
  .footer-item_3 {
    padding-left: 0;
    padding-top: 13px; }
    .footer-item_3 .footer__caption {
      display: none; }
  .footer-item_4 {
    padding-left: 0; }
    .footer-item_4 .footer__caption {
      position: relative; }
      .footer-item_4 .footer__caption:first-child {
        top: 65px; }
      .footer-item_4 .footer__caption:nth-child(3) {
        top: -65px; }
  .footer__socials {
    position: relative;
    top: 65px; }
  .footer__methods {
    position: relative;
    top: -65px; }
  .footer__phone {
    margin-bottom: 17px;
    line-height: 16px;
    width: 266px;
    margin-left: auto;
    margin-right: auto; }
    .footer__phone:nth-child(2) {
      width: 313px; }
    .footer__phone br {
      display: none; }
    .footer__phone a, .footer__phone .about-shops span, .about-shops .footer__phone span {
      margin-bottom: 0;
      margin-right: 15px; }
      .footer__phone a::after, .footer__phone .about-shops span::after, .about-shops .footer__phone span::after {
        content: '-';
        display: inline-block;
        margin-left: 4px;
        width: 0;
        position: absolute; }
  .footer-bottom__links {
    display: none; }
  .footer-bottom {
    height: auto; }
    .footer-bottom .text-left {
      float: none;
      text-align: center;
      position: relative;
      top: 36px; }
    .footer-bottom .text-right {
      float: none;
      text-align: center;
      position: relative;
      top: -36px; }
  .footer__socials {
    margin-bottom: 5px; } }

@media all and (max-width: 479px) {
  .footer-subscribe__form button {
    margin-left: 16px;
    padding-left: 15px;
    padding-right: 15px; }
  .footer__phone:nth-child(2) {
    width: 302px; } }

.main_banner {
  margin-bottom: 25px;
  max-height: 360px;
  overflow: hidden; }
  .main_banner__item {
    height: 360px;
    position: relative;
    display: block;
    color: #333;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 4px; }
    .main_banner__item .text {
      width: 320px;
      font-size: 15px;
      font-weight: bold;
      line-height: 20px;
      color: #555; }
    .main_banner__item:hover {
      text-decoration: none;
      color: #333; }
  .main_banner__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 90px;
    padding-left: 390px;
    background-image: url(../pictures/main-bannre-bg.png);
    background-repeat: no-repeat;
    background-position: 115px -169px; }
  .main_banner__logo {
    margin-bottom: 30px;
    background: url(../pictures/main-banner-logo.png) no-repeat 0 0;
    height: 43px; }

.slick-arrow {
  position: absolute;
  width: 48px;
  height: 48px;
  margin-top: -24px;
  top: 50%;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  line-height: 44px;
  border: 1px solid #333;
  text-align: center;
  font-size: 30px;
  z-index: 10;
  cursor: pointer;
  opacity: .5;
  transition-duration: .1s; }
  .slick-arrow:hover {
    opacity: 1; }
  .slick-arrow:first-child {
    left: 21px; }
  .slick-arrow:nth-child(3) {
    right: 21px; }
    .slick-arrow:nth-child(3)::before {
      position: relative;
      left: 2px; }

.home-promo__top {
  padding-bottom: 19px;
  border-bottom: 1px solid #fafafa; }
  .home-promo__top .caption {
    float: left; }

.home-promo__slider {
  padding-left: 50px;
  padding-right: 50px;
  border-radius: 6px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  margin-bottom: 70px;
  max-height: 400px;
  overflow: hidden; }
  .home-promo__slider.slick-slider {
    overflow: visible; }
  .home-promo__slider .slick-list {
    border-right: 1px solid #e6e6e6; }
  .home-promo__slider .slick-dots {
    position: absolute;
    top: 100%;
    width: 100%;
    left: 0; }

.show_all {
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 23px;
  background-color: #e6e6e6;
  color: #333;
  line-height: 40px;
  border-radius: 3px; }
  .show_all:hover {
    text-decoration: none;
    background-color: #d4d4d4;
    color: #333; }

.stars span {
  color: #00adee;
  display: inline-block;
  margin-left: 4px;
  margin-bottom: 3px; }

.stars .fa {
  color: #e6e6e6; }

.stars.stars-1 .fa:first-child {
  color: #ed3b4d; }

.stars.stars-2 .fa:first-child, .stars.stars-2 .fa:nth-child(2) {
  color: #ed3b4d; }

.stars.stars-3 .fa:nth-child(1), .stars.stars-3 .fa:nth-child(3), .stars.stars-3 .fa:nth-child(2) {
  color: #ed3b4d; }

.stars.stars-4 .fa:nth-child(4), .stars.stars-4 .fa:nth-child(3), .stars.stars-4 .fa:nth-child(2), .stars.stars-4 .fa:nth-child(1) {
  color: #ed3b4d; }

.stars.stars-5 .fa {
  color: #ed3b4d; }

.img-bg {
  display: block;
  max-width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  text-decoration: none; }
  .img-bg:hover {
    text-decoration: none; }

.sale {
  position: absolute;
  top: 13px;
  right: 13px;
  display: block;
  width: 86px;
  height: 86px;
  padding-top: 23px;
  padding-left: 1px;
  border-radius: 50%;
  background-color: #ed3b4d;
  font-size: 20px;
  line-height: 20px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0;
  z-index: 10; }
  .sale span {
    display: block;
    font-size: 16px;
    text-transform: none;
    line-height: 20px;
    letter-spacing: .04em; }

.price {
  margin-bottom: 3px;
  font-size: 20px;
  font-weight: bold;
  color: #ed3b4d; }

.old-price {
  margin-bottom: 3px;
  color: #777;
  text-decoration: line-through;
  font-size: 14px;
  line-height: 20px;
  min-height: 20px; }

.card-mini {
  border: 1px solid #e6e6e6;
  display: inline-block;
  border-right: none; }
  .card-mini .caption {
    display: block;
    height: 40px;
    margin-bottom: 3px;
    padding-left: 14px;
    padding-right: 14px;
    font-size: 16px;
    line-height: 20px;
    overflow: hidden; }
  .card-mini .img-bg {
    margin-bottom: 17px;
    border-bottom: 1px solid #e6e6e6;
    height: 200px; }
  .card-mini a, .card-mini .about-shops span, .about-shops .card-mini span {
    text-decoration: none;
    position: relative;
    display: block; }
  .card-mini .stars, .card-mini .price, .card-mini .old-price {
    padding-left: 14px;
    padding-right: 14px; }
  .card-mini__bottom {
    margin-top: 7px;
    margin-bottom: 13px;
    padding-left: 14px;
    padding-right: 14px;
    height: 40px; }
    .card-mini__bottom .buy {
      float: left; }
    .card-mini__bottom .add-favorites {
      float: right; }

.buy {
  display: inline-block;
  height: 40px;
  padding-left: 55px;
  padding-right: 42px;
  color: #fff;
  line-height: 40px;
  background: url(../img/buy-cart.png) 16px 7px no-repeat;
  background-color: #39b455;
  border-radius: 4px;
  border: none;
  white-space: nowrap; }
  .buy:hover {
    background-color: #32a14b;
    text-decoration: none;
    color: #fff; }

.add-favorites {
  padding: 0;
  height: 40px;
  line-height: 38px;
  background-color: #fff;
  border: 1px solid #00adee;
  border-radius: 4px;
  width: 40px;
  color: #00adee;
  position: relative;
  overflow: hidden;
  transition-duration: 0s;
  white-space: nowrap; }
  .add-favorites span {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 51px;
    width: 150px; }
  .add-favorites .fa {
    float: right;
    width: 38px;
    font-size: 23px; }
  .add-favorites:hover {
    width: 100%;
    margin-left: calc(40px - 100%);
    transition-duration: 0.2s; }

.arrow-product {
  border: none;
  margin: -24px;
  background: transparent; }
  .arrow-product:hover {
    color: #00adee; }

.slick-dots {
  padding: 17px 0;
  list-style: none;
  margin: 0;
  text-align: center; }
  .slick-dots li, .slick-dots .support-wrapper p, .support-wrapper .slick-dots p {
    display: inline-block; }
    .slick-dots li button, .slick-dots .support-wrapper p button, .support-wrapper .slick-dots p button {
      background-color: transparent;
      border: none;
      width: 30px;
      height: 30px;
      font-size: 0; }
      .slick-dots li button::before, .slick-dots .support-wrapper p button::before, .support-wrapper .slick-dots p button::before {
        display: inline-block;
        width: 17px;
        height: 17px;
        content: '';
        border-radius: 50%;
        border: 3px solid #777;
        background-color: transparent; }
    .slick-dots li.slick-active button::before, .slick-dots .support-wrapper p.slick-active button::before, .support-wrapper .slick-dots p.slick-active button::before {
      background-color: #777; }

.home-advantages {
  margin-bottom: 32px;
  background: url(../img/pattern1.jpg) 0 0;
  padding-top: 48px;
  padding-bottom: 35px; }
  .home-advantages__item {
    float: left;
    width: 20%;
    display: block;
    font-weight: bold;
    font-size: 16px;
    color: #333; }
    .home-advantages__item:hover {
      color: #333; }

.home__icon {
  margin-top: -16px;
  margin-right: 22px;
  display: block;
  float: left;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff; }

.home__car {
  background-image: url(../img/home-car.png); }

.home__time {
  background-position: 21px 13px;
  background-image: url(../img/home-time.png); }

.home__user {
  background-image: url(../img/home-user.png);
  margin-right: 10px; }

.home__money {
  margin-left: 10px;
  background-image: url(../img/home-money.png); }

.home__opt {
  background-image: url(../img/home-opt.png); }

.home-posts {
  padding-bottom: 41px;
  background: url(../img/pattern2.jpg); }
  .home-posts .caption {
    margin-top: 35px;
    margin-bottom: 35px;
    color: #fff; }
    .home-posts .caption a, .home-posts .caption .about-shops span, .about-shops .home-posts .caption span {
      color: #fff;
      font-size: 14px;
      font-weight: normal;
      text-transform: uppercase; }
      .home-posts .caption a .fa, .home-posts .caption .about-shops span .fa, .about-shops .home-posts .caption span .fa {
        margin-left: 20px;
        font-size: 20px; }
    .home-posts .caption .text-right {
      float: right; }
  .home-posts-container {
    border: 1px solid #e6e6e6;
    background-color: #fff; }
  .home-posts__item {
    float: left;
    width: 25%;
    height: 300px;
    border-top: 1px solid #e6e6e6;
    margin-top: -1px; }
    .home-posts__item a, .home-posts__item .about-shops span, .about-shops .home-posts__item span {
      position: relative;
      display: block; }
      .home-posts__item a .in-time, .home-posts__item .about-shops span .in-time, .about-shops .home-posts__item span .in-time {
        position: absolute;
        top: 9px;
        right: 9px; }
      .home-posts__item a .new, .home-posts__item .about-shops span .new, .about-shops .home-posts__item span .new {
        position: absolute;
        top: 9px;
        right: 9px; }
      .home-posts__item a .date, .home-posts__item .about-shops span .date, .about-shops .home-posts__item span .date {
        position: absolute;
        top: 0;
        left: 14px; }
      .home-posts__item a:hover, .home-posts__item .about-shops span:hover, .about-shops .home-posts__item span:hover {
        text-decoration: none; }
    .home-posts__item:not(:last-child) {
      border-right: 1px solid #e6e6e6; }
    .home-posts__item:not(:nth-child(4)) {
      border-right: 1px solid #e6e6e6; }
    .home-posts__item .img-bg {
      height: 195px;
      border-bottom: 1px solid #e6e6e6; }
    .home-posts__item .text {
      display: block;
      padding: 22px 30px 0 14px;
      margin-bottom: 22px;
      color: #333;
      line-height: 20px;
      position: relative;
      height: 82px;
      overflow: hidden;
      font-weight: bold;
      min-height: 82px; }
      .home-posts__item .text::after {
        display: inline-block;
        content: '';
        height: 20px;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 50%;
        background: linear-gradient(to left, #fff 0%, transparent 100%); }

.home-posts__item:hover {
  height: 300px;
  position: relative;
  z-index: 10;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2); }
  .home-posts__item:hover .text {
    height: auto;
    background-color: #fff;
    margin-bottom: 0;
    padding-bottom: 10px; }
    .home-posts__item:hover .text::after {
      display: none; }

.date {
  display: block;
  width: 60px;
  padding-top: 35px;
  padding-bottom: 9px;
  line-height: 18px;
  text-align: center;
  color: #fff;
  background-image: url(../img/home-calendar.png);
  background-position: 20px 11px;
  background-repeat: no-repeat;
  background-color: #00adee; }

.new {
  display: inline-block;
  text-align: center;
  height: 87px;
  width: 87px;
  font-size: 20px;
  padding-top: 20px;
  font-weight: bold;
  line-height: 20px;
  color: #fff;
  background-color: #9eda29;
  border-radius: 50%; }
  .new span {
    display: block;
    font-size: 16px; }

.in-time {
  display: inline-block;
  padding-top: 22px;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  background-color: #c364f9;
  color: #fff;
  font-size: 16px;
  line-height: 18px;
  font-weight: bold;
  text-align: center; }

.home-about {
  padding-bottom: 180px;
  background: url(../pictures/home-bg.jpg) center;
  background-size: cover; }
  .home-about .float-50 {
    padding-right: 15px; }
  .home-about .caption {
    margin-top: 40px;
    margin-bottom: 40px;
    font-size: 26px;
    position: relative; }
    .home-about .caption .overton-logo {
      margin-top: 10px; }
    .home-about .caption::after {
      display: block;
      position: absolute;
      content: '';
      height: 3px;
      width: 570px;
      right: 0;
      bottom: -12px;
      background-color: #00adee; }

.home-video {
  margin-top: 51px;
  position: relative;
  margin-right: -15px;
  border-radius: 5px;
  overflow: hidden; }
  .home-video iframe {
    display: block;
    width: 100%;
    height: 330px; }

.overton-logo {
  display: block;
  height: 25px;
  background-position: 0 0;
  background-repeat: no-repeat; }

.float-50 {
  float: left;
  width: 50%; }

.video-preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer; }
  .video-preview .img-bg {
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover; }

.home-feed {
  padding-bottom: 40px; }
  .home-feed-wrapper {
    border: 1px solid #e6e6e6; }
  .home-feed .caption {
    margin-top: 27px;
    margin-bottom: 27px;
    font-size: 26px; }
    .home-feed .caption .text-right {
      float: right; }
    .home-feed .caption a, .home-feed .caption .about-shops span, .about-shops .home-feed .caption span {
      font-size: 14px;
      font-weight: normal;
      text-transform: uppercase; }
      .home-feed .caption a:hover, .home-feed .caption .about-shops span:hover, .about-shops .home-feed .caption span:hover {
        text-decoration: none;
        color: #333; }
      .home-feed .caption a .fa, .home-feed .caption .about-shops span .fa, .about-shops .home-feed .caption span .fa {
        font-size: 20px;
        position: relative;
        top: 2px;
        margin-left: 10px; }
  .home-feed__item {
    float: left;
    width: 25%;
    padding: 10px; }
    .home-feed__item:not(:last-child) {
      border-right: 1px solid #e6e6e6; }
    .home-feed__item .text {
      display: block;
      color: #333;
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 10px; }
    .home-feed__item .date {
      width: auto;
      display: inline-block;
      margin-bottom: 14px;
      padding-left: 32px;
      line-height: 29px;
      padding-top: 0;
      padding-right: 6px;
      padding-bottom: 0;
      background-position: 7px 5px; }
    .home-feed__item .more .fa {
      position: relative;
      top: 2px;
      font-size: 20px;
      margin-left: 11px; }
    .home-feed__item .more:hover {
      color: #333;
      text-decoration: none; }

.home-brands {
  background-color: #e6e6e6; }
  .home-brands .caption {
    margin-top: 33px;
    margin-bottom: 33px; }

.brands-slider {
  margin-bottom: 33px;
  padding-left: 45px;
  padding-right: 45px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.35);
  background-color: #fff;
  border-radius: 6px;
  position: relative; }
  .brands-slider .slick-list {
    border-right: 1px solid #e6e6e6;
    border-left: 1px solid #e6e6e6; }
  .brands-slider__item .img-bg {
    height: 112px; }

.arrow-blue {
  width: 47px;
  height: 66px;
  margin-top: -33px;
  line-height: 62px;
  background-color: #00adee;
  border-radius: 5px;
  color: #fff;
  border: 1px solid #fff;
  opacity: 1; }
  .arrow-blue:first-child {
    left: -35px; }
  .arrow-blue:nth-child(3) {
    right: -35px; }

.slick-dots_white {
  position: absolute;
  width: 100%; }
  .slick-dots_white li button::before, .slick-dots_white .support-wrapper p button::before, .support-wrapper .slick-dots_white p button::before {
    border-color: #fff; }
  .slick-dots_white li.slick-active button::before, .slick-dots_white .support-wrapper p.slick-active button::before, .support-wrapper .slick-dots_white p.slick-active button::before {
    background-color: #fff; }

@media all and (max-width: 1199px) {
  .main_banner {
    max-height: 310px; }
  .main_banner__item {
    height: 310px; }
  .main_banner__content {
    background-size: 78%;
    background-position: 126px -169px; }
  .home-promo__slider {
    padding-left: 95px;
    padding-right: 95px; }
  .home-posts__item:nth-child(n) {
    border-right: 1px solid #e6e6e6;
    margin-right: -1px;
    height: 235px; }
    .home-posts__item:nth-child(n):hover {
      height: 235px; }
    .home-posts__item:nth-child(n) .img-bg {
      height: 155px; }
    .home-posts__item:nth-child(n) .text {
      min-height: 0;
      height: 60px; }
      .home-posts__item:nth-child(n) .text:hover {
        height: 60px; }
      .home-posts__item:nth-child(n) .text::after {
        display: none; }
  .home-posts-container {
    margin-left: 35px;
    margin-right: 35px; }
  .home-about {
    display: none; } }

@media all and (max-width: 1023px) {
  .main_banner {
    max-height: 226px; }
  .add-favorites:hover {
    width: 40px; }
  .main_banner__item {
    height: 226px; }
    .main_banner__item .text {
      font-size: 10px;
      line-height: 14px; }
  .main_banner__content {
    padding-top: 54px;
    padding-left: 245px;
    background-position: 73px -139px; }
  .main_banner__logo {
    margin-bottom: 15px;
    height: 23px;
    background-size: contain; }
  .home-promo__slider {
    padding-left: 0;
    padding-right: 0; }
  .home-advantages {
    display: none; }
  .home-promo_popular {
    display: none; }
  .home-posts {
    padding-bottom: 64px; }
  .brands-slider__item .img-bg {
    background-size: 110px; } }

@media all and (max-width: 767px) {
  .main_banner {
    max-height: 240px; }
  .main_banner__item {
    height: 240px; }
  .main_banner__content {
    padding-left: 199px;
    padding-top: 20px;
    padding-right: 30px;
    background-image: url(../pictures/main-bannre-bg-xs.png);
    background-position: 40px -70px;
    background-size: auto; }
    .main_banner__content .text {
      padding-right: 50px;
      font-size: 13px;
      line-height: 19px;
      width: 100%; }
  .main_banner__logo {
    margin-bottom: 5px; }
  .home-posts-container {
    margin-left: -15px;
    margin-right: -15px; }
  .home-posts__item:nth-child(n) {
    height: 270px; }
    .home-posts__item:nth-child(n) .img-bg {
      height: 185px; }
  .arrow-blue:first-child {
    left: 15px; }
  .arrow-blue:nth-child(3) {
    right: 15px; }
  .home-feed-wrapper {
    padding-left: 42px;
    padding-right: 42px;
    border-radius: 5px; }
    .home-feed-wrapper .slick-list {
      border-left: 1px solid #e6e6e6;
      border-right: 1px solid transparent; }
  .home-feed .caption {
    font-size: 18px; }
  .caption {
    font-size: 18px; } }

@media all and (max-width: 479px) {
  .home-promo__top {
    text-align: center; }
    .home-promo__top .caption {
      float: none; }
    .home-promo__top .show_all {
      display: inline-block;
      margin-left: 0; }
  .main_banner__item {
    height: 170px; }
  .main_banner {
    margin-left: -15px;
    margin-right: -15px;
    max-height: 234px;
    overflow: visible;
    margin-bottom: 0; }
    .main_banner__content {
      padding-left: 123px;
      padding-top: 13px;
      background-position: -23px -130px; }
      .main_banner__content .text {
        font-size: 10px;
        line-height: 16px;
        padding-right: 0; }
    .main_banner__logo {
      height: 21px; }
  .home-promo__slider {
    margin-left: 15px;
    margin-right: 15px; }
    .home-promo__slider .arrow-product:first-child {
      left: -20px; }
    .home-promo__slider .arrow-product:nth-child(3) {
      right: -20px; }
  .home-posts .caption .text-right {
    float: none;
    font-size: 12px; }
  .home-feed {
    padding-bottom: 0; }
    .home-feed-wrapper {
      margin-left: -15px;
      margin-right: -15px;
      padding-left: 33px;
      padding-right: 33px; }
      .home-feed-wrapper .slick-list {
        border-right-color: #e6e6e6; }
    .home-feed__item {
      width: 184px; }
    .home-feed .caption {
      margin-top: 9px;
      margin-bottom: 9px; }
      .home-feed .caption a, .home-feed .caption .about-shops span, .about-shops .home-feed .caption span {
        font-size: 12px;
        position: relative;
        top: -2px; }
  .home-brands .caption {
    text-align: center;
    margin-top: 9px;
    margin-bottom: 9px; }
  .brands-slider {
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 12px; }
    .brands-slider__item .img-bg {
      width: 108px; } }

.breadcrumbs {
  margin-bottom: 36px;
  font-size: 12px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2); }
  .breadcrumbs .list-inline {
    height: 40px;
    align-items: center;
    display: flex;
    margin: 0; }
    .breadcrumbs .list-inline li, .breadcrumbs .list-inline .support-wrapper p, .support-wrapper .breadcrumbs .list-inline p {
      margin-right: 4px;
      padding: 0; }
      .breadcrumbs .list-inline li.separator, .breadcrumbs .list-inline .support-wrapper p.separator, .support-wrapper .breadcrumbs .list-inline p.separator {
        margin-right: 7px;
        width: 10px;
        height: 10px;
        background: url(../img/arrow.png) center no-repeat; }
    .breadcrumbs .list-inline span {
      color: #777; }
    .breadcrumbs .list-inline a, .breadcrumbs .list-inline .about-shops span, .about-shops .breadcrumbs .list-inline span {
      color: #333; }

@media screen and (max-width: 1023px) {
  .breadcrumbs {
    margin-bottom: 24px; } }

@media screen and (max-width: 767px) {
  .breadcrumbs .list-inline {
    flex-wrap: wrap;
    height: auto; } }

@media screen and (max-width: 479px) {
  .breadcrumbs {
    margin-bottom: 5px; } }

.instructions h1 {
  margin-top: -10px;
  margin-bottom: 35px; }

.instructions-wrapper {
  margin-bottom: 40px;
  columns: 4; }
  .instructions-wrapper .caption-16 {
    font-size: 14px;
    margin-bottom: 7px; }

.instructions__item {
  font-weight: 200;
  margin-bottom: 7px; }
  .instructions__item a:hover, .instructions__item .about-shops span:hover, .about-shops .instructions__item span:hover {
    text-decoration: none;
    color: #333; }

.leave-comments__item {
  position: relative; }
  .leave-comments__item .leave-stars {
    position: absolute;
    top: -7px; }
  .leave-comments__item .leave-star {
    width: 15px;
    background-size: 13px;
    cursor: default; }

.card-adding .home-promo__slider {
  max-height: none; }

.leave-stars {
  height: 25px;
  margin-top: 10px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 10px; }

.leave-star {
  display: inline-block;
  width: 28px;
  height: 25px;
  vertical-align: middle;
  background: url(../img/star-lg-gray.svg) 0 0 no-repeat;
  background-size: 26px;
  cursor: pointer;
  float: left; }

.leave-stars-1 .leave-star:first-child {
  background-image: url(../img/star-lg-red.svg); }

.leave-stars-2 .leave-star:first-child {
  background-image: url(../img/star-lg-red.svg); }

.leave-stars-2 .leave-star:nth-child(2) {
  background-image: url(../img/star-lg-red.svg); }

.leave-stars-3 .leave-star:first-child {
  background-image: url(../img/star-lg-red.svg); }

.leave-stars-3 .leave-star:nth-child(2) {
  background-image: url(../img/star-lg-red.svg); }

.leave-stars-3 .leave-star:nth-child(3) {
  background-image: url(../img/star-lg-red.svg); }

.leave-stars-4 .leave-star:not(:nth-child(5)) {
  background-image: url(../img/star-lg-red.svg); }

.leave-form .leave-stars:hover .leave-star {
  background-image: url(../img/star-lg-red.svg); }
  .leave-form .leave-stars:hover .leave-star:hover ~ .leave-star {
    background-image: url(../img/star-lg-gray.svg); }

.leave-stars-5 .leave-star {
  background-image: url(../img/star-lg-red.svg); }

.leave-hover {
  opacity: 0;
  position: absolute;
  left: 110%;
  top: 50%;
  width: 125px;
  height: 40px;
  margin-top: -17px;
  line-height: 40px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.3);
  transition-duration: .2s; }

.leave-form .stars-label ~ .form-button {
  margin-left: 27px; }

.leave-star:hover ~ .leave-hover {
  opacity: 1; }

.root-wrapper {
  margin-left: -30px;
  margin-bottom: 50px; }

.root__item {
  display: block;
  position: relative;
  float: left;
  margin-bottom: 30px;
  width: calc(33.33% - 30px);
  margin-left: 30px;
  border: 1px solid #e4e6ed;
  padding-bottom: 44px; }
  .root__item .img-bg {
    height: 200px; }
  .root__item .caption-16 {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    margin-bottom: 0;
    padding: 11px 17px;
    background-color: #00adee;
    color: #fff;
    font-weight: 500; }
    .root__item .caption-16:hover {
      color: #333; }

.search-products {
  margin-left: -30px; }
  .search-products .card-mini {
    position: relative;
    width: calc(25% - 30px);
    margin-left: 30px;
    margin-bottom: 30px;
    border-right: 1px solid #e6e6e6;
    float: left; }
    .search-products .card-mini .caption {
      margin-bottom: 15px; }
    .search-products .card-mini .price {
      margin-bottom: 20px; }
    .search-products .card-mini .add-favorites {
      position: absolute;
      top: 16px;
      right: 18px; }
      .search-products .card-mini .add-favorites:hover {
        width: calc(100% - 36px); }
    .search-products .card-mini__bottom {
      text-align: center; }
      .search-products .card-mini__bottom .buy {
        float: none; }
  .search-products-container {
    margin-bottom: 50px; }

.search-publication-container {
  margin-bottom: 50px; }
  .search-publication-container .sales-item {
    width: calc(25% - 30px); }

.catalog-container {
  margin-top: 44px; }

.catalog h1 {
  height: 0;
  margin: 0;
  padding-left: 300px; }

.catalog__erase {
  display: inline-block;
  text-transform: uppercase;
  text-decoration: underline;
  color: #777; }
  .catalog__erase:hover {
    text-decoration: none;
    color: #777; }

.catalog-range {
  margin-top: 4px;
  margin-bottom: 13px;
  padding: 13px 16px;
  background-color: #fafafa;
  border: 1px solid #e4e6ed;
  border-radius: 4px; }
  .catalog-range .text {
    margin-bottom: 15px;
    font-size: 12px; }
  .catalog-range .form-button {
    height: 40px;
    line-height: 39px;
    width: auto;
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 17px; }
    .catalog-range .form-button:hover {
      background-color: transparent; }

.erase {
  display: inline-block;
  width: 13px;
  margin-left: 15px;
  height: 13px;
  background: url(../img/erase.png) center no-repeat; }

.caption-16 {
  display: block;
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: bold; }

.price-filter {
  display: block;
  margin-bottom: 19px; }
  .price-filter input {
    width: 90px;
    text-align: center;
    border-color: #e6e6e6;
    border-radius: 3px; }

.price-inputs {
  display: block;
  margin-bottom: 21px; }

.price-separator {
  display: inline-block;
  width: 29px;
  height: 1px;
  background-color: #333; }

.price-range {
  display: block;
  position: relative;
  height: 5px;
  border: none;
  background-color: #777;
  border-radius: 2.5px;
  cursor: pointer; }

.price-range-between {
  background-color: #00adee; }

.ui-slider-handle.price-range-start {
  width: 22px;
  height: 22px;
  top: -8px;
  border: none;
  background-color: #fff;
  border-radius: 50%;
  cursor: url(../img/grab.cur) 1 1, pointer;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.35); }
  .ui-slider-handle.price-range-start.ui-state-active {
    height: 22px;
    padding: 0;
    cursor: url(../img/grabbing.cur) 1 1, pointer; }

.ui-widget.ui-widget-content {
  border: none; }

.range-label {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.catalog-nav {
  border: 1px solid #e4e6ed;
  border-radius: 5px;
  overflow: hidden; }
  .catalog-nav .caption-16 {
    margin-bottom: 0;
    padding-left: 16px;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-right: 50px;
    font-weight: normal;
    line-height: 18px;
    background-color: #fafafa;
    border-bottom: 1px solid #e4e6ed;
    position: relative; }
    .catalog-nav .caption-16 a, .catalog-nav .caption-16 .about-shops span, .about-shops .catalog-nav .caption-16 span {
      color: #333; }
      .catalog-nav .caption-16 a:hover, .catalog-nav .caption-16 .about-shops span:hover, .about-shops .catalog-nav .caption-16 span:hover {
        text-decoration: none;
        color: #00adee; }
    .catalog-nav .caption-16 .switcher {
      position: absolute;
      height: 50px;
      right: 0;
      top: 50%;
      width: auto;
      padding-top: 0;
      padding-bottom: 0;
      margin-top: -25px;
      line-height: 50px;
      padding-left: 18px;
      padding-right: 18px;
      float: right;
      cursor: pointer;
      display: inline-block; }
    .catalog-nav .caption-16 .caret {
      display: inline-block;
      vertical-align: middle;
      width: 0;
      padding: 0;
      border-bottom: none;
      border-top: 11px solid #777;
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
      transition-duration: .1s; }
    .catalog-nav .caption-16.active .caret {
      transform: scale(1, -1); }

.ui-selectmenu-menu.ui-front.ui-selectmenu-open {
  border: 1px solid #e6e6e6; }

.catalog-nav__list {
  height: 0;
  overflow: hidden;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  transition-duration: .2s; }
  .catalog-nav__list li, .catalog-nav__list .support-wrapper p, .support-wrapper .catalog-nav__list p {
    padding: 7px 16px; }
    .catalog-nav__list li a, .catalog-nav__list .support-wrapper p a, .support-wrapper .catalog-nav__list p a, .catalog-nav__list li .about-shops span, .about-shops .catalog-nav__list li span, .catalog-nav__list .support-wrapper p .about-shops span, .about-shops .catalog-nav__list .support-wrapper p span, .support-wrapper .catalog-nav__list p .about-shops span, .about-shops .support-wrapper .catalog-nav__list p span {
      color: #333; }
      .catalog-nav__list li a:hover, .catalog-nav__list .support-wrapper p a:hover, .support-wrapper .catalog-nav__list p a:hover, .catalog-nav__list li .about-shops span:hover, .about-shops .catalog-nav__list li span:hover, .catalog-nav__list .support-wrapper p .about-shops span:hover, .about-shops .catalog-nav__list .support-wrapper p span:hover, .support-wrapper .catalog-nav__list p .about-shops span:hover, .about-shops .support-wrapper .catalog-nav__list p span:hover {
        color: #00adee;
        text-decoration: none; }

.active + .catalog-nav__list {
  height: auto;
  padding-top: 7px;
  padding-bottom: 7px; }
  .active + .catalog-nav__list:not(:last-child) {
    border-bottom: 1px solid #e4e6ed; }

.catalog-choose {
  margin-bottom: 17px;
  padding: 10px 19px;
  background-color: #fafafa;
  border-radius: 3px;
  border: 1px solid #e4e6ed; }
  .catalog-choose .caption-16 {
    margin-bottom: 13px;
    width: 100%; }
  .catalog-choose .check {
    height: 24px;
    width: 24px;
    top: -3px;
    background-color: #fff;
    border-radius: 3px;
    border-color: #e6e6e6; }
  .catalog-choose .text {
    font-size: 15px; }
  .catalog-choose .checkbox {
    min-height: 38px;
    width: 33.33%;
    float: left;
    padding-left: 36px; }
  .catalog-choose input:checked + .check {
    background: url(../img/check-white.png) center no-repeat;
    background-color: #00adee;
    border-color: #00adee; }
    .catalog-choose input:checked + .check::before {
      display: none; }
  .catalog-choose .checkbox + .checkbox {
    margin-top: 10px; }

.catalog-actions {
  margin-bottom: 17px; }
  .catalog-actions .text-right {
    float: right; }

.catalog__sort {
  display: inline-block;
  width: 225px;
  vertical-align: middle;
  margin-left: 26px; }

.catalog__quantity {
  display: inline-block;
  width: 78px;
  margin-left: 19px; }
  .catalog__quantity .ui-selectmenu-button.ui-button {
    background-color: #fafafa;
    border-color: #e4e6ed; }

.catalog-wrapper {
  margin-left: -30px; }
  .catalog-wrapper .card-mini {
    float: left;
    width: calc(33.33% - 30px);
    margin-bottom: 28px;
    margin-left: 30px;
    border-right: 1px solid #e6e6e6;
    transition-duration: .3s; }
    .catalog-wrapper .card-mini:hover {
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2); }
  .catalog-wrapper .price {
    margin-bottom: 15px;
    color: #333; }

.content_block_ajax {
  position: relative; }

.fa-heart-o.break::before {
  background-image: url(../img/dislike.svg);
  background-size: contain;
  vertical-align: middle;
  height: 23px;
  width: 23px;
  display: inline-block;
  background-repeat: no-repeat;
  content: '';
  background-position: center; }

.card-mini__bottom.in-cart a, .card-mini__bottom.in-cart .about-shops span, .about-shops .card-mini__bottom.in-cart span {
  display: inline-block;
  height: 40px;
  line-height: 38px;
  border-radius: 4px; }
  .card-mini__bottom.in-cart a:hover, .card-mini__bottom.in-cart .about-shops span:hover, .about-shops .card-mini__bottom.in-cart span:hover {
    text-decoration: none;
    color: #fff;
    background-color: #00adee; }
    .card-mini__bottom.in-cart a:hover span, .card-mini__bottom.in-cart .about-shops span:hover span, .about-shops .card-mini__bottom.in-cart span:hover span {
      color: #fff; }

.paginator {
  height: 40px;
  padding-left: 5px;
  padding-right: 25px;
  margin-bottom: 29px;
  line-height: 40px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
  border-radius: 4px; }
  .paginator .fa {
    position: relative;
    top: 3px;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 24px; }
  .paginator__prev {
    display: inline-block;
    color: #333;
    text-decoration: underline;
    text-transform: uppercase; }
    .paginator__prev:hover {
      color: #333; }
  .paginator__list {
    margin-bottom: 0;
    margin-left: 40px;
    margin-right: 40px;
    display: inline-block;
    vertical-align: middle; }
    .paginator__list span {
      padding-left: 3px;
      padding-right: 3px; }
    .paginator__list a, .paginator__list .about-shops span, .about-shops .paginator__list span {
      color: #333;
      display: inline-block;
      padding-left: 3px;
      padding-right: 3px; }
      .paginator__list a:hover, .paginator__list .about-shops span:hover, .about-shops .paginator__list span:hover {
        text-decoration: underline; }
  .paginator__next {
    text-transform: uppercase;
    text-decoration: underline; }
  .paginator .text-right {
    float: right; }
    .paginator .text-right span {
      margin-left: 3px;
      margin-right: 3px; }
  .paginator__current {
    color: #00adee; }

.catalog-interes {
  margin-bottom: 25px;
  padding: 15px 0 0 20px;
  background-color: #fafafa;
  border: 1px solid #e4e6ed; }
  .catalog-interes .caption {
    font-size: 22px;
    margin-bottom: 15px; }
  .catalog-interes__link {
    display: block;
    float: left;
    width: calc(16.66% - 28px);
    margin-right: 28px;
    margin-bottom: 15px;
    padding: 15px 0;
    text-align: center;
    background-color: #fff;
    border: 1px solid #e4e6ed; }
    .catalog-interes__link .img-bg {
      width: 70px;
      height: 70px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 9px;
      border: 1px solid #edeef3;
      background-size: contain; }
    .catalog-interes__link:hover {
      text-decoration: none;
      color: #333; }

.catalog-list {
  margin-left: -30px;
  margin-bottom: 40px; }
  .catalog-list__item {
    float: left;
    margin-left: 30px;
    width: calc(33.33% - 30px); }
    .catalog-list__item .caption {
      text-align: center;
      margin-bottom: 5px; }
      .catalog-list__item .caption a, .catalog-list__item .caption .about-shops span, .about-shops .catalog-list__item .caption span {
        font-size: 22px;
        color: #333;
        letter-spacing: 0;
        text-decoration: underline; }
        .catalog-list__item .caption a:hover, .catalog-list__item .caption .about-shops span:hover, .about-shops .catalog-list__item .caption span:hover {
          color: #00adee;
          text-decoration: none; }
  .catalog-list__link {
    display: block;
    display: flex;
    align-items: center;
    margin-right: -6px;
    color: #333;
    line-height: 18px; }
    .catalog-list__link:hover {
      text-decoration: none;
      color: #00adee; }
    .catalog-list__link:not(:last-child) {
      margin-bottom: 20px; }
    .catalog-list__link .img-bg {
      flex-shrink: 0;
      width: 70px;
      height: 70px;
      display: inline-block;
      margin-right: 16px;
      background-size: contain; }
  .catalog-list-wrapper {
    padding: 20px;
    border: 1px solid #e4e6ed; }

.catalog-posts-slider {
  margin-bottom: 65px;
  padding-left: 35px;
  padding-right: 35px;
  border-radius: 5px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2); }
  .catalog-posts-slider .slick-list {
    border-right: 1px solid #e4e6ed;
    border-left: 1px solid #e4e6ed;
    overflow-y: visible;
    overflow-x: hidden; }
  .catalog-posts-slider .slick-dots {
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0; }

.catalog-posts__link {
  display: block;
  padding: 10px 10px 14px;
  font-size: 16px;
  line-height: 18px;
  color: #333;
  font-weight: bold; }
  .catalog-posts__link .img-bg {
    height: 175px;
    margin-bottom: 15px;
    background-size: cover;
    border: 1px solid #e4e6ed; }
  .catalog-posts__link:hover {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.35);
    text-decoration: none;
    color: #00adee; }

.catalog-description {
  margin-bottom: 45px; }
  .catalog-description p {
    color: #666; }

.card h1 {
  margin-bottom: 10px; }

.card-top a, .card-top .about-shops span, .about-shops .card-top span {
  color: #777; }
  .card-top a:hover, .card-top .about-shops span:hover, .about-shops .card-top span:hover {
    text-decoration: none; }

.card-top .stars {
  float: left;
  margin-right: 10px; }
  .card-top .stars span {
    margin-left: 10px;
    color: #777; }

.card-slider {
  padding: 38px 46px 40px 15px;
  border: 1px solid #e6e6e6; }
  .card-slider__main {
    margin-right: 40px;
    width: 610px;
    float: left; }
    .card-slider__main .img-bg {
      height: 434px;
      background-size: auto;
      border: 1px solid #e6e6e6; }
  .card-slider-thumbs {
    position: relative;
    height: 434px;
    float: left;
    width: 150px; }
    .card-slider-thumbs__item {
      margin-bottom: 9px; }
      .card-slider-thumbs__item .img-bg {
        height: 100px;
        border: 1px solid #e6e6e6;
        background-size: contain; }
      .card-slider-thumbs__item.slick-current .img-bg {
        border: 2px solid #00adee; }

.arrow-vertical {
  background-color: transparent;
  border: none;
  color: #777;
  opacity: 1;
  transform: translate(-50%); }
  .arrow-vertical:hover {
    color: #00adee; }
  .arrow-vertical:first-child {
    top: -18px;
    left: 50%; }
  .arrow-vertical:nth-child(3) {
    bottom: -44px;
    top: auto;
    left: 50%; }

.card-right {
  position: relative;
  height: 514px;
  margin-bottom: 30px;
  padding: 25px;
  background-color: #fafafa;
  border: 1px solid #e4e6ed; }
  .card-right .add-favorites {
    float: right;
    height: 64px; }
    .card-right .add-favorites span {
      top: 12px; }
  .card-right .clearfix {
    margin-bottom: 5px; }
  .card-right .text {
    color: #777;
    margin-bottom: -3px; }
  .card-right .price {
    margin-bottom: -5px;
    font-size: 30px;
    color: #333; }
  .card-right .green {
    margin-bottom: 5px; }
  .card-right .buy {
    padding-right: 30px; }

.card-options {
  margin-right: -15px;
  margin-bottom: 6px; }
  .card-options li, .card-options .support-wrapper p, .support-wrapper .card-options p {
    margin-bottom: 10px;
    position: relative;
    padding-left: 27px;
    font-size: 13px;
    color: #00adee; }
    .card-options li:first-child img, .card-options .support-wrapper p:first-child img, .support-wrapper .card-options p:first-child img {
      width: 21px;
      margin-left: -10px; }
    .card-options li:nth-child(2) img, .card-options .support-wrapper p:nth-child(2) img, .support-wrapper .card-options p:nth-child(2) img {
      width: 11px;
      margin-left: -5px;
      margin-top: -8px; }
    .card-options li img, .card-options .support-wrapper p img, .support-wrapper .card-options p img {
      position: absolute;
      top: 50%;
      left: 10px;
      width: 16px;
      margin-left: -8px;
      margin-top: -9px;
      height: auto; }
    .card-options li span, .card-options .support-wrapper p span, .support-wrapper .card-options p span {
      color: #333; }

.card__brand {
  width: 147px;
  display: inline-block;
  height: 64px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #e4e6ed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain; }

.color {
  margin-bottom: 15px; }
  .color .text {
    margin-bottom: 10px;
    color: #333;
    font-size: 13px; }
  .color__item {
    display: inline-block;
    height: 24px;
    width: 24px;
    vertical-align: middle;
    border-radius: 3px;
    cursor: pointer;
    margin-right: 3px; }
    .color__item.white {
      border: 1px solid #e6e6e6; }

input:checked + .color__item {
  border: 3px solid #00adee; }

.card-popup {
  display: none;
  position: absolute;
  bottom: 32px;
  left: 0;
  padding: 33px 20px 15px;
  background-color: #fff;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2); }
  .card-popup__close {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    right: 0;
    background: url(../img/close.png) center no-repeat;
    cursor: pointer; }
  .card-popup .text {
    padding-left: 36px;
    position: relative;
    margin-bottom: 20px; }
  .card-popup .green-check {
    position: absolute;
    top: 3px;
    left: 0; }
  .card-popup .buy {
    margin-bottom: 10px;
    padding-right: 57px; }

.green-check {
  display: inline-block;
  width: 21px;
  height: 21px;
  border-radius: 3px;
  background: url(../img/check-white.png) center no-repeat;
  background-color: #39b455; }

.return {
  display: inline-block;
  width: 100%;
  line-height: 40px;
  border-radius: 4px;
  text-align: center;
  color: #333;
  background-color: #e6e6e6; }
  .return:hover {
    text-decoration: none;
    color: #333;
    background-color: #d4d4d4; }

.card-content {
  margin-bottom: 37px; }

.card-tabs__item, .where-tabs__item {
  display: inline-block;
  float: left;
  position: relative;
  line-height: 50px;
  margin-right: 24px;
  border: 1px solid #e4e6ed;
  border-bottom: none;
  padding-left: 28px;
  padding-right: 28px;
  border-radius: 5px 5px 0 0;
  transition-duration: .2s;
  overflow: hidden;
  font-size: 16px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .card-tabs__item + .card-tabs-content, .where-tabs__item + .card-tabs-content {
    display: none; }
  .card-tabs__item.active, .active.where-tabs__item {
    padding-left: 55px;
    padding-right: 55px;
    background-color: #e6e6e6; }
  .card-tabs__item::after, .where-tabs__item::after {
    position: absolute;
    top: 100%;
    left: -10%;
    display: block;
    content: '';
    width: 120%;
    height: 100%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5); }

.card-tabs-container {
  padding: 27px 18px;
  border: 1px solid #e4e6ed; }
  .card-tabs-container iframe {
    float: left;
    margin-right: 20px;
    width: 353px;
    height: 195px; }
  .card-tabs-container p {
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0; }
    .card-tabs-container p:last-child {
      margin-bottom: 0; }

.catalog-posts-slider_card {
  position: relative;
  padding-left: 53px;
  padding-right: 53px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.35); }
  .catalog-posts-slider_card .slick-list {
    border: 1px solid #e4e6ed; }
  .catalog-posts-slider_card .slick-dots {
    left: 0;
    top: 100%;
    width: 100%;
    position: absolute; }

.card-interes {
  padding-bottom: 74px;
  padding-top: 30px;
  background-color: #e6e6e6; }
  .card-interes .caption {
    margin-bottom: 20px; }

.card .catalog-list {
  margin-bottom: 36px; }

.card-adding .caption {
  margin-bottom: 19px;
  font-size: 24px;
  float: left; }

.card-adding .card-mini .caption {
  font-size: 16px; }

a.card-mini__link, .about-shops span.card-mini__link {
  display: inline-block;
  line-height: 38px;
  padding-left: 40px;
  padding-right: 40px;
  border: 1px solid #00adee;
  border-radius: 4px; }
  a.card-mini__link:hover, .about-shops span.card-mini__link:hover {
    text-decoration: none; }

@media screen and (max-width: 1199px) {
  .leave-form .stars-label {
    width: 235px; }
  .root-wrapper {
    margin-left: -25px; }
  .root__item {
    width: calc(33.33% - 25px);
    margin-left: 25px;
    margin-bottom: 25px; }
    .root__item .img-bg {
      height: 170px;
      background-size: contain; }
  .search-products {
    margin-left: -93px; }
    .search-products .card-mini {
      width: calc(33.33% - 93px);
      margin-left: 93px; }
      .search-products .card-mini:nth-child(7) {
        display: none; }
      .search-products .card-mini:nth-child(8) {
        display: none; }
  .search .publications-wrapper .sales-item:nth-child(7) {
    display: none; }
  .search .publications-wrapper .sales-item:nth-child(8) {
    display: none; }
  .search-products-container {
    margin-bottom: 35px; }
  .search-publication-container {
    margin-bottom: 35px; }
  .card .col-lg-9 {
    width: 724px;
    float: left; }
  .card .col-lg-3 {
    width: 300px;
    float: left; }
  .card-slider {
    padding: 17px 20px 38px 17px; }
    .card-slider-thumbs {
      margin-top: 20px; }
    .card-slider__main {
      width: 483px;
      margin-right: 18px; }
      .card-slider__main .img-bg {
        width: 483px;
        height: 340px;
        background-size: contain; }
  .catalog-root .catalog-range {
    display: none; }
  .catalog-nav {
    display: none; }
  .catalog-range {
    padding-top: 17px;
    padding-bottom: 17px; }
    .catalog-range:after {
      display: table;
      content: '';
      clear: both; }
    .catalog-range .caption-16 {
      float: left;
      margin-right: 15px;
      margin-bottom: 0;
      position: relative;
      top: 9px; }
    .catalog-range .price-filter {
      float: left;
      margin-bottom: 0; }
    .catalog-range form {
      float: left; }
    .catalog-range .range-label {
      width: 220px;
      position: relative;
      top: 3px; }
    .catalog-range .price-separator {
      width: 18px; }
    .catalog-range .text {
      position: relative;
      top: 11px;
      float: left; }
    .catalog-range .price-inputs {
      float: left;
      margin-right: 27px;
      margin-bottom: 0; }
    .catalog-range .price-range {
      position: relative;
      top: 18px;
      float: left;
      width: 220px;
      margin-right: 27px; }
    .catalog-range .text-center {
      float: left;
      width: 290px;
      display: flex;
      justify-content: space-around;
      align-items: center; }
    .catalog-range .form-button {
      margin-bottom: 0; }
  .catalog-container {
    margin-top: 15px; }
  .catalog h1 {
    height: auto;
    margin-bottom: 25px;
    padding-left: 0; }
  .catalog-wrapper .card-mini {
    width: calc(25% - 30px); }
  .catalog-posts-slider {
    padding-left: 95px;
    padding-right: 95px; }
  .card-popup .buy {
    width: 100%; } }

@media screen and (max-width: 1023px) {
  .instructions-wrapper {
    columns: 3; }
  .root h1 {
    text-align: center;
    margin-bottom: 30px; }
  .root-wrapper {
    margin-left: -20px; }
  .root__item {
    width: calc(33.33% - 20px);
    margin-left: 20px;
    margin-bottom: 20px; }
    .root__item .caption-16 {
      font-size: 14px; }
    .root__item .img-bg {
      height: 170px;
      background-size: contain; }
  .search h1 {
    text-align: center; }
  .search h2 {
    text-align: center; }
  .search-products {
    margin-left: 0; }
    .search-products .card-mini {
      width: calc(33.33% + 1px);
      margin-left: 0;
      margin-right: -1px; }
  .search .publications-wrapper .sales-item:nth-child(8) {
    display: block; }
  .search .publications-wrapper .sales-item:nth-child(7) {
    display: block; }
  .card h1 {
    font-size: 22px; }
  .card .col-lg-9 {
    width: 468px; }
  .card .col-lg-3 {
    width: 300px;
    padding-left: 0; }
  .card-slider__main {
    width: 100%;
    margin-right: 0; }
  .card-slider-thumbs {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    height: 58px; }
    .card-slider-thumbs .slick-arrow:first-child {
      left: 6px; }
    .card-slider-thumbs .slick-arrow:nth-child(3) {
      right: 7px; }
    .card-slider-thumbs .img-bg {
      height: 58px;
      margin-right: 6px; }
  .catalog h1 {
    font-size: 24px;
    text-align: center; }
  .price-filter input {
    width: 65px;
    font-size: 12px;
    padding-left: 9px;
    padding-right: 9px; }
  .catalog-range {
    padding-top: 14px;
    padding-bottom: 14px; }
    .catalog-range .price-inputs {
      margin-right: 16px; }
    .catalog-range .price-range {
      width: 111px; }
    .catalog-range .form-button {
      height: 30px;
      line-height: 28px;
      padding-left: 35px;
      padding-right: 35px;
      font-size: 12px; }
    .catalog-range .catalog__erase {
      font-size: 12px; }
    .catalog-range .text-center {
      width: 230px;
      position: relative;
      top: 5px; }
    .catalog-range .range-label {
      width: 163px; }
    .catalog-range .caption-16 {
      margin-right: 5px;
      font-size: 12px; }
  .catalog-wrapper {
    margin-left: 0; }
    .catalog-wrapper .card-mini {
      width: calc(33.33% + 1px);
      margin-left: 0;
      margin-right: -1px; }
  .paginator__list {
    margin-left: 30px;
    margin-right: 30px; }
  .catalog-interes__link {
    width: calc(20% - 28px); }
  .catalog-list {
    display: none; }
  .catalog-posts-slider {
    padding-left: 32px;
    padding-right: 32px; } }

@media screen and (max-width: 767px) {
  .instructions h1 {
    text-align: center;
    margin-bottom: 15px; }
  .instructions-wrapper {
    columns: 2; }
  .root h1 {
    margin-top: -15px;
    margin-bottom: 10px; }
  .root-wrapper {
    margin-left: -14px; }
  .root__item {
    width: calc(50% - 14px);
    margin-left: 14px;
    margin-bottom: 14px; }
    .root__item .caption-16 {
      text-align: center;
      min-height: 62px;
      display: flex;
      align-items: center;
      justify-content: center; }
    .root__item .img-bg {
      height: 170px;
      background-size: contain; }
  h2 {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px; }
  .search-products {
    margin-left: 0; }
    .search-products .card-mini {
      width: calc(50% + 1px); }
  .catalog-posts-slider_card {
    padding-left: 30px;
    padding-right: 30px; }
    .catalog-posts-slider_card .slick-arrow:first-child {
      left: 15px; }
    .catalog-posts-slider_card .slick-arrow:nth-child(3) {
      right: 15px; }
  a.card-mini__link, .about-shops span.card-mini__link {
    padding-left: 32px;
    padding-right: 32px; }
  .card h1 {
    font-size: 18px; }
  .card .col-lg-9 {
    width: 100%; }
  .card .col-lg-3 {
    width: 100%;
    padding-left: 15px; }
  .card-slider {
    margin-bottom: 20px;
    padding: 13px; }
  .card-right {
    height: auto; }
    .card-right .add-favorites {
      width: 217px; }
    .card-right .color {
      position: absolute;
      right: 30px;
      top: 99px; }
  .card-popup {
    margin-left: -135px;
    left: 50%;
    width: 270px; }
  .card-tabs__item, .where-tabs__item {
    float: none;
    width: 100%;
    margin-bottom: 9px; }
    .card-tabs__item.active + .card-tabs-content, .active.where-tabs__item + .card-tabs-content {
      padding: 22px;
      border: 1px solid #e4e6ed;
      display: block;
      background-color: #fff;
      margin-bottom: 20px;
      margin-top: -20px; }
      .card-tabs__item.active + .card-tabs-content iframe, .active.where-tabs__item + .card-tabs-content iframe {
        display: block;
        width: 351px;
        margin-left: auto;
        margin-right: auto;
        float: none;
        margin-bottom: 10px;
        height: 196px; }
  .card-tabs-container {
    display: none; }
  .catalog h1 {
    font-size: 18px;
    margin-bottom: 5px; }
  .catalog-range {
    padding-bottom: 65px; }
    .catalog-range form {
      width: 365px;
      position: relative; }
    .catalog-range .price-filter {
      float: none;
      display: block; }
    .catalog-range .range-label {
      width: 206px; }
    .catalog-range .price-range {
      float: none;
      width: 206px;
      margin-top: 20px; }
    .catalog-range .price-inputs {
      float: none; }
    .catalog-range .caption-16 {
      margin-right: 15px; }
    .catalog-range .text {
      position: absolute;
      top: 11px;
      right: 3px; }
      .catalog-range .text-center {
        position: absolute;
        bottom: -40px;
        top: auto;
        right: 12px;
        flex-wrap: wrap;
        width: 100px; }
    .catalog-range .form-button {
      margin-bottom: 10px; }
  .catalog-choose .columns {
    columns: 1; }
  .catalog-choose .text {
    font-size: 14px; }
  .catalog-choose .checkbox {
    min-height: 30px;
    width: 50%; }
  .catalog-actions .text {
    display: block;
    margin-bottom: 3px; }
  .catalog-actions .text-right .text {
    float: left;
    line-height: 34px; }
  .catalog__sort {
    margin-left: 0;
    width: 194px; }
  .catalog-wrapper .card-mini {
    width: 50%;
    margin-bottom: 26px; }
  .paginator {
    padding-right: 0; }
    .paginator .text-right {
      display: none; }
    .paginator__list a, .paginator__list .about-shops span, .about-shops .paginator__list span {
      padding-left: 0;
      padding-right: 0; }
    .paginator__next {
      float: right; }
  .catalog-interes__link {
    width: calc(33.33% - 25px);
    margin-right: 25px; }
  .catalog-posts-slider .img-bg {
    height: 128px;
    margin-bottom: 8px; }
  .catalog-description {
    text-align: center; }
    .catalog-description h2 {
      font-size: 18px; } }

@media all and (max-width: 479px) {
  .instructions h1 {
    margin-top: 0;
    margin-bottom: 10px; }
  .instructions-wrapper {
    columns: 1; }
  .card-adding .caption {
    float: none; }
  .root h1 {
    margin-top: 0; }
  .root__item {
    width: 100%; }
  .search-products {
    margin-left: 0; }
    .search-products .card-mini {
      width: 100%;
      margin-right: 0; }
  .card__brand {
    width: 100%; }
  .card-tabs__item.active + .card-tabs-content iframe, .active.where-tabs__item + .card-tabs-content iframe {
    width: 100%;
    height: 139px; }
  .card-slider__main .img-bg {
    height: 225px; }
  .card-right {
    padding-top: 99px; }
    .card-right .color {
      position: static; }
    .card-right .add-favorites {
      width: 238px;
      position: absolute;
      left: 25px;
      top: 25px; }
  .catalog h1 {
    margin-bottom: 0;
    margin-top: 10px; }
  .catalog-range {
    padding-bottom: 15px; }
    .catalog-range form {
      width: 210px; }
    .catalog-range .text {
      position: static;
      text-align: center;
      width: 100%;
      margin-top: 40px; }
      .catalog-range .text-center {
        position: static;
        width: 200px;
        margin-left: auto;
        margin-right: auto; }
  .catalog-wrapper .card-mini {
    width: 100%; }
  .catalog-interes__link {
    width: calc(50% - 15px);
    margin-right: 15px; }
  .catalog-choose .checkbox {
    width: 100%; }
  .catalog-actions .text-right {
    float: none;
    margin-top: 15px; }
  .paginator__prev {
    font-size: 0; }
  .paginator__next {
    font-size: 0;
    position: relative;
    top: 2px;
    right: 5px; }
  .paginator__list {
    margin-right: 0;
    margin-left: 10px; } }

.registration h1 {
  margin-bottom: 40px; }

.registration-container {
  margin-bottom: 34px;
  padding: 30px;
  border: 1px solid #e6e6e6; }

.registration-form {
  padding-left: 32px;
  width: 630px;
  float: left; }
  .registration-form__checkboxes {
    margin-bottom: 30px; }
  .registration-form__inputs {
    margin-bottom: 28px; }
  .registration-form label {
    display: block;
    width: 278px;
    font-size: 16px;
    letter-spacing: 0;
    margin-bottom: 11px; }
  .registration-form .invalid-text {
    left: 100%;
    top: 50%;
    width: 100%;
    transform: translate(14px, 4px); }
  .registration-form .checkbox {
    max-width: 100%;
    padding-left: 52px;
    margin-bottom: 18px;
    width: 538px;
    line-height: 24px; }
  .registration-form .check {
    width: 36px;
    height: 36px;
    top: -5px;
    border-color: #999;
    border-radius: 3px;
    transform: scale(0.75);
    will-change: transform; }
  .registration-form input:checked + .check::before {
    width: 21px;
    height: 16px;
    top: 50%;
    left: 50%;
    margin-top: -8px;
    margin-left: -10px;
    background: url(../img/check-black.png) center no-repeat; }
  .registration-form__bottom {
    width: 275px;
    margin-bottom: 25px; }
  .registration-form .text {
    width: 565px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0; }

.registration-socials {
  width: 461px;
  float: left;
  height: 500px;
  padding-right: 40px;
  border-left: 1px solid #e6e6e6; }
  .registration-socials .text {
    margin-bottom: 30px;
    font-size: 16px;
    letter-spacing: 0; }

.registration-banner {
  margin-bottom: 44px;
  padding-left: 825px;
  padding-top: 19px;
  padding-bottom: 38px;
  background-position: 0 0;
  background-repeat: no-repeat; }
  .registration-banner .text {
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: bold; }
  .registration-banner .form-button {
    width: auto;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 3px; }

.registration__logo {
  display: inline-block;
  height: 36px;
  width: 200px;
  background-repeat: no-repeat;
  vertical-align: middle;
  background-position: 0 0;
  background-size: contain;
  margin-right: 10px; }

.cart-page h1 {
  margin-bottom: 30px; }

.cart-left {
  border: 1px solid #e4e6ed; }

.cart__reviews:hover {
  color: #333;
  text-decoration: none; }

.cart-card {
  padding: 18px; }
  .cart-card::after {
    display: table;
    clear: both;
    content: ''; }
  .cart-card .stars {
    display: inline-block;
    margin-right: 7px; }
    .cart-card .stars span {
      color: #777;
      margin-left: 10px; }
  .cart-card-actions {
    margin-bottom: 28px; }
  .cart-card-middle {
    float: left;
    width: 528px;
    padding-right: 30px; }
    .cart-card-middle .caption-16 {
      margin-bottom: 20px;
      line-height: 20px;
      color: #333; }
      .cart-card-middle .caption-16:hover {
        color: #333; }
  .cart-card__img {
    display: block;
    float: left;
    width: 160px;
    padding-right: 17px; }
    .cart-card__img .img-bg {
      height: 142px;
      border: 1px solid #e6e6e6;
      background-size: 90%; }

.cart__adiing {
  border-bottom: 1px dashed #00adee; }
  .cart__adiing:hover {
    text-decoration: none;
    border-bottom-color: transparent; }

.cart__delete {
  display: inline-block;
  color: #00adee;
  cursor: pointer; }
  .cart__delete span {
    color: #333;
    border-bottom: 1px dashed #00adee; }
    .cart__delete span:hover {
      border-bottom-color: transparent; }
  .cart__delete:hover {
    text-decoration: none; }

.cart-container {
  margin-bottom: 36px; }

.cart-card:not(:last-child) {
  border-bottom: 1px solid #e6e6e6; }

.cart-card-right {
  float: left;
  width: 140px; }
  .cart-card-right .text {
    color: #777; }
  .cart-card-right .price {
    margin-bottom: 7px;
    color: #333;
    line-height: 13px; }
    .cart-card-right .price span:not(.inherits) {
      font-size: 14px;
      margin-left: 5px; }

.cart-right {
  border: 1px solid #e6e6e6; }

.cart__confirm {
  padding: 14px;
  border-bottom: 1px solid #e6e6e6; }
  .cart__confirm .text {
    margin-bottom: 14px;
    font-size: 16px;
    line-height: 18px; }
  .cart__confirm .price {
    margin-bottom: 16px;
    font-size: 30px;
    color: #333; }

.cart-back {
  padding: 14px; }
  .cart-back .fa {
    margin-right: 10px;
    font-size: 20px;
    position: relative;
    top: 1px; }
  .cart-back a:hover, .cart-back .about-shops span:hover, .about-shops .cart-back span:hover {
    text-decoration: none;
    color: #333; }

.confirm {
  display: inline-block;
  padding-left: 14px;
  padding-right: 14px;
  color: #fff;
  background-color: #39b455;
  border-radius: 3px;
  line-height: 40px; }
  .confirm:hover {
    background-color: #32a14b;
    color: #fff;
    text-decoration: none; }

.quantity-inputs {
  margin-bottom: 20px; }
  .quantity-inputs::after {
    display: table;
    clear: both;
    content: ''; }
  .quantity-inputs button {
    width: 34px;
    height: 34px;
    margin-right: 2px;
    border-radius: 3px;
    border: 1px solid #00adee;
    color: #00adee;
    background-color: #fff;
    float: left; }
    .quantity-inputs button.plus::after {
      content: '+'; }
    .quantity-inputs button.minus::after {
      content: '-';
      position: relative;
      top: 2px; }
  .quantity-inputs input {
    width: 66px;
    height: 34px;
    float: left;
    margin-right: 2px;
    border: none;
    border-radius: 3px;
    background-color: #e6e6e6;
    text-align: center; }

.gray {
  color: #777; }

.order h1 {
  margin-bottom: 30px; }

.order-left {
  margin-bottom: 27px; }
  .order-left .caption-16 {
    margin-bottom: 0;
    padding: 15px 16px;
    border-radius: 5px 5px  0 0;
    background-color: #00adee;
    color: #fff; }
    .order-left .caption-16 span {
      font-weight: normal; }
  .order-left-wrapper {
    border: 1px solid #e4e6ed;
    border-top: none; }
  .order-left__link {
    display: block;
    padding: 15px 16px;
    border-bottom: 1px solid #e4e6ed;
    color: #333;
    font-weight: bold; }
    .order-left__link:hover {
      text-decoration: none;
      background-color: #fafafa;
      color: #333; }
    .order-left__link span {
      font-weight: normal; }

.order__sum {
  padding: 15px 16px;
  border-bottom: 1px solid #e4e6ed;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px; }
  .order__sum .inherits {
    margin-right: 20px; }
  .order__sum .price {
    display: inline-block;
    vertical-align: middle;
    color: #333; }

#coupon, .order__coupon input {
  border-color: #00adee;
  color: #00adee; }
  #coupon:-ms-input-placeholder, .order__coupon input:-ms-input-placeholder {
    color: #00adee; }
  #coupon::placeholder, .order__coupon input::placeholder {
    color: #00adee; }
  #coupon::-moz-placeholder, .order__coupon input::-moz-placeholder {
    color: #00adee; }
  #coupon::-webkit-input-placeholder, .order__coupon input::-webkit-input-placeholder {
    color: #00adee; }

.order-banner {
  position: relative;
  height: 385px;
  margin-bottom: 30px;
  background-repeat: no-repeat;
  background-size: cover; }
  .order-banner .main_banner__logo {
    position: absolute;
    right: 11px;
    top: 90px;
    width: 140px;
    height: 52px;
    background-color: #fff;
    border-radius: 50%;
    background-size: 100px;
    background-position: center; }
  .order-banner .caption {
    position: absolute;
    left: 13px;
    bottom: 70px;
    font-size: 25px;
    text-transform: uppercase;
    color: #fff;
    line-height: 31px; }
    .order-banner .caption span {
      display: block;
      font-size: 32px;
      line-height: 32px; }
  .order-banner .text {
    position: absolute;
    bottom: 23px;
    left: 13px;
    font-size: 16px;
    font-weight: bold; }

.order-form {
  margin-bottom: 40px; }
  .order-form textarea {
    width: 100%;
    height: 105px;
    resize: none;
    border-radius: 3px;
    border: 1px solid #777;
    padding: 10px;
    vertical-align: middle; }
  .order-form-top {
    border: 1px solid #e4e6ed;
    padding-top: 22px;
    padding-bottom: 29px; }
  .order-form-left {
    width: 216px;
    padding-left: 33px;
    float: left;
    min-height: 1px; }
    .order-form-left .text {
      font-size: 16px;
      line-height: 34px;
      margin-bottom: 18px; }
  .order-form-right {
    float: left; }
    .order-form-right .ui-selectmenu-button.ui-button {
      width: 280px; }
    .order-form-right label {
      display: block;
      width: 280px;
      margin-bottom: 18px; }
    .order-form-right .checkbox {
      padding-left: 51px;
      margin-top: 30px;
      margin-bottom: 30px;
      width: 100%;
      font-size: 14px; }
    .order-form-right .check {
      top: -8px;
      width: 34px;
      height: 34px;
      border-radius: 3px;
      transform: scale(0.75);
      will-change: transform; }
    .order-form-right input:checked + .check::before {
      position: static;
      width: 100%;
      height: 100%;
      background: url(../img/check-black.png) center no-repeat; }
  .order-form-middle {
    padding-bottom: 22px;
    padding-top: 22px;
    border: 1px solid #e4e6ed;
    border-top: none; }

.legal-inputs {
  padding-left: 33px;
  padding-right: 33px;
  margin-bottom: -10px; }
  .legal-inputs label {
    width: 50%;
    margin-bottom: 10px;
    float: left;
    font-size: 16px; }
    .legal-inputs label:nth-child(2n) {
      padding-left: 6px; }
    .legal-inputs label:nth-child(2n-1) {
      padding-right: 6px; }

.order-comment label {
  width: 605px;
  max-width: 100%;
  margin-bottom: 0; }

.order__coupon {
  padding: 22px 0;
  border: 1px solid #e4e6ed;
  border-top: none; }
  .order__coupon label {
    margin-bottom: 0;
    width: 280px; }

.order-self {
  margin-top: 30px; }

.order-method {
  margin-bottom: 30px;
  padding-top: 22px;
  padding-bottom: 22px;
  border: 1px solid #e4e6ed;
  border-top: none; }

.order__choose {
  display: inline-block;
  height: 40px;
  padding-left: 50px;
  padding-right: 50px;
  line-height: 38px;
  background-color: #fff;
  border: 1px solid #00adee;
  border-radius: 4px;
  color: #00adee; }

.order-where {
  margin-bottom: 25px;
  padding-left: 34px;
  padding-right: 34px;
  max-height: 335px;
  overflow: auto;
  background-color: #fafafa;
  counter-reset: order-where;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6; }
  .order-where__item {
    position: relative;
    padding: 38px  185px 20px 80px; }
    .order-where__item:not(:last-child) {
      border-bottom: 1px solid #e6e6e6; }
    .order-where__item::before {
      content: counter(order-where);
      counter-increment: order-where;
      display: block;
      position: absolute;
      width: 56px;
      height: 56px;
      border-radius: 50%;
      background-color: #fff;
      text-align: center;
      line-height: 56px;
      font-size: 18px;
      font-weight: bold;
      top: 21px;
      left: 0; }
    .order-where__item .caption-16 {
      color: #00adee; }
    .order-where__item .text {
      font-weight: 200; }
    .order-where__item label {
      position: absolute;
      top: 71px;
      right: 16px;
      cursor: pointer; }

input:checked + .order__choose {
  background-color: #00adee;
  color: #fff;
  font-size: 0; }
  input:checked + .order__choose .text-alt {
    display: inline;
    font-size: 14px; }

.text-alt {
  display: none; }

.text-alert {
  color: #00adee; }

.order-free {
  margin-bottom: 30px;
  position: relative;
  padding: 25px 35px 15px;
  border: 1px solid #e6e6e6; }
  .order-free .text {
    font-size: 16px;
    font-weight: bold;
    line-height: 18px;
    margin-bottom: 10px;
    width: 533px;
    max-width: 100%; }
    .order-free .text .price {
      color: #00adee;
      font-size: 20px;
      margin-left: 5px; }
  .order-free .return {
    position: absolute;
    bottom: 30px;
    right: 22px;
    width: auto;
    height: 40px;
    line-height: 38px;
    padding-left: 61px;
    padding-right: 61px;
    color: #00adee;
    background-color: #fff;
    border: 1px solid #00adee; }
    .order-free .return:hover {
      background-color: #00adee;
      color: #fff; }
    .order-free .return:active {
      box-shadow: inset 4px 4px 2px 0 rgba(0, 0, 0, 0.2); }

.order-total-right {
  float: left;
  width: 334px;
  padding: 20px;
  border: 1px solid #e4e6ed; }
  .order-total-right .text {
    margin-bottom: 5px; }
  .order-total-right .caption-16 {
    margin-bottom: 17px;
    line-height: 20px; }
    .order-total-right .caption-16 .price {
      color: #333;
      font-size: 30px;
      margin-left: 10px; }
  .order-total-right .form-button {
    height: 40px;
    line-height: 38px;
    background-color: #39b455;
    border-color: #39b455;
    text-transform: none; }
    .order-total-right .form-button:hover {
      color: #39b455;
      background-color: #fff; }

.order-total-left {
  margin-right: 27px;
  float: left;
  width: 509px;
  padding: 20px 20px 20px 34px;
  background-color: #fafafa;
  border: 1px solid #e4e6ed; }
  .order-total-left .caption-16 {
    margin-bottom: 15px; }
  .order-total-left .price {
    margin-left: 15px;
    font-size: 20px;
    line-height: 20px;
    color: #333; }
  .order-total-left .ui-selectmenu-button.ui-button {
    width: 264px;
    float: right; }
  .order-total-left label {
    width: 100%;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 34px; }
  .order-total-left .text {
    width: 294px;
    max-width: 100%; }
    .order-total-left .text a, .order-total-left .text .about-shops span, .about-shops .order-total-left .text span {
      color: #333;
      text-decoration: underline; }
      .order-total-left .text a:hover, .order-total-left .text .about-shops span:hover, .about-shops .order-total-left .text span:hover {
        text-decoration: none; }

.payment {
  margin-bottom: 40px; }
  .payment-container {
    padding: 25px 35px;
    border: 1px solid #e4e6ed; }
    .payment-container .caption {
      margin-bottom: 25px;
      font-size: 22px; }
    .payment-container .text {
      width: 450px;
      max-width: 100%;
      margin-bottom: 20px;
      padding-bottom: 15px;
      border-bottom: 1px solid #e6e6e6;
      font-size: 16px;
      line-height: 34px;
      letter-spacing: 0; }
      .payment-container .text .gray {
        margin-left: 20px; }
    .payment-container .caption-16 {
      margin-bottom: 45px; }
      .payment-container .caption-16 .price {
        margin-left: 20px;
        color: #333; }
    .payment-container .form-button {
      width: auto;
      height: 40px;
      line-height: 38px;
      padding-left: 110px;
      padding-right: 100px;
      border-color: #39b455;
      background-color: #39b455;
      text-transform: none; }
      .payment-container .form-button:hover {
        color: #39b455;
        background-color: #fff; }
  .payment-table {
    margin-bottom: 20px; }
    .payment-table table {
      border-collapse: collapse;
      border: 1px solid #e6e6e6; }
    .payment-table td {
      height: 97px;
      width: 175px;
      text-align: center;
      border: 1px solid #e6e6e6;
      font-size: 16px; }
      .payment-table td b {
        display: block;
        line-height: 27px; }
      .payment-table td:nth-child(2) {
        width: 180px; }

.thanks-container {
  margin-top: 30px;
  margin-bottom: 40px;
  padding: 35px 15px 15px;
  border: 1px solid #e4e6ed; }

.thanks__img {
  float: left;
  width: 243px;
  margin-right: 50px; }
  .thanks__img .img-bg {
    height: 182px; }

.thanks .text {
  width: 606px;
  max-width: 100%;
  float: left;
  margin-bottom: 25px;
  font-size: 16px; }

.thanks-links {
  width: 606px;
  max-width: 100%;
  float: left;
  margin-top: 30px;
  margin-bottom: 15px; }
  .thanks-links a, .thanks-links .about-shops span, .about-shops .thanks-links span {
    display: inline-block;
    height: 40px;
    line-height: 38px;
    margin-right: 20px;
    min-width: 280px;
    text-align: center;
    border: 1px solid #39b455;
    border-radius: 3px; }
    .thanks-links a:hover, .thanks-links .about-shops span:hover, .about-shops .thanks-links span:hover {
      text-decoration: none; }
    .thanks-links a:active, .thanks-links .about-shops span:active, .about-shops .thanks-links span:active {
      box-shadow: inset 4px 4px 2px 0 rgba(0, 0, 0, 0.2); }

.to-main {
  color: #39b455; }
  .to-main:hover {
    color: #39b455; }

.to-blog {
  color: #fff;
  background-color: #39b455; }
  .to-blog:hover {
    color: #fff; }

@media screen and (max-width: 1199px) {
  .order .col-lg-3.col-lg-push-9 {
    float: right;
    width: 300px; }
  .order .col-lg-9.col-lg-pull-3 {
    float: left;
    width: 724px; }
  .order-form-right {
    width: 472px; }
  .legal-inputs label:nth-child(2n-1) {
    padding-right: 13px; }
  .legal-inputs label:nth-child(2n) {
    padding-left: 13px; }
  .order__choose {
    padding-left: 40px;
    padding-right: 40px; }
  .order-free {
    padding-bottom: 25px; }
  .order-total-left {
    width: 100%;
    margin-right: 0; }
  .order-total-right {
    width: 100%;
    position: relative; }
    .order-total-right .form-button {
      position: absolute;
      width: auto;
      padding-left: 73px;
      padding-right: 73px;
      top: 45px;
      right: 21px; }
  .cart-page .col-lg-9 {
    width: 724px;
    float: left; }
  .cart-page .col-lg-3 {
    width: 300px;
    float: left; }
  .cart-card-middle {
    width: 356px; }
  .registration-form {
    width: 50%; }
    .registration-form .checkbox {
      padding-right: 35px; }
    .registration-form .text {
      width: 536px; }
  .registration-socials {
    width: 50%;
    padding-left: 30px;
    padding-right: 0; }
  .registration-banner {
    padding-left: 666px;
    padding-top: 10px;
    padding-bottom: 20px;
    background-size: contain; } }

@media screen and (max-width: 1023px) {
  .thanks .text {
    max-width: 100%;
    width: 411px;
    margin-top: 25px; }
    .thanks .text:nth-child(2) {
      width: 300px; }
  .thanks-links {
    width: 100%;
    text-align: center; }
  .order .col-lg-3.col-lg-push-9 {
    width: 100%; }
  .order .col-lg-9.col-lg-pull-3 {
    width: 100%; }
  .order-total-left .text {
    width: 100%; }
  .order-banner {
    height: 100px; }
    .order-banner .main_banner__logo {
      top: 30px;
      right: -3px;
      transform: scale(0.7); }
  .order-banner .text {
    bottom: 17px;
    font-size: 19px; }
    .order-banner .text br {
      display: none; }
  .order-banner .caption {
    top: 20px;
    font-size: 34px; }
    .order-banner .caption span {
      margin-left: 10px;
      font-size: 34px;
      display: inline; }
  .cart-page .col-lg-9 {
    width: 475px; }
  .cart-page .col-lg-3 {
    width: 290px; }
  .cart-card {
    position: relative;
    padding-bottom: 75px; }
    .cart-card-actions {
      position: absolute;
      width: 140px;
      left: 17px;
      top: 172px; }
      .cart-card-actions .stars {
        display: inline; }
        .cart-card-actions .stars span {
          margin-left: 0; }
        .cart-card-actions .stars i:last-of-type {
          margin-right: 35%; }
    .cart-card-middle {
      width: 247px; }
  .cart__adiing {
    position: absolute;
    bottom: 30px;
    left: 179px; }
  .registration-form {
    padding-left: 14px; }
    .registration-form .checkbox {
      font-size: 14px;
      line-height: 18px; }
    .registration-form .text {
      max-width: 100%; }
  .registration-banner {
    margin-bottom: 26px;
    padding-left: 400px;
    background-size: cover; } }

@media all and (max-width: 767px) {
  .thanks__img {
    display: none; }
  .thanks h1 {
    text-align: center;
    margin-top: -15px;
    margin-bottom: 0; }
  .thanks .text {
    margin-top: 0; }
  .thanks-container {
    margin-top: 10px;
    padding-top: 20px; }
  .thanks-links {
    margin-top: 0; }
    .thanks-links a, .thanks-links .about-shops span, .about-shops .thanks-links span {
      margin-right: 0; }
    .thanks-links .to-main {
      margin-bottom: 10px; }
  .payment h1 {
    margin-bottom: 15px;
    margin-top: -15px;
    text-align: center; }
  .payment-container {
    padding: 15px; }
    .payment-container .caption {
      font-size: 17px; }
  .payment-table td {
    padding-left: 15px;
    padding-right: 15px; }
    .payment-table td b {
      line-height: 23px; }
  .order-banner {
    background-position: -200px center; }
    .order-banner .text {
      bottom: 27px;
      font-size: 12px; }
    .order-banner .caption {
      bottom: auto;
      top: 25px;
      font-size: 22px; }
      .order-banner .caption span {
        font-size: 22px; }
  .order-form-left {
    padding-left: 15px;
    width: 150px; }
    .order-form-left .text {
      font-size: 14px;
      letter-spacing: 0; }
  .order-form-right {
    width: 288px;
    max-width: 100%; }
    .order-form-right .checkbox {
      width: 450px;
      margin-left: -135px;
      max-width: 450px; }
  .legal-inputs label {
    width: 100%; }
  .legal-inputs label:nth-child(2n),
  .legal-inputs label:nth-child(2n-1) {
    padding-left: 50px;
    padding-right: 50px;
    font-size: 14px; }
  .order-where__item {
    padding-right: 0;
    padding-bottom: 66px; }
    .order-where__item label {
      top: auto;
      bottom: 15px;
      right: -15px; }
  .order-free {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 65px; }
    .order-free .return {
      bottom: 16px; }
    .order-free .text {
      font-size: 14px; }
      .order-free .text:nth-child(2) {
        font-size: 16px; }
  .order-total-left {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 30px; }
    .order-total-left .text {
      width: 304px; }
  .order-total-right {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 66px; }
    .order-total-right .form-button {
      top: auto;
      bottom: 20px; }
  h1 {
    font-size: 18px; }
  .cart-page h1 {
    text-align: center;
    position: relative;
    top: -12px;
    margin-bottom: 0; }
  .cart-page .col-lg-9 {
    margin-bottom: 30px;
    width: 100%; }
  .cart-page .col-lg-3 {
    width: 100%; }
  .cart__confirm {
    padding-bottom: 28px; }
    .cart__confirm .price {
      float: right; }
    .cart__confirm .text {
      float: left;
      width: 66%;
      min-height: 50px; }
    .cart__confirm .confirm {
      float: right; }
  .registration-container {
    padding: 0;
    border: none;
    margin-bottom: 10px; }
  .registration-form {
    width: 100%;
    padding-left: 0;
    margin-bottom: 16px; }
    .registration-form__bottom {
      margin-left: auto;
      margin-right: auto; }
    .registration-form .text {
      padding-left: 30px;
      padding-right: 30px;
      font-size: 14px;
      line-height: 18px; }
    .registration-form label {
      margin-left: auto;
      margin-right: auto;
      max-width: 100%; }
  .registration-socials {
    width: 100%;
    padding-left: 0;
    height: auto;
    border-left: none;
    padding-top: 16px;
    border-top: 1px solid #e6e6e6; }
  .registration-form__checkboxes {
    width: 278px;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%; }
  .registration-banner {
    display: none; }
  .order h1 {
    text-align: center;
    margin-bottom: 10px;
    margin-top: -15px; } }

@media all and (max-width: 479px) {
  .thanks h1 {
    margin-top: 0;
    margin-bottom: 5px; }
  .thanks-links a, .thanks-links .about-shops span, .about-shops .thanks-links span {
    width: 100%;
    min-width: 0; }
  .payment h1 {
    margin-top: 0;
    margin-bottom: 5px; }
  .payment-table td {
    font-size: 14px;
    padding: 0; }
  .payment-container .form-button {
    width: 100%;
    padding: 0; }
  .payment-container .caption-16 .price {
    display: block;
    margin-left: 0; }
  .payment-container .text {
    font-size: 14px; }
  .order h1 {
    margin-top: 0;
    margin-bottom: 5px; }
  .order-banner .main_banner__logo {
    display: none; }
  .order-form-left {
    width: 100%; }
    .order-form-left .text {
      margin-bottom: 40px; }
  .order-form-right {
    position: relative;
    padding-left: 15px;
    top: 40px;
    margin-bottom: 40px;
    margin-left: -100%;
    width: 100%; }
    .order-form-right .ui-selectmenu-button.ui-button {
      width: 260px; }
    .order-form-right .checkbox {
      margin-left: 0;
      padding-right: 15px;
      width: 100%; }
    .order-form-right label {
      margin-bottom: 40px;
      width: 260px; }
  .legal-inputs label:nth-child(2n),
  .legal-inputs label:nth-child(2n-1) {
    padding-left: 0;
    padding-right: 0; }
  .order-where__item {
    padding-left: 0;
    padding-top: 15px; }
    .order-where__item::before {
      margin-bottom: 15px;
      position: static;
      margin-left: auto;
      margin-right: auto; }
  .order-free .return {
    width: calc(100% - 30px);
    padding-left: 0;
    padding-right: 0;
    right: 15px; }
  .cart-page h1 {
    position: static;
    margin-bottom: 5px; }
  .registration-form .text {
    padding-left: 5px;
    padding-right: 5px; }
  .cart__adiing {
    left: 33px;
    bottom: 14px; }
  .cart-card {
    padding-bottom: 50px; }
    .cart-card .quantity-inputs {
      margin-bottom: 0; }
    .cart-card__img {
      float: none;
      margin-left: auto;
      margin-right: auto;
      padding-right: 0;
      margin-bottom: 5px; }
    .cart-card-middle {
      width: 100%;
      padding-right: 0;
      margin-bottom: 5px; }
    .cart-card-actions {
      position: static;
      width: 100%;
      margin: 0; }
      .cart-card-actions .stars {
        display: inline-block; }
        .cart-card-actions .stars i:last-of-type {
          margin-right: 10px; }
    .cart-card .caption-16 {
      margin-bottom: 5px; }
  .cart-card-right {
    width: 100%; }
    .cart-card-right .text {
      float: left;
      margin-right: 10px; }
  .cart__delete {
    font-size: 12px;
    position: absolute;
    right: 13px;
    bottom: 97px;
    width: 67px; } }

.article-aside .caption {
  margin-bottom: 30px;
  font-size: 22px; }

.article-aside-products {
  margin-bottom: 38px; }

.article-posts__link {
  display: block;
  margin-bottom: 27px;
  border: 1px solid #e4e6ed; }
  .article-posts__link:hover {
    text-decoration: none; }
  .article-posts__link .img-bg {
    height: 200px;
    background-size: cover;
    border-bottom: 1px solid #e4e6ed; }
  .article-posts__link .text {
    display: block;
    margin-bottom: 5px;
    padding: 15px;
    font-weight: bold;
    font-size: 16px;
    color: #333;
    transition-duration: .2s; }
    .article-posts__link .text:hover {
      color: #00adee; }

.license h1 {
  margin-bottom: 40px; }

.license-wrapper {
  margin-left: -30px; }
  .license-wrapper .sales-item .caption {
    height: 40px;
    font-size: 16px;
    line-height: 18px;
    font-weight: normal;
    color: #00adee; }
    .license-wrapper .sales-item .caption:hover {
      color: #333;
      text-decoration: none; }
  .license-wrapper .sales-item__bottom span.gray {
    padding-left: 0;
    margin-left: 0;
    border-left: none; }

.leave .caption-16 {
  margin-bottom: 10px;
  font-size: 22px; }

.leave-container {
  margin-bottom: 25px;
  padding: 21px;
  border: 1px solid #e4e6ed; }
  .leave-container .form-button {
    width: 195px;
    text-transform: none;
    height: 40px;
    line-height: 38px; }

.leave-form {
  display: none;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #f0f0f0;
  padding: 22px; }
  .leave-form label {
    font-weight: 200;
    width: 270px;
    padding-right: 10px;
    vertical-align: bottom;
    margin: 0; }
  .leave-form .textarea {
    margin-bottom: 15px;
    width: 100%;
    padding: 0; }
  .leave-form textarea {
    margin-top: 5px;
    width: 100%;
    display: block;
    height: 111px;
    padding: 15px;
    border-color: transparent;
    resize: none; }
  .leave-form input {
    margin-top: 5px;
    border-color: transparent;
    height: 40px;
    line-height: 40px;
    border-radius: 0; }
  .leave-form .form-button {
    margin-left: 38px;
    vertical-align: bottom; }

.leave-comments {
  margin-top: 20px; }
  .leave-comments__item {
    min-height: 100px;
    position: relative;
    padding-top: 19px;
    padding-left: 95px; }
    .leave-comments__item .person {
      position: absolute;
      top: 0;
      left: 0; }
    .leave-comments__item p:last-child {
      margin-bottom: 0; }
  .leave-comments__name {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 16px; }
    .leave-comments__name .gray {
      display: inline-block;
      padding-left: 11px;
      margin-left: 11px;
      border-left: 1px solid #333;
      font-size: 14px;
      line-height: 17px;
      vertical-align: middle;
      font-weight: normal; }

.person {
  display: inline-block;
  background: url(../img/person.png) center no-repeat;
  width: 74px;
  height: 74px; }

.article-top {
  display: flex;
  margin-bottom: 10px;
  padding-bottom: 4px;
  border-bottom: 1px solid #e6e6e6;
  justify-content: space-between;
  align-items: center; }

.article__date {
  color: #777; }
  .article__date_in {
    display: inline-block;
    padding-left: 33px;
    background: url(../img/article-date.png) 4px 0 no-repeat; }
  .article__date .inherits {
    margin-left: 3px;
    margin-right: 3px; }

.article__author {
  color: #777;
  font-size: 16px; }
  .article__author .inherits {
    margin-left: 3px;
    margin-right: 3px; }

.article-content {
  margin-bottom: 55px; }
  .article-content img {
    margin-bottom: 20px; }
  .article-content p {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 30px;
    font-weight: 200; }
    .article-content p:not(:last-child) {
      margin-bottom: 30px; }
  .article-content ul, .article-content ol {
    list-style: none;
    margin-top: 40px;
    margin-bottom: 40px;
    padding-left: 40px; }
  .article-content li, .article-content .support-wrapper p, .support-wrapper .article-content p {
    position: relative;
    font-weight: 200;
    margin-bottom: 15px;
    color: #333;
    font-size: 18px;
    line-height: 30px; }
    .article-content li::before, .article-content .support-wrapper p::before, .support-wrapper .article-content p::before {
      position: absolute;
      display: block;
      top: 12px;
      left: -20px; }
  .article-content ul li::before, .article-content ul .support-wrapper p::before, .support-wrapper .article-content ul p::before {
    content: '';
    width: 6px;
    height: 6px;
    background-color: #00adee;
    border-radius: 50%; }
  .article-content ol {
    counter-reset: article-list; }
    .article-content ol li::before, .article-content ol .support-wrapper p::before, .support-wrapper .article-content ol p::before {
      content: counter(article-list);
      counter-increment: article-list;
      top: 0;
      left: -22px;
      color: #00adee; }
  .article-content iframe {
    display: block;
    margin-top: 40px;
    margin-bottom: 40px;
    width: 100%;
    height: 483px; }
  .article-content blockquote {
    margin-bottom: 20px;
    padding: 15px 65px;
    position: relative;
    background-color: #fafafa;
    font-size: 18px;
    line-height: 30px;
    font-weight: 200;
    border: none;
    border-bottom: 3px solid #00adee; }
    .article-content blockquote::before {
      position: absolute;
      top: 15px;
      left: 25px;
      font-family: FontAwesome;
      content: '\f100';
      display: inline-block;
      color: #00adee;
      font-size: 30px; }
  .article-content .article__img {
    margin-bottom: 20px; }
    .article-content .article__img img {
      margin-bottom: 10px; }
    .article-content .article__img p {
      text-align: center;
      font-size: 15px;
      font-style: italic;
      line-height: 20px; }
  .article-content h2 {
    font-size: 25px; }
  .article-content h3 {
    font-size: 21px;
    font-weight: bold; }

.article-bottom {
  margin-bottom: 30px;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  padding-top: 6px;
  padding-bottom: 6px; }
  .article-bottom .text {
    float: left;
    margin-right: 23px;
    line-height: 28px; }

.article__sharing a, .article__sharing .about-shops span, .about-shops .article__sharing span {
  display: inline-block;
  line-height: 28px;
  color: #fff;
  width: 120px;
  text-align: center;
  margin-right: 12px;
  letter-spacing: 0; }
  .article__sharing a:hover, .article__sharing .about-shops span:hover, .about-shops .article__sharing span:hover {
    text-decoration: none; }

.vk {
  background-color: #3d5e8b; }

.facebook {
  background-color: #2d409f; }

.ok {
  background-color: #ff8226; }

.tw {
  background-color: #00adee; }

.gp {
  background-color: #bb3b02; }

.sales h1 {
  margin-bottom: 40px; }

.sales-wrapper {
  margin-left: -30px; }

.sales-item {
  margin-bottom: 30px;
  float: left;
  width: calc(33.33% - 30px);
  margin-left: 30px;
  border: 1px solid #e4e6ed; }
  .sales-item__image {
    margin-bottom: 15px;
    display: block;
    position: relative; }
    .sales-item__image .img-bg {
      height: 200px;
      -webkit-background-size: cover;
      background-size: cover; }
    .sales-item__image:hover .img-bg {
      opacity: .4; }
  .sales-item .caption {
    margin-bottom: 10px;
    height: 56px;
    overflow: hidden;
    font-size: 19px;
    padding-left: 20px; }
    .sales-item .caption:hover {
      color: #333; }
  .sales-item .text {
    margin-bottom: 10px;
    padding-right: 60px;
    height: 40px;
    overflow: hidden;
    color: #777;
    padding-left: 20px; }
    .sales-item .text p {
      font-size: 14px;
      line-height: 20px; }
  .sales-item__link {
    margin-bottom: 14px;
    display: inline-block;
    padding-left: 20px; }
    .sales-item__link .fa {
      font-size: 20px;
      position: relative;
      top: 2px;
      margin-left: 10px; }
    .sales-item__link:hover {
      text-decoration: none;
      color: #333; }
  .sales-item__bottom {
    line-height: 32px;
    padding-left: 20px;
    border-top: 1px solid #e4e6ed;
    height: 33px;
    overflow: hidden;
    white-space: nowrap; }
    .sales-item__bottom span {
      display: inline-block;
      line-height: 17px;
      vertical-align: middle; }
      .sales-item__bottom span.gray {
        padding-left: 11px;
        border-left: 1px solid #333;
        margin-left: 11px; }

.sales-banner {
  height: 130px;
  padding-left: 824px;
  padding-top: 17px;
  margin-bottom: 40px;
  background-repeat: no-repeat;
  background-size: cover; }
  .sales-banner .caption-16 {
    font-size: 18px; }
  .sales-banner .main_banner__logo {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
    margin-right: 11px;
    width: 206px;
    background-size: contain;
    background-position: center;
    border-bottom: 3px solid #00adee; }
  .sales-banner .form-button {
    position: relative;
    top: 5px;
    width: auto;
    height: 38px;
    padding-left: 21px;
    padding-right: 21px;
    line-height: 36px; }

.sale-page .catalog-description a:hover, .sale-page .catalog-description .about-shops span:hover, .about-shops .sale-page .catalog-description span:hover {
  color: #333;
  text-decoration: none; }

.sale-page .catalog-description a .fa, .sale-page .catalog-description .about-shops span .fa, .about-shops .sale-page .catalog-description span .fa {
  font-size: 20px;
  position: relative;
  top: 3px;
  margin-left: 10px; }

.sale-page h1 {
  margin-bottom: 40px; }

.sale-page .caption {
  margin-bottom: 25px;
  font-size: 24px; }

.sale__img {
  margin-bottom: 25px; }

.sale-wrapper {
  margin-left: -30px;
  margin-bottom: 12px; }
  .sale-wrapper .card-mini {
    float: left;
    width: calc(25% - 30px);
    margin-left: 30px;
    margin-bottom: 30px;
    border-right: 1px solid #e6e6e6;
    transition-duration: .2s; }
    .sale-wrapper .card-mini:hover {
      box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.35); }

.additional-text {
  display: none; }

.publications h1 {
  margin-bottom: 40px; }

.publications-nav {
  border-radius: 3px;
  border: 1px solid #e4e6ed; }
  .publications-nav ul {
    margin-bottom: 0; }
  .publications-nav span, .publications-nav a, .publications-nav .about-shops span, .about-shops .publications-nav span {
    width: 100%;
    display: inline-block;
    padding-left: 16px;
    padding-right: 16px;
    line-height: 48px;
    font-size: 16px;
    color: #333; }
    .publications-nav span:not(:last-child), .publications-nav a:not(:last-child), .publications-nav .about-shops span:not(:last-child), .about-shops .publications-nav span:not(:last-child) {
      border-bottom: 1px solid #e4e6ed; }
  .publications-nav span {
    background-color: #fafafa; }
  .publications-nav a:hover, .publications-nav .about-shops span:hover, .about-shops .publications-nav span:hover {
    background-color: #00adee;
    color: #fff;
    text-decoration: none; }
  .publications-nav li:not(:last-child), .publications-nav .support-wrapper p:not(:last-child), .support-wrapper .publications-nav p:not(:last-child) {
    border-bottom: 1px solid #e4e6ed; }

.publications-wrapper {
  margin-left: -30px; }
  .publications-wrapper .sales-item .caption {
    font-size: 16px;
    line-height: 18px;
    height: 36px; }
  .publications-wrapper .sales-item .text {
    height: 82px;
    position: relative;
    padding-right: 15px;
    line-height: 21px; }
    .publications-wrapper .sales-item .text::after {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      content: '';
      width: 100%;
      height: 21px;
      background-image: linear-gradient(to top, #fff, transparent); }
  .publications-wrapper .sales-item__bottom span.gray {
    width: 100%;
    text-align: right;
    border-left: none;
    margin-left: 0;
    padding-right: 15px; }

.publications__name {
  position: absolute;
  left: 0;
  bottom: 0;
  min-width: 105px;
  line-height: 22px;
  padding-left: 12px;
  padding-right: 12px;
  text-align: center;
  background-color: #00adee;
  color: #fff; }

.play {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  background: url(../img/play.png) center no-repeat;
  width: 82px;
  height: 59px;
  margin-top: -30px;
  margin-left: -41px; }

.article-container .article-aside {
  display: none; }

.ya-share2__item .ya-share2__badge {
  width: 100%;
  vertical-align: middle; }
  .ya-share2__item .ya-share2__badge .ya-share2__counter_visible {
    float: right; }
  .ya-share2__item .ya-share2__badge .ya-share2__icon {
    background: none; }

.ya-share2__item .ya-share2__badge .ya-share2__icon {
  vertical-align: middle;
  letter-spacing: 0.1em;
  width: auto;
  font-size: 12px;
  color: #fff;
  line-height: 24px; }
  .ya-share2__item .ya-share2__badge .ya-share2__icon::before {
    display: inline-block;
    vertical-align: middle; }

.ya-share2__item_service_vkontakte .ya-share2__icon::before {
  content: 'Vkontakte'; }

.ya-share2__item_service_facebook .ya-share2__icon::before {
  content: 'Facebook'; }

.ya-share2__item_service_twitter .ya-share2__icon::before {
  content: 'Twitter'; }

.ya-share2__item_service_odnoklassniki .ya-share2__icon::before {
  content: 'Odnoklassniki'; }

.ya-share2__item_service_gplus .ya-share2__icon::before {
  content: 'Google +'; }

@media screen and (max-width: 1199px) {
  .license-wrapper {
    margin-left: -26px; }
    .license-wrapper .sales-item {
      width: calc(33.33% - 26px);
      margin-left: 26px; }
      .license-wrapper .sales-item__image .img-bg {
        height: 170px; }
  .article .col-lg-9 {
    float: left;
    width: 727px; }
  .article .col-lg-3 {
    float: left;
    width: 297px; }
  .article-container {
    margin-bottom: 40px; }
    .article-container .catalog-list {
      display: none; }
  .leave-form label {
    width: 190px; }
  .leave-form .form-button {
    width: 150px; }
  .article-content iframe {
    height: 392px; }
  .news .publications-nav {
    border: none;
    border-radius: 0; }
    .news .publications-nav li:nth-child(n), .news .publications-nav .support-wrapper p:nth-child(n), .support-wrapper .news .publications-nav p:nth-child(n) {
      border: 1px solid #e4e6ed;
      margin-right: 20px;
      border-radius: 4px; }
  .publications-wrapper {
    margin-left: -80px; }
    .publications-wrapper .sales-item {
      width: calc(33.33% - 80px);
      margin-left: 80px; }
  .sales-item__image .img-bg {
    background-size: cover; }
  .sales-banner {
    padding-left: 660px;
    background-position: -176px center; }
  .publications-nav {
    float: left;
    margin-bottom: 30px;
    max-width: 100%; }
    .publications-nav ul {
      display: flex;
      width: 100%; }
    .publications-nav li, .publications-nav .support-wrapper p, .support-wrapper .publications-nav p {
      flex-shrink: 1;
      display: flex;
      float: left;
      width: 268px; }
      .publications-nav li:not(:last-child), .publications-nav .support-wrapper p:not(:last-child), .support-wrapper .publications-nav p:not(:last-child) {
        border-bottom: none;
        border-right: 1px solid #e4e6ed; }
      .publications-nav li > *, .publications-nav .support-wrapper p > *, .support-wrapper .publications-nav p > * {
        display: flex;
        align-items: center; }
  .publications h1 {
    margin-bottom: 35px; } }

@media screen and (max-width: 1023px) {
  .license h1 {
    margin-bottom: 30px; }
  .license-wrapper {
    margin-left: -27px; }
    .license-wrapper .sales-item {
      width: calc(50% - 27px);
      margin-left: 27px; }
      .license-wrapper .sales-item__image .img-bg {
        height: 190px; }
  .license .sales-banner {
    margin-bottom: 10px;
    margin-top: -15px;
    transform: scale(0.742);
    transform-origin: 100% 50%;
    display: block;
    margin-left: -256px; }
    .license .sales-banner .main_banner__logo {
      height: 36px; }
  .article h1 {
    text-align: center; }
  .article .col-lg-9 {
    width: 100%; }
  .article .col-lg-3 {
    width: 100%; }
  .article-container .article-aside {
    display: block; }
  .article-aside {
    display: none; }
    .article-aside > img {
      display: none; }
    .article-aside .caption {
      text-align: center;
      margin-bottom: 10px; }
    .article-aside .catalog-list__link:nth-child(n) {
      width: 33.33%;
      float: left;
      margin-bottom: 0;
      margin-left: 0;
      padding-left: 15px;
      min-height: 90px; }
  .article-posts-wrapper {
    margin-left: -11px; }
  .article-posts__link {
    width: calc(33.33% - 11px);
    float: left;
    margin-left: 11px; }
  .publications-wrapper {
    margin-left: -30px; }
    .publications-wrapper .sales-item {
      width: calc(50% - 30px);
      margin-left: 30px; }
  .publications h1 {
    text-align: center;
    margin-bottom: 25px; }
  .publications-wrapper {
    padding-left: 75px;
    padding-right: 75px; }
  .sales-item {
    width: calc(50% - 30px); }
  .sales-banner {
    display: none; }
  .sale-wrapper {
    margin-left: 0;
    margin-bottom: 0; }
    .sale-wrapper .card-mini {
      margin-left: 0;
      width: 33%; } }

@media screen and (max-width: 767px) {
  .article__author {
    font-size: 0; }
    .article__author .inherits {
      font-size: 14px; }
  .article__date {
    font-size: 0; }
    .article__date .inherits {
      font-size: 14px; }
    .article__date_in {
      font-size: 14px; }
  .license h1 {
    text-align: center;
    margin-top: -15px;
    margin-bottom: 10px; }
  .license-wrapper {
    margin-left: 0;
    padding-left: 40px;
    padding-right: 40px; }
    .license-wrapper .sales-item {
      width: 100%;
      margin-left: 0;
      margin-bottom: 33px; }
      .license-wrapper .sales-item__image .img-bg {
        height: 200px; }
  .license .sales-banner {
    display: none; }
  .article-content blockquote {
    padding-right: 20px; }
  .article-content h2 {
    font-size: 17px; }
  .article-content h3 {
    font-size: 16px; }
  .article-content p {
    line-height: 24px;
    font-size: 16px; }
    .article-content p:not(:last-child) {
      margin-bottom: 15px; }
  .article-content li, .article-content .support-wrapper p, .support-wrapper .article-content p {
    line-height: 24px;
    margin-bottom: 7.5px;
    font-size: 16px; }
  .article-content iframe {
    margin-top: 20px;
    margin-bottom: 20px;
    height: 252px; }
  .article-content ol, .article-content ul {
    margin-top: 20px;
    margin-bottom: 20px; }
  .article-bottom {
    position: relative;
    padding-left: 110px; }
    .article-bottom .text {
      position: absolute;
      top: 7px;
      left: 0; }
  .article__sharing a, .article__sharing .about-shops span, .about-shops .article__sharing span {
    margin-bottom: 10px; }
  .article-aside .catalog-list__link:nth-child(n) {
    width: 100%; }
  .article-posts__link {
    width: calc(50% - 11px);
    margin-bottom: 11px; }
    .article-posts__link .img-bg {
      height: 163px; }
  .leave-form label {
    width: 178px; }
  .leave-form .form-button {
    margin-left: 182px;
    margin-top: 11px; }
  .news .publications-nav {
    margin-right: -10px; }
    .news .publications-nav li:nth-child(n), .news .publications-nav .support-wrapper p:nth-child(n), .support-wrapper .news .publications-nav p:nth-child(n) {
      margin-right: 10px; }
  .publications-wrapper {
    margin-left: 0; }
    .publications-wrapper .sales-item {
      width: 100%;
      margin-left: 0; }
  .publications h1 {
    margin-bottom: 10px;
    margin-top: -15px; }
  .sales h1 {
    text-align: center;
    margin-bottom: 10px;
    margin-top: -15px; }
  .sales-wrapper {
    margin-left: 0;
    padding-left: 40px;
    padding-right: 40px; }
  .sales-item {
    width: 100%;
    margin-bottom: 20px;
    margin-left: 0; }
    .sales-item .img-bg {
      background-size: cover; }
  .sale__img {
    position: relative;
    overflow: hidden; }
    .sale__img img {
      width: 171%;
      margin-left: -71%;
      max-width: none; }
  .sale-page h1 {
    margin-top: -15px;
    margin-bottom: 10px;
    text-align: center; }
  .sale-page .caption {
    font-size: 18px; }
  .sale-wrapper .card-mini {
    width: 50%; }
  .sale-page .caption {
    margin-bottom: 15px; }
  .sale__img {
    margin-bottom: 15px; } }

@media screen and (max-width: 479px) {
  .license h1 {
    margin-bottom: 10px;
    margin-top: 0; }
  .leave-comments__item {
    padding-left: 0; }
    .leave-comments__item .person {
      position: static;
      float: left;
      margin-right: 10px;
      width: 30px;
      height: 30px;
      background-size: contain; }
  .leave-comments__name .gray {
    margin-left: 0;
    padding-left: 0;
    border-left: none;
    display: block; }
  .leave-form label {
    padding-right: 0;
    width: 100%; }
  .leave-form .form-button {
    margin-left: 0;
    width: 100%; }
  .leave-container .form-button {
    width: 100%; }
  .article-posts-wrapper {
    margin-left: 0; }
    .article-posts-wrapper .article-posts__link {
      width: 100%;
      margin-left: 0; }
      .article-posts-wrapper .article-posts__link .img-bg {
        height: 200px; }
  .license-wrapper {
    padding-left: 0;
    padding-right: 0; }
  .article h1 {
    margin-top: 10px; }
  .article-content iframe {
    height: 162px; }
  .news .publications-nav li:nth-child(n), .news .publications-nav .support-wrapper p:nth-child(n), .support-wrapper .news .publications-nav p:nth-child(n) {
    margin-right: 0;
    margin-bottom: 10px; }
  .publications h1 {
    margin-top: 0;
    margin-bottom: 5px; }
  .publications-wrapper {
    padding: 0; }
  .publications-nav {
    margin-bottom: 10px; }
    .publications-nav ul {
      flex-wrap: wrap; }
    .publications-nav li, .publications-nav .support-wrapper p, .support-wrapper .publications-nav p {
      width: 100%; }
      .publications-nav li:not(:last-child), .publications-nav .support-wrapper p:not(:last-child), .support-wrapper .publications-nav p:not(:last-child) {
        border-right: none;
        border-bottom: 1px solid #e4e6ed; }
  .sales h1 {
    margin-top: 0;
    margin-bottom: 5px; }
  .sales-wrapper {
    padding: 0; }
  .sale-wrapper .card-mini {
    width: 100%; }
  .sale-page h1 {
    margin-top: 0;
    margin-bottom: 5px; } }

.instructions-nav {
  display: flex;
  flex-wrap: wrap;
  margin: 0; }
  .instructions-nav li, .instructions-nav .support-wrapper p, .support-wrapper .instructions-nav p {
    display: inline-block;
    flex-grow: 1;
    width: 15px;
    margin-bottom: 15px; }
    .instructions-nav li a, .instructions-nav .support-wrapper p a, .support-wrapper .instructions-nav p a, .instructions-nav li .about-shops span, .about-shops .instructions-nav li span, .instructions-nav .support-wrapper p .about-shops span, .about-shops .instructions-nav .support-wrapper p span, .support-wrapper .instructions-nav p .about-shops span, .about-shops .support-wrapper .instructions-nav p span {
      font-size: 18px;
      text-transform: uppercase; }

.instructions-item {
  font-size: 16px; }
  .instructions-item table {
    width: 100%;
    border-collapse: collapse; }
  .instructions-item thead {
    font-weight: bold;
    background-color: #e6e6e6; }
    .instructions-item thead td {
      padding-bottom: 20px; }
  .instructions-item td {
    padding: 5px;
    vertical-align: top; }
    .instructions-item td:first-child {
      width: 200px; }
    .instructions-item td:nth-child(2) {
      width: 500px; }
    .instructions-item td:nth-child(3) a, .instructions-item td:nth-child(3) .about-shops span, .about-shops .instructions-item td:nth-child(3) span {
      display: block; }
  .instructions-item tbody tr:nth-child(2n) {
    background-color: #fafafa; }

.instructions .caption {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase; }
  .instructions .caption a, .instructions .caption .about-shops span, .about-shops .instructions .caption span {
    font-weight: normal;
    font-size: 16px;
    text-transform: none; }
    .instructions .caption a .fa, .instructions .caption .about-shops span .fa, .about-shops .instructions .caption span .fa {
      margin-right: 10px;
      margin-left: 10px; }

.about-shops > .caption-16 {
  display: none; }

.about-shops .catalog-nav {
  margin-top: 13px; }

.lk-message__nav {
  margin-top: 30px;
  border: 1px solid #e4e6ed;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 6px; }
  .lk-message__nav ul {
    margin: 0; }
  .lk-message__nav li, .lk-message__nav .support-wrapper p, .support-wrapper .lk-message__nav p {
    font-size: 16px;
    line-height: 18px;
    vertical-align: middle; }
    .lk-message__nav li .gray, .lk-message__nav .support-wrapper p .gray, .support-wrapper .lk-message__nav p .gray {
      font-size: 12px;
      margin-left: 5px; }
    .lk-message__nav li > span, .lk-message__nav .support-wrapper p > span, .support-wrapper .lk-message__nav p > span {
      display: inline-block;
      padding-left: 10px;
      padding-right: 10px; }
    .lk-message__nav li:not(:last-child), .lk-message__nav .support-wrapper p:not(:last-child), .support-wrapper .lk-message__nav p:not(:last-child) {
      border-right: 1px solid #e4e6ed; }
    .lk-message__nav li a, .lk-message__nav .support-wrapper p a, .support-wrapper .lk-message__nav p a, .lk-message__nav li .about-shops span, .about-shops .lk-message__nav li span, .lk-message__nav .support-wrapper p .about-shops span, .about-shops .lk-message__nav .support-wrapper p span, .support-wrapper .lk-message__nav p .about-shops span, .about-shops .support-wrapper .lk-message__nav p span {
      display: inline-block;
      padding-left: 10px;
      padding-right: 10px;
      color: #333; }
      .lk-message__nav li a:hover, .lk-message__nav .support-wrapper p a:hover, .support-wrapper .lk-message__nav p a:hover, .lk-message__nav li .about-shops span:hover, .about-shops .lk-message__nav li span:hover, .lk-message__nav .support-wrapper p .about-shops span:hover, .about-shops .lk-message__nav .support-wrapper p span:hover, .support-wrapper .lk-message__nav p .about-shops span:hover, .about-shops .support-wrapper .lk-message__nav p span:hover {
        color: #00adee;
        text-decoration: none; }

.lk-message {
  margin-bottom: 30px; }
  .lk-message__tr {
    border: 1px solid #e4e6ed;
    border-top: none; }
    .lk-message__tr:not(:nth-child(2)) {
      border-bottom-width: 2px; }
  .lk-message__th {
    float: left;
    line-height: 44px;
    background-color: #5ccbf5;
    border-right: 1px solid #49b5de;
    font-weight: bold;
    font-size: 15px; }
    .lk-message__th:nth-child(1) {
      width: 140px; }
    .lk-message__th:nth-child(2) {
      width: 623px; }
    .lk-message__th:nth-child(3) {
      width: 105px;
      border-right: none; }
  .lk-message__td {
    float: left;
    border-right: 1px solid #e4e6ed;
    padding: 15px 25px; }
    .lk-message__td p {
      font-size: 16px;
      font-weight: 200;
      line-height: 23px; }
      .lk-message__td p:last-child {
        margin-bottom: 0; }
    .lk-message__td:nth-child(1) {
      font-size: 16px;
      font-weight: 200;
      line-height: 23px;
      color: #a0a0a0;
      width: 140px;
      text-align: center;
      border-left: 1px solid #e4e6ed; }
    .lk-message__td:nth-child(2) {
      position: relative;
      width: 623px;
      cursor: pointer;
      max-height: 213px;
      overflow: hidden; }
      .lk-message__td:nth-child(2)::after {
        position: absolute;
        content: '';
        display: block;
        width: 100%;
        height: 40px;
        left: 0;
        bottom: 0;
        background: linear-gradient(to bottom, transparent, #fff);
        transition-duration: .2s; }
      .lk-message__td:nth-child(2).active {
        max-height: none; }
        .lk-message__td:nth-child(2).active::after {
          height: 0; }
    .lk-message__td:nth-child(3) {
      font-size: 16px;
      font-weight: 200;
      line-height: 23px;
      color: #a0a0a0;
      width: 105px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center; }

.lk-favorites {
  margin-left: -4px;
  margin-top: 18px;
  margin-bottom: 6px; }
  .lk-favorites .card-mini {
    float: left;
    width: calc(33.33% - 34px);
    margin-left: 34px;
    margin-bottom: 34px;
    border-right: 1px solid #e6e6e6; }
    .lk-favorites .card-mini .fa-heart-o::before {
      background-image: url(../img/dislike.svg);
      background-size: contain;
      vertical-align: middle;
      height: 23px;
      width: 23px;
      display: inline-block;
      background-repeat: no-repeat;
      content: '';
      background-position: center; }
    .lk-favorites .card-mini .add-favorites span {
      width: 165px; }

.lk-tabs-wrapper {
  margin-bottom: 40px; }

.lk-order {
  margin-bottom: 35px;
  border: 1px solid #e4e6ed;
  border-width: 2px; }
  .lk-order-top {
    margin: -2px -1px 0;
    padding: 17px 33px;
    border-bottom: 1px solid #e4e6ed;
    background-color: #5ccbf5; }
    .lk-order-top .text {
      font-size: 15px; }
      .lk-order-top .text:not(:last-child) {
        margin-bottom: 5px; }
      .lk-order-top .text b {
        display: inline-block;
        width: 125px; }

.lk-orders-item {
  position: relative;
  padding: 36px 33px;
  border-bottom: 1px solid #e4e6ed; }
  .lk-orders-item .lk__leave-comment {
    position: absolute;
    top: 33px;
    right: 33px; }
  .lk-orders-item a:hover, .lk-orders-item .about-shops span:hover, .about-shops .lk-orders-item span:hover {
    text-decoration: none;
    color: #333; }
  .lk-orders-item .stars {
    margin-right: 10px; }
    .lk-orders-item .stars i {
      margin-right: 2px; }
    .lk-orders-item .stars span {
      margin-left: 10px;
      color: #333; }
  .lk-orders-item__bottom {
    position: absolute;
    bottom: 32px;
    left: 310px;
    right: 33px; }
    .lk-orders-item__bottom .color__item {
      margin-right: 10px;
      border-radius: 0;
      cursor: default;
      border: 1px solid #e4e6ed; }
    .lk-orders-item__bottom .price {
      color: #333;
      margin-left: 10px; }
  .lk-orders-item__color {
    float: left; }
  .lk-orders-item .img-bg {
    float: left;
    height: 187px;
    width: 250px;
    margin-right: 27px;
    background-size: contain;
    border: 1px solid #e4e6ed; }
  .lk-orders-item .caption-16 {
    display: inline-block;
    margin-top: -7px;
    margin-bottom: 30px;
    font-weight: normal;
    letter-spacing: 0;
    color: #333; }
    .lk-orders-item .caption-16 span {
      font-weight: bold;
      font-size: 20px; }
    .lk-orders-item .caption-16:hover {
      text-decoration: none;
      color: #00adee; }

.lk-order-bottom {
  padding: 20px;
  border-top: 1px solid #e4e6ed;
  font-weight: bold;
  font-size: 16px; }
  .lk-order-bottom .price {
    font-size: 24px;
    color: #333;
    margin-left: 20px; }

.lk__leave-comment {
  font-size: 12px; }

.lk-tabs {
  border: 1px solid #e4e6ed; }
  .lk-tabs__item {
    float: left;
    width: 33.33%;
    line-height: 45px;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    transition-duration: .2s; }
    .lk-tabs__item:not(:last-child) {
      border-right: 1px solid #e4e6ed; }
    .lk-tabs__item.active {
      background-color: #f4f4f4; }
    .lk-tabs__item:hover {
      background-color: #00adee;
      color: #fff; }

.lk h1 {
  margin-bottom: 35px;
  margin-top: -10px; }

.lk .about__banner {
  margin-top: 0; }

.lk-info {
  margin-bottom: 37px;
  padding: 20px 38px;
  border: 1px solid #e4e6ed; }
  .lk-info a:hover, .lk-info .about-shops span:hover, .about-shops .lk-info span:hover {
    text-decoration: none;
    color: #333; }
  .lk-info h2 {
    margin-top: 0;
    margin-bottom: 40px; }

.lk-inputs label {
  display: block;
  margin-bottom: 30px;
  line-height: 34px;
  cursor: pointer;
  font-size: 12px; }
  .lk-inputs label input {
    width: 279px;
    margin-right: 12px;
    float: left;
    border-color: #afafaf;
    font-size: 13px; }

.lk__icon {
  display: inline-block;
  width: 40px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  float: left;
  margin-right: -3px;
  position: relative;
  z-index: 10;
  border-radius: 3px 0 0 3px;
  background-color: #afafaf; }
  .lk__icon::before {
    position: relative;
    top: -1px;
    display: inline-block;
    content: '';
    width: 24px;
    height: 24px;
    vertical-align: middle;
    background: url(../img/lk-sprites.png); }

.lk__mail::before {
  background-position: 0 -26px; }

.lk__phone::before {
  background-position: 0 -52px; }

.lk__change {
  float: left;
  margin-right: 10px;
  line-height: 34px;
  display: inline-block;
  color: #00adee;
  transition-duration: .2s; }
  .lk__change:hover {
    color: #333; }

.lk__save {
  float: left;
  line-height: 34px;
  display: none;
  height: 34px; }
  .lk__save:hover {
    color: #333;
    text-decoration: none; }
  .lk__save::before {
    display: inline-block;
    content: url(../img/lk-arrow.png);
    vertical-align: middle;
    position: relative;
    top: -2px;
    margin-right: 8px; }

.lk-passwords {
  display: none;
  margin-top: 30px; }
  .lk-passwords label {
    display: block;
    margin-bottom: 15px;
    line-height: 34px; }
    .lk-passwords label input {
      float: right;
      width: 315px;
      max-width: 100%;
      margin-right: 200px;
      border-color: #afafaf; }
  .lk-passwords .form-button {
    margin-right: 200px;
    width: auto;
    padding-left: 50px;
    padding-right: 50px; }

input:focus ~ .lk__save {
  display: inline-block; }

label:hover .lk__save {
  display: inline-block; }

input:focus ~ .lk__change {
  color: #333; }

a.caption-16, .about-shops span.caption-16 {
  color: #333; }
  a.caption-16:hover, .about-shops span.caption-16:hover {
    color: #00adee;
    text-decoration: none; }

.faq {
  margin-bottom: 20px; }
  .faq h1 {
    float: left;
    margin-right: 45px; }
  .faq .ui-selectmenu-button.ui-button {
    float: left;
    width: 475px; }
  .faq p {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 30px; }
  .faq-wrapper {
    margin-top: 38px;
    padding-bottom: 0;
    margin-bottom: 40px; }
  .faq-question, .faq-answer {
    position: relative;
    padding: 30px 37px;
    border: 1px solid #e6e6e6;
    border-radius: 6px;
    margin-bottom: 24px;
    height: 122px;
    overflow: hidden;
    cursor: pointer; }
    .faq-question::before, .faq-answer::before {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      content: '';
      display: block;
      width: 4.5px;
      background-color: #00adee;
      border-radius: 5px 0 0 5px; }
    .faq-question::after, .faq-answer::after {
      display: block;
      content: '';
      position: absolute;
      width: 100%;
      left: 7px;
      height: 60px;
      top: 62px;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 90%); }
  .faq-answer {
    height: auto;
    margin-left: 36px;
    margin-bottom: 40px;
    cursor: default; }
    .faq-answer::before {
      background-color: #fafafa; }
    .faq-answer::after {
      display: none; }

.active .faq-question, .active .faq-answer {
  height: auto; }
  .active .faq-question::after, .active .faq-answer::after {
    height: 0; }

.where-tabs__item {
  min-width: 200px;
  padding: 0 10px;
  text-align: center; }
  .where-tabs__item.active {
    padding: 0 10px; }

.where-tabs-container {
  padding: 0 20px;
  border: 1px solid #e4e6ed;
  margin-bottom: 30px; }

.where-item {
  position: relative;
  padding: 28px 0 28px 150px; }
  .where-item:not(:last-child) {
    border-bottom: 1px solid #e4e6ed; }
  .where-item__img {
    position: absolute;
    top: 28px;
    left: 0;
    width: 123px; }
  .where-item .caption-16 {
    margin-bottom: 25px; }
  .where-item a, .where-item .about-shops span, .about-shops .where-item span {
    font-weight: bold; }
    .where-item a:hover, .where-item .about-shops span:hover, .about-shops .where-item span:hover {
      text-decoration: none;
      color: #333; }
  .where-item .text {
    font-weight: 200;
    line-height: 21px; }
    .where-item .text:nth-child(2) {
      margin-bottom: 25px; }
  .where-item__list {
    color: #00adee;
    line-height: 21px; }
    .where-item__list p {
      font-weight: 200; }
    .where-item__list span {
      margin-left: 5px;
      margin-right: 5px; }
  .where-item__middle {
    float: left;
    width: 327px;
    padding-right: 40px; }

.map-link {
  display: inline-block;
  padding-left: 23px;
  background: url(../img/map-marker.png) 0 0 no-repeat;
  text-decoration: underline;
  color: #00adee;
  text-decoration-style: dashed;
  line-height: 22px; }
  .map-link span {
    color: #333;
    cursor: pointer; }

#where-map {
  padding-top: 28px;
  padding-bottom: 28px;
  height: 550px; }

.where .ui-selectmenu-button.ui-button {
  display: none; }

.about .catalog-nav ul {
  border: none; }

.about .catalog-nav a, .about .catalog-nav .about-shops span, .about-shops .about .catalog-nav span {
  padding: 0; }

.about .catalog-nav .caption-16 .switcher {
  height: 46px;
  line-height: 46px;
  margin-top: -23px; }

.about .ui-selectmenu-button.ui-button {
  display: none; }

.about-shops .caption {
  margin-bottom: 25px;
  font-size: 22px; }

.about-shops a, .about-shops span {
  display: inline-block;
  width: 100%;
  padding: 13px 18px;
  color: #333;
  font-size: 16px; }
  .about-shops a:hover, .about-shops span:hover {
    text-decoration: none;
    color: #00adee; }

.about-shops span {
  background-color: #fafafa; }
  .about-shops span:hover {
    color: #333; }

.about-shops ul {
  border: 1px solid #e4e6ed; }

.about-shops li:not(:last-child), .about-shops .support-wrapper p:not(:last-child), .support-wrapper .about-shops p:not(:last-child) {
  border-bottom: 1px solid #e4e6ed; }

.about .home-advantages {
  padding-top: 25px;
  margin-bottom: 0;
  padding-bottom: 0;
  background: #fff; }
  .about .home-advantages .caption {
    margin-bottom: 35px;
    font-weight: normal;
    font-size: 25px; }
  .about .home-advantages__item {
    width: 33.33%;
    margin-bottom: 41px; }
    .about .home-advantages__item:nth-child(4) {
      margin-left: 16.66%; }

.about .home__icon {
  border-radius: 50%;
  box-shadow: 0 1px 1px 2px rgba(0, 0, 0, 0.1); }

.about__banner {
  margin-bottom: 15px;
  margin-top: 13px; }

.content {
  margin-bottom: 30px; }
  .content h2 {
    font-weight: normal;
    font-size: 25px; }
  .content p {
    font-weight: 200;
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 15px; }
    .content p:last-child {
      margin-bottom: 0; }

.about-partner {
  margin-top: 57px;
  margin-bottom: 20px;
  line-height: 111px; }
  .about-partner .caption {
    display: inline-block;
    vertical-align: middle;
    padding-left: 20px;
    font-weight: normal;
    font-size: 25px; }

.support h1 {
  margin-bottom: 35px; }

.support h2 {
  font-size: 22px; }

.support-wrapper {
  padding-left: 70px; }
  .support-wrapper ol {
    padding: 0;
    list-style: none;
    counter-reset: support-count; }
    .support-wrapper ol > li::before, .support-wrapper ol > p::before {
      position: absolute;
      content: counter(support-count);
      counter-increment: support-count;
      font-weight: bold;
      width: 57px;
      height: 57px;
      top: -15px;
      left: -77px;
      text-align: center;
      line-height: 57px;
      color: #00adee; }
  .support-wrapper li, .support-wrapper p {
    position: relative;
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 30px;
    font-weight: 200; }
    .support-wrapper li p, .support-wrapper p p {
      margin-bottom: 10px; }

@media screen and (max-width: 1199px) {
  .lk-message__th:nth-child(1) {
    width: 109px; }
  .lk-message__th:nth-child(2) {
    width: 480px; }
  .lk-message__th:nth-child(3) {
    width: 95px; }
  .lk-message__td:nth-child(1) {
    width: 109px;
    padding-left: 0;
    padding-right: 0; }
  .lk-message__td:nth-child(2) {
    width: 480px; }
  .lk-message__td:nth-child(3) {
    width: 95px;
    padding-left: 0;
    padding-right: 0; }
  .lk-favorites {
    margin-left: 0; }
    .lk-favorites .card-mini {
      margin-left: 0;
      margin-right: -1px;
      width: calc(33.33% + 1px); }
    .lk-favorites .add-favorites span {
      right: 32px; }
  .lk-orders-item {
    padding-right: 7px;
    padding-left: 30px; }
    .lk-orders-item .caption-16 {
      width: 349px;
      margin-bottom: 50px; }
    .lk-orders-item .lk__leave-comment {
      right: auto;
      left: 309px;
      top: 95px; }
  .lk h1 {
    margin-bottom: 30px; }
  .lk .col-lg-3 {
    float: left;
    width: 312px; }
  .lk .col-lg-9 {
    width: 712px;
    float: left; }
  .lk .publications-nav ul {
    display: block; }
  .lk .publications-nav li, .lk .publications-nav .support-wrapper p, .support-wrapper .lk .publications-nav p {
    width: 100%; }
    .lk .publications-nav li:not(:last-child), .lk .publications-nav .support-wrapper p:not(:last-child), .support-wrapper .lk .publications-nav p:not(:last-child) {
      border-right: none;
      border-bottom: 1px solid #e4e6ed; }
  .lk-passwords label input {
    margin-right: 45px; }
  .lk-passwords .form-button {
    margin-right: 45px; }
  .where .col-lg-3 {
    float: left;
    width: 312px; }
  .where .col-lg-9 {
    width: 712px;
    float: left; }
  .where-item__middle {
    width: 310px; }
  .about .catalog-nav {
    display: block; }
  .about .col-lg-3 {
    float: left;
    width: 312px; }
  .about .col-lg-9 {
    width: 712px;
    float: left; }
  .about .home__icon {
    width: 60px;
    height: 60px;
    margin-top: -5px;
    margin-bottom: 15px;
    background-size: 30px auto; }
  .about .home__time {
    background-position: 16px 13px; }
  .about .home__car {
    position: relative;
    top: 5px; }
  .about .home-advantages {
    display: block; } }

@media screen and (max-width: 1023px) {
  .about-shops > .caption-16 {
    display: block; }
  .about .catalog-nav {
    display: none; }
  .lk-message__nav {
    margin-top: 0; }
  .lk-message__th:nth-child(1) {
    width: 92px; }
  .lk-message__th:nth-child(2) {
    width: 280px; }
  .lk-message__th:nth-child(3) {
    width: 77px; }
  .lk-message__td:nth-child(1) {
    width: 92px;
    font-size: 12px; }
  .lk-message__td:nth-child(2) {
    width: 280px; }
  .lk-message__td:nth-child(3) {
    width: 77px;
    font-size: 12px; }
  .lk-favorites .card-mini {
    width: calc(50% + 1px);
    margin-bottom: 24px; }
    .lk-favorites .card-mini:last-child:nth-child(2n-1) {
      margin-left: calc(50%/2); }
  .lk-orders-item {
    padding: 30px 25px; }
    .lk-orders-item__bottom {
      position: static; }
    .lk-orders-item__color {
      float: none;
      margin-bottom: 20px; }
    .lk-orders-item .lk__leave-comment {
      top: auto;
      bottom: 30px;
      left: 165px; }
    .lk-orders-item .img-bg {
      width: 120px;
      height: 90px;
      margin-right: 20px;
      margin-bottom: 100px; }
    .lk-orders-item .caption-16 {
      margin-top: -6px;
      width: 228px;
      font-size: 14px; }
      .lk-orders-item .caption-16 span {
        font-size: 18px; }
    .lk-orders-item .cart-card-actions {
      position: static;
      width: auto; }
      .lk-orders-item .cart-card-actions .stars i:last-of-type {
        margin-right: 0px; }
  input:focus ~ .lk__save {
    display: none; }
  label:hover .lk__save {
    display: none; }
  .lk-passwords label {
    padding-right: 10px;
    margin-bottom: 5px; }
    .lk-passwords label input {
      width: 100%;
      margin-right: 0;
      float: none; }
  .lk-passwords .form-button {
    margin-right: 11px;
    margin-top: 20px; }
  .lk h1 {
    text-align: center;
    margin-bottom: 20px; }
  .lk .col-lg-3 {
    width: 277px;
    margin-top: 148px; }
  .lk .col-lg-9 {
    width: 476px; }
  .lk .about__banner {
    margin-left: -277px;
    max-width: 723px;
    margin-bottom: 23px; }
  .lk-info {
    padding-right: 10px; }
  .lk__change {
    margin-right: 0; }
  .faq h1 {
    margin-right: 0;
    text-align: center;
    float: none;
    margin-top: -15px;
    margin-bottom: 10px; }
  .faq .ui-selectmenu-button.ui-button {
    float: none;
    display: block;
    margin-left: auto;
    margin-right: auto; }
  .faq p {
    font-size: 16px;
    line-height: 24px; }
  .faq-answer {
    height: 122px; }
    .faq-answer::after {
      display: block; }
  .where .ui-selectmenu-button.ui-button {
    width: 477px;
    display: inline-block;
    float: right; }
  .where .col-lg-9, .where .col-lg-3 {
    width: 100%; }
  .about .col-lg-9, .about .col-lg-3 {
    width: 100%; }
  .about h1 {
    text-align: center; }
  .about .ui-selectmenu-button.ui-button {
    width: 477px;
    display: inline-block;
    float: right; }
  .about-shops ul {
    display: none; }
  .about-shops .caption {
    float: left; } }

@media screen and (max-width: 767px) {
  .lk-message__th:nth-child(1) {
    width: 70px;
    flex-shrink: 0; }
  .lk-message__th:nth-child(3) {
    width: 70px;
    flex-shrink: 0; }
  .lk-message__td {
    padding-top: 10px;
    padding-bottom: 10px; }
    .lk-message__td p {
      font-size: 13px;
      line-height: 18px; }
    .lk-message__td:nth-child(1) {
      width: 70px;
      flex-shrink: 0; }
    .lk-message__td:nth-child(2) {
      padding-left: 10px;
      padding-right: 10px; }
    .lk-message__td:nth-child(3) {
      width: 70px;
      flex-shrink: 0; }
  .lk-favorites {
    margin-top: 0; }
  .lk h1 {
    margin-top: -15px;
    margin-bottom: 10px; }
  .lk h2 {
    margin-bottom: 30px;
    font-size: 22px; }
  .lk .col-lg-3 {
    width: 100%;
    margin-top: 0; }
  .lk .col-lg-9 {
    width: 100%; }
  .lk .about__banner {
    margin-bottom: 20px;
    display: none;
    margin-top: 0;
    max-width: 100%;
    margin-left: 0; }
  .lk-info {
    margin-bottom: 30px; }
  .lk-inputs label input {
    width: 190px;
    margin-right: 10px; }
  .faq .ui-selectmenu-button.ui-button {
    width: 267px; }
  .faq-wrapper {
    margin-top: 30px; }
  .faq-question, .faq-answer {
    padding: 13px 23px; }
  .where-item__middle {
    padding-left: 0;
    max-width: 100%;
    margin-bottom: 15px; }
  .content p {
    font-size: 16px;
    line-height: 24px; }
  .support h1 {
    text-align: center; }
  .support h2 {
    font-size: 16px; }
  .support-wrapper li, .support-wrapper p, .support-wrapper p {
    font-size: 16px;
    line-height: 24px; }
  .about-partner {
    text-align: center;
    margin-top: 0; }
    .about-partner .caption {
      display: block;
      margin-top: 17px;
      text-align: left;
      padding-left: 0; }
  .where .ui-selectmenu-button.ui-button {
    width: 263px; }
  .where h1 {
    margin-top: -10px;
    text-align: center; }
  .where .caption {
    font-size: 18px; }
  .about .caption {
    font-size: 18px; }
  .about .ui-selectmenu-button.ui-button {
    width: 263px; }
  .about .home-advantages__item:nth-child(n) {
    margin-left: 0;
    width: 50%;
    font-size: 14px;
    margin-bottom: 25px; }
    .about .home-advantages__item:nth-child(n) .home__icon {
      margin-right: 15px; } }

@media screen and (max-width: 479px) {
  .lk-favorites {
    margin-top: 10px; }
    .lk-favorites .card-mini {
      width: 100%; }
      .lk-favorites .card-mini:last-child:nth-child(2n-1) {
        margin-left: 0; }
  .lk-tabs__item {
    width: 100%; }
    .lk-tabs__item:not(:last-child) {
      border-right: none;
      border-bottom: 1px solid #e4e6ed; }
  .lk-orders-item {
    padding: 15px; }
    .lk-orders-item .img-bg {
      float: none;
      margin-bottom: 10px;
      margin-left: auto;
      margin-right: auto; }
    .lk-orders-item .caption-16 {
      width: 100%;
      margin-bottom: 10px; }
    .lk-orders-item .cart-card-actions {
      margin-bottom: 10px; }
    .lk-orders-item__color {
      margin-bottom: 10px; }
    .lk-orders-item .lk__leave-comment {
      bottom: 58px;
      left: auto;
      right: 15px; }
  .lk h1 {
    margin-top: 0; }
  .lk h2 {
    font-size: 16px; }
  .lk-inputs label input {
    width: 190px;
    margin-right: 10px; }
  .lk__change {
    margin-right: 10px; }
  .lk__save {
    display: inline-block; }
  input:focus ~ .lk__save {
    display: inline-block; }
  label:hover .lk__save {
    display: inline-block; }
  .faq h1 {
    margin-top: 0; }
  .where h1 {
    margin-top: 0;
    margin-bottom: 10px; }
  .where-item {
    padding-left: 10px;
    padding-right: 10px; }
  .where-item__img {
    position: static;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 15px; }
  .where .ui-selectmenu-button.ui-button {
    width: 155px;
    float: none;
    margin-bottom: 15px;
    margin-right: auto;
    margin-left: auto;
    display: block; }
  .where .about-shops .caption {
    float: none;
    text-align: center; }
  .support-wrapper ol li::before, .support-wrapper ol p::before {
    left: -49px; }
  .support h1 {
    margin-bottom: 5px; }
  .support-wrapper {
    padding-left: 35px; }
  .about .caption {
    font-size: 16px; }
  .about .ui-selectmenu-button.ui-button {
    width: 155px; }
  .about .home-advantages__item:nth-child(n) {
    width: 100%; } }
