.popup {
  &-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    z-index: 100;
    text-align: center;
    line-height: 100vh; }
  &-container {
    vertical-align: middle;
    line-height: 1.5;
    display: inline-block;
    position: relative;
    //position: absolute
    //top: 50%
    //left: 50%
    width: 320px;
    max-height: 100%;
    overflow: auto;
    text-align: left;
    will-change: transform;
    padding: 36px 20px 27px;
    border-radius: 5px;
    border: 1px solid $black;
    background-color: #fff; }
  &__close {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 35px;
    background: url(../img/close.png) center no-repeat;
    cursor: pointer; }
  &__caption {
    margin-bottom: 12px;
    font-size: 20px;
    text-align: center;
    letter-spacing: 0; }
  &__form {
    .text-right {
      position: relative;
      z-index: 10;
      margin-bottom: -15px; }
    label {
      display: block;
      margin-bottom: 20px;
      font-size: 16px;
      letter-spacing: 0; }
    .checkbox {
      font-size: 13px; }
    textarea {
      width: 100%;
      height: 55px;
      resize: none;
      border-radius: 4px;
      font-size: 13px;
      line-height: 18px;
      padding: 7px 13px; } } }

.invalid {
  border-color: $red!important;
  &-text {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    font-size: 12px;
    color: $red;
    letter-spacing: 0; }
  &+.invalid-text {
    display: block; } }

#callback {
  display: none; }

#login {
  display: none; }

.capcha {
  line-height: 57px;
  text-align: right;
  margin-bottom: 15px;
  &__img {
    float: left; }
  &__refresh {
    font-size: 16px;
    color: $turquoise;
    cursor: pointer;
    display: block;
    user-select: none;
    text-decoration: underline;
    &:hover {
      text-decoration: none; }
    &::before {
      display: inline-block;
      content: '';
      vertical-align: middle;
      position: relative;
      top: -2px;
      margin-right: 10px;
      background: url(../img/capcha.png) center no-repeat;
      width: 18px;
      height: 18px; } } }
