.header {
  margin-bottom: 20px;
  position: relative;
  z-index: 20;
  &-top {
    padding-top: 7px;
    padding-bottom: 7px;
    border-bottom: 1px solid $light-gray;
    background-color: $bg-light-gray;
    &__links {
      width: 570px;
      display: inline-flex;
      justify-content: space-between;
      li {
        padding-left: 12px;
        padding-right: 12px; }
      a {
        color: $gray;
        text-decoration: underline;
        &:hover {
          text-decoration: none; } } }
    a {
      font-size: 12px; }
    .flex {
      justify-content: space-between;
      align-items: center; }
    .list-inline {
      margin: 0; } }
  &__mail {
    width: 270px;
    color: $turquoise;
    text-decoration: underline;
    text-decoration-style: dashed;
    line-height: 20px;
    &:hover {
      color: $turquoise;
      text-decoration: none; }
    .fa {
      margin-right: 10px;
      font-size: 20px;
      vertical-align: middle;
      position: relative;
      top: -2px; } }
  &__socials {
    width: 270px;
    text-align: right;
    a {
      i {
        transition-duration: .2s;
        display: inline-block;
        width: 25px;
        height: 25px;
        border-radius: 5px;
        line-height: 25px;
        text-align: center;
        color: $gray;
        font-size: 15px;
        &:hover {
          color: #fff; } } } }
  &__vk {
    &:hover {
      background-color: $vk-bg; }
    &::before {
      display: inline-block;
      content: 'B';
      font-family: Arial;
      font-weight: bold;
      font-style: normal;
      font-size: 15px; } }
  &__facebook {
    &:hover {
      background-color: $facebook-bg; }
    &::before {
      display: inline-block;
      content: 'f';
      font-family: Arial;
      font-style: normal; } }
  &__youtube-play {
    &:hover {
      background-color: red; } } }

.header {
  &-middle {
    padding-top: 28px;
    &__links {
      margin: 0 30px;
      li {
        padding-left: 12px;
        padding-right: 12px;
        a {
          display: block;
          color: $black;
          letter-spacing: .02em;
          transition-duration: 0s;
          &:hover {
            color: $turquoise;
            text-decoration: none; } } } } }
  &__logo {
    width: 272px;
    height: 43px;
    margin-left: -2px;
    background: url(../pictures/header-logo.jpg) center no-repeat; }
  &__icon {
    width: 22px;
    height: 25px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    background: url(../img/header-sprites.png) 0 0; }
  &__call-back {
    position: relative;
    top: -7px;
    padding-left: 70px;
    background: url(../img/header-phone.png) 35px 10px no-repeat;
    cursor: pointer;
    max-width: 257px;
    &:hover {
      .text {
        border-bottom-color: transparent; } }
    .text {
      display: inline-block;
      position: relative;
      transition-duration: .2s;
      top: -5px;
      border-bottom: 1px dashed $turquoise; } }
  &__phone {
    white-space: nowrap;
    font-size: 24px;
    font-weight: bold; } }

.header {
  &__about {
    .header__icon {
      background-position: 0 0;
      height: 23px; } }
  &__about:hover {
    .header__icon {
      background-position: 0 -30px; } }
  &__sales {
    .header__icon {
      background-position: -31px 0; } }
  &__sales:hover {
    .header__icon {
      background-position: -31px -29px; } }
  &__buy {
    .header__icon {
      background-position: -62px 0; } }
  &__buy:hover {
    .header__icon {
      background-position: -62px -28px; } }
  &__publications {
    .header__icon {
      background-position: -93px 0; } }
  &__publications:hover {
    .header__icon {
      background-position: -94px -28px; } }
  &__news {
    .header__icon {
      background-position: -124px 0;
      width: 25px;
      height: 24px; } }
  &__news:hover {
    .header__icon {
      background-position: -124px -30px; } }
  &__contacts {
    .header__icon {
      background-position: -157px 0;
      width: 24px; } }
  &__contacts:hover {
    .header__icon {
      background-position: -158px -28px; } } }

.header {
  &-bottom {
    padding-top: 18px; }
  &-catalog {
    margin-right: 31px;
    position: relative;
    float: left;
    &__label {
      z-index: 60;
      position: relative;
      width: 268px;
      height: 40px;
      line-height: 40px;
      padding-left: 28px;
      background-color: $turquoise;
      color: #fff;
      font-weight: bold;
      font-size: 18px;
      border-radius: 4px;
      cursor: pointer;
      user-select: none;
      a:hover {
        text-decoration: none;
        color: #fff; }
      .triangle {
        position: absolute;
        top: 15px;
        right: 33px;
        z-index: 10; } } } }

.active {
  .triangle {
    transform: scale(1,-1); } }

.triangle {
  display: block;
  transition-duration: .2s;
  width: 0;
  height: 0;
  border-top: 12px solid #fff;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent; }

.active+.header-nav {
  left: -1px; }

.header-nav {
  width: 270px;
  position: absolute;
  top: -1px;
  left: -11111px;
  z-index: 50;
  background-color: #fff;
  box-shadow: 0 0 7px 0 rgba(0,0,0,0.2);
  >ul {
    position: relative;
    margin: 0;
    z-index: 60;
    width: 270px;
    padding-top: 41px;
    float: left;
    box-shadow: 3px 0 3px rgba(0,0,0,0.2);
    &>li {
      &:not(:last-child) {
        border-bottom: 1px solid $light-gray; }
      &>a {
        padding-left: 23px;
        position: relative;
        display: block;
        height: 44px;
        line-height: 44px;
        color: $black;
        background-color: #fff;
        z-index: 60;
        &::after {
          position: absolute;
          width: 3px;
          height: calc(100% + 1px);
          top: 0;
          right: -3px;
          content: '';
          display: block;
          background: linear-gradient(to right, rgba(0,0,0,.2), transparent); }
        &:hover {
          text-decoration: none;
          background-color: $bg-light-gray; }
        .fa {
          font-size: 20px;
          height: 100%;
          position: absolute;
          right: 0;
          top: 0;
          line-height: 44px;
          width: 44px;
          text-align: center; } } } } }

li:hover .header-category {
  left: 100%;
  opacity: 1; }


.header-category {
  position: absolute;
  top: 0;
  left: -9999px;
  width: 600px;
  min-height: 100%;
  background-color: #fff;
  box-shadow: 0 0 7px 0 rgba(0,0,0,0.2);
  z-index: -10;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  opacity: 0;
  &__item {
    display: inline-block;
    width: 147px;
    margin-bottom: 10px;
    float: left;
    margin-left: 23px;
    margin-right: 30px;
    padding-top: 38px;
    .caption {
      margin-bottom: 12px;
      min-height: 40px;
      font-size: 14px;
      line-height: 18px; }
    li {
      height: 40px;
      line-height: 40px;
      &:last-child {
        border-bottom: 1px solid $light-gray; }
      a {
        display: inline-block;
        vertical-align: top;
        line-height: 18px;
        color: $black; } }

    a:hover {
      text-decoration: none;
      color: $turquoise; } } }

.caption {
  display: block;
  font-weight: bold;
  color: $black; }

.header__search {
  width: 570px;
  float: left;
  position: relative;
  margin-right: 32px;
  input {
    height: 40px;
    border: 2px solid $turquoise;
    padding-left: 22px;
    padding-right: 100px; }
  button {
    position: absolute;
    right: 2px;
    top: 0;
    height: 40px;
    width: 98px;
    background: url(../img/search.png) center no-repeat;
    background-color: $turquoise;
    border: none; } }

.header {
  &-account {
    float: left;
    width: 148px;
    padding-left: 44px;
    background-color: $turquoise;
    color: #fff;
    border-radius: 4px;
    padding-top: 4px;
    position: relative;
    height: 40px;
    user-select: none;
    cursor: pointer;
    .caption {
      color: #fff;
      font-size: 16px;
      line-height: 1;
      width: auto;
      border-bottom: 1px solid #fff;
      display: inline-block; }
    .text {
      font-size: 12px;
      line-height: 1; }
    .user {
      position: absolute;
      top: 7px;
      left: 10px; } } }

.active .header-login {
  display: block; }

.user {
  width: 23px;
  height: 23px;
  display: inline-block;
  background: url(../img/header-sprites2.png) 0 0 no-repeat; }

.header-login {
  position: absolute;
  top: 47px;
  width: 220px;
  padding: 20px;
  left: 0;
  z-index: 10;
  background-color: #fff;
  box-shadow:  0 0 7px 0 rgba(0,0,0,.2);
  display: none;
  &__button {
    display: inline-block;
    text-align: center;
    line-height: 38px;
    width: 100%;
    margin-bottom: 14px;
    height: 40px;
    border: 1px solid $turquoise;
    color: $turquoise;
    background-color: #fff;
    font-size: 16px;
    font-weight: bold;
    border-radius: 3px;
    transition-duration: .2s;
    a {
      display: inline-block;
      width: 100%;
      text-align: center;
      line-height: 35px;
      vertical-align: top; }
    &:hover {
      background-color: $turquoise;
      text-decoration: none;
      color: #fff;
      a {
        color: #fff;
        text-decoration: none; } } }
  .text {
    color: $gray;
    margin-bottom: 4px; }
  &__link {
    display: inline-block;
    text-align: center;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    border: 1px solid $turquoise;
    background-color: $turquoise;
    height: 40px;
    line-height: 38px;
    width: 100%;
    border-radius: 3px;
    &:hover {
      background-color: #fff;
      color: $turquoise;
      text-decoration: none; } } }

.header {
  &-actions {
    margin-left: 20px;
    float: left;
    a {
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 40px;
      border: 1px solid $turquoise;
      border-radius: 3px;
      &:first-child {
        margin-right: 12px; }
      .count {
        display: inline-block;
        height: 22px;
        position: absolute;
        top: -7px;
        right: -7px;
        line-height: 23px;
        color: #fff;
        background-color: $green;
        border-radius: 11px;
        padding-left: 7px;
        padding-right: 7px; } } } }

.ham {
  height: 40px;
  width: 40px;
  top: -2px;
  border-radius: 3px;
  background-color: $turquoise;
  line-height: 7px;
  text-align: center;
  position: relative;
  &__bar {
    position: absolute;
    left: 9px;
    display: inline-block;
    width: 22px;
    height: 2px;
    background-color: #fff;
    transition-duration: .2s;
    &:first-child {
      top: 12px; }
    &:nth-child(2) {
      top: 19px; }
    &:nth-child(3) {
      top: 26px; } } }

.active {
  .ham__bar {
    &:first-child {
      transform: rotate(45deg) translate(6px,4px);
      width: 20px; }
    &:nth-child(2) {
      transform: scale(0,1); }
    &:nth-child(3) {
      transform: rotate(-45deg) translate(6px,-4px);
      width: 20px; } } }

.header-button {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding-left: 28px;
  padding-right: 28px;
  border-radius: 4px;
  color: #fff;
  background-color: $green;
  &:hover {
    text-decoration: none;
    color: #fff; } }

.heart {
  width: 30px;
  height: 30px;
  display: inline-block;
  vertical-align: middle;
  background: url(../img/header-sprites2.png) -28px 2px no-repeat; }

.cart {
  @extend .heart;
  background-position: -61px 2px; }

.header-account+.header-account {
  display: none!important; }


@media all and (max-width: 1199px) {
  .header-actions {
    margin-left: 17px; }
  .header {
    &__mail {
      width: 179px; }
    &-middle {
      .flex {
        align-items: center; }
      &__links {
        margin-left: 5px;
        margin-right: 5px;
        li {
          padding-left: 5px;
          padding-right: 5px; } } } }
  .header__call-back {
    padding-left: 35px;
    background-position: 0 9px;
    position: static; }
  .header__phone {
    font-size: 24px; }
  .header__logo {
    background-position: center;
    background-size: contain; }
  .header__search {
    width: 401px; } }

@media all and (max-width: 1024px) {
  .header {
    &__toggle-search {
      display: inline-block;
      float: left;
      width: 40px;
      height: 40px;
      margin-right: 12px;
      background: url(../img/search.png) center no-repeat;
      background-color: $turquoise;
      border-radius: 3px; }
    &-top {
      display: none; }
    &-middle {
      padding-top: 23px;
      padding-bottom: 13px;
      .overton-sm {
        width: 205px;
        position: relative;
        z-index: 10; }
      &__links {
        display: none; } } }
  .header__call-back {
    display: none; }
  .header-middle .flex {
    justify-content: space-between; }
  .header-account {
    display: none; }
  .header-actions_sm {
    .header-account {
      display: inline-block;
      width: 40px;
      padding-left: 40px;
      height: 40px; } }
  .header-login {
    left: auto;
    right: 0; }
  .header-account .user {
    left: 9px; }
  .header-actions {
    margin-left: 12px; }
  .header-bottom {
    .header-actions {
      display: none; } }
  .header-catalog {
    display: none; }
  .header__search {
    width: 100%;
    display: none; }
  .header-nav > ul > li > a::after {
    display: none; }
  .header-menu_sm {
    width: 272px;
    position: absolute;
    top: 53px;
    left: -15px; }
  .mobile-cart {
    position: relative;
    padding: 16px 8px 22px 65px;
    background-color: $bg-light-gray;
    line-height: 18px;
    text-align: left;
    box-shadow:  -1px 0 0 0 rgba(51,51,51,0.1) inset, -2px 0 0 0 rgba(51,51,51,0.09) inset,-3px 0 0 0 rgba(51,51,51,0.08) inset,-4px 0 0 0 rgba(51,51,51,0.07) inset,-5px 0 0 0 rgba(51,51,51,0.06) inset,-6px 0 0 0 rgba(51,51,51,0.05) inset,-7px 0 0 0 rgba(51,51,51,0.04) inset,-8px 0 0 0 rgba(51,51,51,0.03) inset,-9px 0 0 0 rgba(51,51,51,0.02) inset,-10px 0 0 0 rgba(51,51,51,0.01) inset;
    &__row {
      span {
        white-space: nowrap; }
      span:first-child {
        width: 100px;
        display: inline-block; } }
    .green {
      font-weight: bold; }
    .cart-green {
      position: absolute;
      top: 20px;
      left: 18px; }
    .header-button {
      margin-top: 18px; } }
  .mobile__catalog-switcher {
    height: 41px;
    padding-left: 23px;
    line-height: 40px;
    border-top: 1px solid #fff;
    background-color: $turquoise;
    color: #fff;
    font-weight: bold;
    box-shadow:  -1px 0 0 0 rgba(51,51,51,0.1) inset, -2px 0 0 0 rgba(51,51,51,0.09) inset,-3px 0 0 0 rgba(51,51,51,0.08) inset,-4px 0 0 0 rgba(51,51,51,0.07) inset,-5px 0 0 0 rgba(51,51,51,0.06) inset,-6px 0 0 0 rgba(51,51,51,0.05) inset,-7px 0 0 0 rgba(51,51,51,0.04) inset,-8px 0 0 0 rgba(51,51,51,0.03) inset,-9px 0 0 0 rgba(51,51,51,0.02) inset,-10px 0 0 0 rgba(51,51,51,0.01) inset;
    &.active {
      &::before {
        display: inline-block;
        content: '';
        margin-right: 11px;
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-right: 7px solid #fff; } } }
  .active+.header-menu_sm {
    transform: scale(1,1);
    transform-origin: 0 0; }
  .header-menu_sm {
    overflow: auto;
    position: absolute;
    transform: scale(0,1);
    transform-origin: 0 0;
    transition-duration: .2s;
    .header-nav {
      display: block;
      position: static;
      border-right: none;
      width: 100%;
      &>ul {
        box-shadow: none;
        width: 100%; }
      &>ul>li {
        box-shadow:  -1px 0 0 0 rgba(51,51,51,0.1) inset, -2px 0 0 0 rgba(51,51,51,0.09) inset,-3px 0 0 0 rgba(51,51,51,0.08) inset,-4px 0 0 0 rgba(51,51,51,0.07) inset,-5px 0 0 0 rgba(51,51,51,0.06) inset,-6px 0 0 0 rgba(51,51,51,0.05) inset,-7px 0 0 0 rgba(51,51,51,0.04) inset,-8px 0 0 0 rgba(51,51,51,0.03) inset,-9px 0 0 0 rgba(51,51,51,0.02) inset,-10px 0 0 0 rgba(51,51,51,0.01) inset;
        border-bottom: none;
        &>a {
          background: transparent;
          border-bottom: 1px solid rgba(55, 55, 55,.2);
          transition-duration: 0s;
          &.active {
            background-color: $bg-light-gray;
            box-shadow:  -1px 0 0 0 rgba(51,51,51,0.1) inset, -2px 0 0 0 rgba(51,51,51,0.09) inset,-3px 0 0 0 rgba(51,51,51,0.08) inset,-4px 0 0 0 rgba(51,51,51,0.07) inset,-5px 0 0 0 rgba(51,51,51,0.06) inset,-6px 0 0 0 rgba(51,51,51,0.05) inset,-7px 0 0 0 rgba(51,51,51,0.04) inset,-8px 0 0 0 rgba(51,51,51,0.03) inset,-9px 0 0 0 rgba(51,51,51,0.02) inset,-10px 0 0 0 rgba(51,51,51,0.01) inset;
            padding-left: 0;
            text-align: center;
            .fa {
              display: none; }
            &::before {
              display: inline-block;
              content: '\f104';
              font-family: FontAwesome;
              width: 44px;
              font-size: 20px; } } } }
      .header-category {
        position: relative;
        display: none;
        left: 0;
        width: 100%;
        opacity: 1;
        box-shadow:  -1px 0 0 0 rgba(51,51,51,0.1) inset, -2px 0 0 0 rgba(51,51,51,0.09) inset,-3px 0 0 0 rgba(51,51,51,0.08) inset,-4px 0 0 0 rgba(51,51,51,0.07) inset,-5px 0 0 0 rgba(51,51,51,0.06) inset,-6px 0 0 0 rgba(51,51,51,0.05) inset,-7px 0 0 0 rgba(51,51,51,0.04) inset,-8px 0 0 0 rgba(51,51,51,0.03) inset,-9px 0 0 0 rgba(51,51,51,0.02) inset,-10px 0 0 0 rgba(51,51,51,0.01) inset;
        z-index: 10;
        &__item {
          padding-top: 10px;
          width: 202px; } }
      &::after {
        display: table;
        clear: both;
        content: ''; } }
    .header__socials {
      padding-left: 12px;
      background-color: #313131;
      width: 100%;
      padding-top: 10px;
      padding-bottom: 18px;
      margin: 0;
      text-align: left;
      a {
        display: inline-block;
        background-color: $turquoise;
        width: 26px;
        height: 26px;
        border-radius: 4px;
        i {
          color: #fff; } } }
    .header__call-back {
      display: block;
      padding-top: 17px;
      padding-left: 51px;
      background-image: url(../img/phone-sm.png);
      background-position: 17px 25px;
      background-color: #313131;
      color: #fff;
      max-width: none; }
    .header-middle__links {
      display: block;
      background-color: $turquoise;
      margin: 0;
      box-shadow:  -1px 0 0 0 rgba(51,51,51,0.1) inset, -2px 0 0 0 rgba(51,51,51,0.09) inset,-3px 0 0 0 rgba(51,51,51,0.08) inset,-4px 0 0 0 rgba(51,51,51,0.07) inset,-5px 0 0 0 rgba(51,51,51,0.06) inset,-6px 0 0 0 rgba(51,51,51,0.05) inset,-7px 0 0 0 rgba(51,51,51,0.04) inset,-8px 0 0 0 rgba(51,51,51,0.03) inset,-9px 0 0 0 rgba(51,51,51,0.02) inset,-10px 0 0 0 rgba(51,51,51,0.01) inset;

      li {
        display: block;
        height: 37px;
        line-height: 37px;

        .header__icon {
          display: inline-block;
          margin-right: 13px;
          width: 25px;
          vertical-align: middle;
          background: url(../img/sm-sprites-header.png) 0 0 no-repeat; }
        a {
          color: #fff;
          border-bottom: 1px solid #5fbde1; }
        .header__sales {
          .header__icon {
            background-position: 0 -41px; } }
        .header__buy {
          .header__icon {
            background-position: 0 -80px; } }
        .header__publications {
          .header__icon {
            background-position: 0 -118px; } }
        .header__news {
          .header__icon {
            background-position: 0 -196px; } }
        .header__contacts {
          .header__icon {
            background-position: 0 -235px; } } } } }
  .header-nav > ul {
    padding-top: 0; } }


@media  all and (max-width: 1023px) {

  .header-middle .overton-sm:first-child {
    width: 42px; }
  .header-category {
    width: 410px; } }


@media all and (max-width: 767px) {
  .header-bottom {
    padding: 0; }
  .header-account>.text {
    font-size: 0; }
  .header__logo {
    width: 125px;
    position: relative;
    left: 25px; }
  .header-actions {
    margin-left: 18px; }
  .header-actions a:first-child {
    margin-right: 18px; }
  .header__toggle-search {
    position: absolute;
    top: -1px;
    left: -230px; }
  .header-middle .overton-sm {
    width: 160px; }
  .header-category {
    width: 188px;
    max-height: 100%;
    overflow: auto; } }


@media all  and (max-width: 1024px) {
  .header-menu_sm .header-nav .header-category {
    position: static;
    left: 100%; }
  .header-menu_sm {
    overflow-x: visible;
    overflow-y: auto;
    max-height: calc(100vh - 79px); } }


@media all and (max-width: 479px) {
  .header-menu_sm {
    max-height: calc(100vh - 109px); }
  .header-middle {
    .flex {
      position: relative;
      left: 10px; } }
  .header {
    &-middle {
      padding-top: 55px; }
    &__logo {
      position: absolute;
      top: -36px;
      left: 50%;
      width: 126px;
      margin-left: -63px;
      height: 20px; } }
  .header-account .caption {
    font-size: 0; }
  .header__toggle-search {
    position: static;
    margin-right: 19px; }
  .overton-sm.overton-sm_last {
    width: 230px; }
  .ham {
    top: -1px; }
  .header-actions a.header__favorites .count {
    background-color: #ff765b; }
  .header-actions a.header__cart .count {
    background-color: $red; }
  .header-login {
    right: -88px; } }
