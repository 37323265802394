.article-aside {
  .caption {
    margin-bottom: 30px;
    font-size: 22px; }
  &-products {
    margin-bottom: 38px; } }
.article-posts {
  &__link {
    display: block;
    margin-bottom: 27px;
    border: $border-metal;
    &:hover {
      text-decoration: none; }
    .img-bg {
      height: 200px;
      background-size: cover;
      border-bottom: $border-metal; }
    .text {
      display: block;
      margin-bottom: 5px;
      padding: 15px;
      font-weight: bold;
      font-size: 16px;
      color: $black;
      transition-duration: .2s;
      &:hover {
        color: $turquoise; } } } }

.license {
  h1 {
    margin-bottom: 40px; }
  &-wrapper {
    margin-left: -30px;
    .sales-item {
      .caption {
        height: 40px;
        font-size: 16px;
        line-height: 18px;
        font-weight: normal;
        color: $turquoise;
        &:hover {
          color: $black;
          text-decoration: none; } }
      &__bottom span.gray {
        padding-left: 0;
        margin-left: 0;
        border-left: none; } } } }

.leave {
  .caption-16 {
    margin-bottom: 10px;
    font-size: 22px; }
  &-container {
    margin-bottom: 25px;
    padding: 21px;
    border: $border-metal;
    .form-button {
      width: 195px;
      text-transform: none;
      height: 40px;
      line-height: 38px; } }
  &-form {
    display: none;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #f0f0f0;
    padding: 22px;
    label {
      font-weight: 200;
      width: 270px;
      padding-right: 10px;
      vertical-align: bottom;
      margin: 0; }
    .textarea {
      margin-bottom: 15px;
      width: 100%;
      padding: 0; }
    textarea {
      margin-top: 5px;
      width: 100%;
      display: block;
      height: 111px;
      padding: 15px;
      border-color: transparent;
      resize: none; }
    input {
      margin-top: 5px;
      border-color: transparent;
      height: 40px;
      line-height: 40px;
      border-radius: 0; }
    .form-button {
      margin-left: 38px;
      vertical-align: bottom; } } }

.leave-comments {
  margin-top: 20px;
  &__item {
    min-height: 100px;
    position: relative;
    padding-top: 19px;
    padding-left: 95px;
    .person {
      position: absolute;
      top: 0;
      left: 0; }
    p:last-child {
      margin-bottom: 0; } }
  &__name {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 16px;
    .gray {
      display: inline-block;
      padding-left: 11px;
      margin-left: 11px;
      border-left: 1px solid $black;
      font-size: 14px;
      line-height: 17px;
      vertical-align: middle;
      font-weight: normal; } } }

.person {
  display: inline-block;
  background: url(../img/person.png) center no-repeat;
  width: 74px;
  height: 74px; }

.article {
  &-top {
    display: flex;
    margin-bottom: 10px;
    padding-bottom: 4px;
    border-bottom: 1px solid $light-gray;
    justify-content: space-between;
    align-items: center; }
  &__date {
    color: $gray;
    &_in {
      display: inline-block;
      padding-left: 33px;
      background: url(../img/article-date.png) 4px 0 no-repeat; }
    .inherits {
      margin-left: 3px;
      margin-right: 3px; } }
  &__author {
    color: $gray;
    font-size: 16px;
    .inherits {
      margin-left: 3px;
      margin-right: 3px; } }
  &-content {
    margin-bottom: 55px;
    img {
      margin-bottom: 20px; }
    p {
      margin-bottom: 0;
      font-size: 18px;
      line-height: 30px;
      font-weight: 200;
      &:not(:last-child) {
        margin-bottom: 30px; } }
    ul,ol {
      list-style: none;
      margin-top: 40px;
      margin-bottom: 40px;
      padding-left: 40px; }
    li {
      position: relative;
      font-weight: 200;
      margin-bottom: 15px;
      color: $black;
      font-size: 18px;
      line-height: 30px;
      &::before {
        position: absolute;
        display: block;
        top: 12px;
        left: -20px; } }
    ul li::before {
      content: '';
      width: 6px;
      height: 6px;
      background-color: $turquoise;
      border-radius: 50%; }
    ol {
      counter-reset: article-list;
      li::before {
        content: counter(article-list);
        counter-increment: article-list;
        top: 0;
        left: -22px;
        color: $turquoise; } }
    iframe {
      display: block;
      margin-top: 40px;
      margin-bottom: 40px;
      width: 100%;
      height: 483px; }
    blockquote {
      margin-bottom: 20px;
      padding: 15px 65px;
      position: relative;
      background-color: $bg-light-gray;
      font-size: 18px;
      line-height: 30px;
      font-weight: 200;
      border: none;
      border-bottom: 3px solid $turquoise;
      &::before {
        position: absolute;
        top: 15px;
        left: 25px;
        font-family: FontAwesome;
        content: '\f100';
        display: inline-block;
        color: $turquoise;
        font-size: 30px; } }
    .article__img {
      margin-bottom: 20px;
      img {
        margin-bottom: 10px; }
      p {
        text-align: center;
        font-size: 15px;
        font-style: italic;
        line-height: 20px; } }
    h2 {
      font-size: 25px; }
    h3 {
      font-size: 21px;
      font-weight: bold; } }
  &-bottom {
    margin-bottom: 30px;
    border-top: 1px solid $light-gray;
    border-bottom: 1px solid $light-gray;
    padding-top: 6px;
    padding-bottom: 6px;
    .text {
      float: left;
      margin-right: 23px;
      line-height: 28px; } }
  &__sharing {
    a {
      display: inline-block;
      line-height: 28px;
      color: #fff;
      width: 120px;
      text-align: center;
      margin-right: 12px;
      letter-spacing: 0;
      &:hover {
        text-decoration: none; } } } }


.vk {
  background-color: $vk-bg; }

.facebook {
  background-color: $facebook-bg; }

.ok {
  background-color: $ok-bg; }

.tw {
  background-color: $turquoise; }

.gp {
  background-color: $google-bg; }



.sales {
  h1 {
    margin-bottom: 40px; }
  &-wrapper {
    margin-left: -30px; }
  &-item {
    margin-bottom: 30px;
    float: left;
    width: calc(33.33% - 30px);
    margin-left: 30px;
    border: $border-metal;
    &__image {
      margin-bottom: 15px;
      display: block;
      position: relative;
      .img-bg {
        height: 200px;
        -webkit-background-size: cover;
        background-size: cover; }
      &:hover .img-bg {
        opacity: .4; } }
    .caption {
      margin-bottom: 10px;
      height: 56px;
      overflow: hidden;
      font-size: 19px;
      padding-left: 20px;
      &:hover {
        color: $black; } }
    .text {
      margin-bottom: 10px;
      padding-right: 60px;
      height: 40px;
      overflow: hidden;
      color: $gray;
      padding-left: 20px;
      p {
        font-size: 14px;
        line-height: 20px; } }
    &__link {
      margin-bottom: 14px;
      display: inline-block;
      padding-left: 20px;
      .fa {
        font-size: 20px;
        position: relative;
        top: 2px;
        margin-left: 10px; }
      &:hover {
        text-decoration: none;
        color: $black; } }
    &__bottom {
      line-height: 32px;
      padding-left: 20px;
      border-top: $border-metal;
      height: 33px;
      overflow: hidden;
      white-space: nowrap;
      span {
        display: inline-block;
        line-height: 17px;
        vertical-align: middle;
        &.gray {
          padding-left: 11px;
          border-left: 1px solid $black;
          margin-left: 11px; } } } } }

.sales-banner {
  height: 130px;
  padding-left: 824px;
  padding-top: 17px;
  margin-bottom: 40px;
  background-repeat: no-repeat;
  background-size: cover;
  .caption-16 {
    font-size: 18px; }
  .main_banner__logo {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
    margin-right: 11px;
    width: 206px;
    background-size: contain;
    background-position: center;
    border-bottom: 3px solid $turquoise; }
  .form-button {
    position: relative;
    top: 5px;
    width: auto;
    height: 38px;
    padding-left: 21px;
    padding-right: 21px;
    line-height: 36px; } }

.sale {
  &-page {
    .catalog-description {
      a {
        &:hover {
          color: $black;
          text-decoration: none; }
        .fa {
          font-size: 20px;
          position: relative;
          top: 3px;
          margin-left: 10px; } } }
    h1 {
      margin-bottom: 40px; }
    .caption {
      margin-bottom: 25px;
      font-size: 24px; } }
  &__img {
    margin-bottom: 25px; }
  &-wrapper {
    margin-left: -30px;
    margin-bottom: 12px;
    .card-mini {
      float: left;
      width: calc(25% - 30px);
      margin-left: 30px;
      margin-bottom: 30px;
      border-right: 1px solid $light-gray;
      transition-duration: .2s;
      &:hover {
        box-shadow: 0 0 3px 0 rgba(0,0,0,.35); } } } }

.additional-text {
  display: none; }

.publications {
  h1 {
    margin-bottom: 40px; }
  &-nav {
    border-radius: 3px;
    border: $border-metal;
    ul {
      margin-bottom: 0; }
    span,a {
      width: 100%;
      display: inline-block;
      padding-left: 16px;
      padding-right: 16px;
      line-height: 48px;
      font-size: 16px;
      color: $black;
      &:not(:last-child) {
        border-bottom: $border-metal; } }
    span {
      background-color: $bg-light-gray; }
    a {
      &:hover {
        background-color: $turquoise;
        color: #fff;
        text-decoration: none; } }
    li {
      &:not(:last-child) {
        border-bottom: $border-metal; } } } }

.publications {
  &-wrapper {
    margin-left: -30px;
    .sales-item {
      .caption {
        font-size: 16px;
        line-height: 18px;
        height: 36px; }
      .text {
        height: 82px;
        position: relative;
        padding-right: 15px;
        line-height: 21px;
        &::after {
          position: absolute;
          bottom: 0;
          left: 0;
          display: block;
          content: '';
          width: 100%;
          height: 21px;
          background-image: linear-gradient(to top,  #fff , transparent); } }
      &__bottom {
        span.gray {
          width: 100%;
          text-align: right;
          border-left: none;
          margin-left: 0;
          padding-right: 15px; } } } }

  &__name {
    position: absolute;
    left: 0;
    bottom: 0;
    min-width: 105px;
    line-height: 22px;
    padding-left: 12px;
    padding-right: 12px;
    text-align: center;
    background-color: $turquoise;
    color: #fff; } }

.play {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  background: url(../img/play.png) center no-repeat;
  width: 82px;
  height: 59px;
  margin-top: -30px;
  margin-left: -41px; }

.article-container {
  .article-aside {
    display: none; } }

.ya-share2__item {
  .ya-share2__badge {
    width: 100%;
    vertical-align: middle;
    .ya-share2__counter_visible {
      float: right; }
    .ya-share2__icon {
      background: none; } } }

.ya-share2__item {
  .ya-share2__badge {
    .ya-share2__icon {
      vertical-align: middle;
      letter-spacing: 0.1em;
      width: auto;
      font-size: 12px;
      color: #fff;
      line-height: 24px;
      &::before {
        display: inline-block;
        vertical-align: middle; } } }
  &_service {
    &_vkontakte {
      .ya-share2__icon::before {
        content: 'Vkontakte'; } }
    &_facebook {
      .ya-share2__icon::before {
        content: 'Facebook'; } }
    &_twitter {
      .ya-share2__icon::before {
        content: 'Twitter'; } }
    &_odnoklassniki {
      .ya-share2__icon::before {
        content: 'Odnoklassniki'; } }
    &_gplus {
      .ya-share2__icon::before {
        content: 'Google +'; } } } }

@media screen and (max-width: 1199px) {
  .license {
    &-wrapper {
      margin-left: -26px;
      .sales-item {
        width: calc(33.33% - 26px);
        margin-left: 26px;
        &__image .img-bg {
          height: 170px; } } } }
  .article {
    .col-lg-9 {
      float: left;
      width: 727px; }
    .col-lg-3 {
      float: left;
      width: 297px; }
    &-container {
      margin-bottom: 40px;
      .catalog-list {
        display: none; } } }
  .leave-form {
    label {
      width: 190px; }
    .form-button {
      width: 150px; } }
  .article-content iframe {
    height: 392px; }
  .news {
    .publications-nav {
      border: none;
      border-radius: 0;
      li:nth-child(n) {
        border: $border-metal;
        margin-right: 20px;
        border-radius: 4px; } } }


  .publications {
    &-wrapper {
      margin-left: -80px;
      .sales-item {
        width: calc(33.33% - 80px);
        margin-left: 80px; } } }
  .sales-item__image .img-bg {
    background-size: cover; }
  .sales-banner {
    padding-left: 660px;
    background-position: -176px center; }
  .publications-nav {
    float: left;
    margin-bottom: 30px;
    max-width: 100%;
    ul {
      display: flex;
      width: 100%; }
    li {
      flex-shrink: 1;
      display: flex;
      float: left;
      width: 268px;
      &:not(:last-child) {
        border-bottom: none;
        border-right: $border-metal; }
      &>* {
        display: flex;
        align-items: center; } } }
  .publications {
    h1 {
      margin-bottom: 35px; } } }


@media screen and (max-width: 1023px) {
  .license {
    h1 {
      margin-bottom: 30px; }
    &-wrapper {
      margin-left: -27px;
      .sales-item {
        width: calc(50% - 27px);
        margin-left: 27px;
        &__image .img-bg {
          height: 190px; } } }
    .sales-banner {
      margin-bottom: 10px;
      margin-top: -15px;
      transform: scale(.742);
      transform-origin: 100% 50%;
      display: block;
      margin-left: -256px;
      .main_banner__logo {
        height: 36px; } } }
  .article {
    h1 {
      text-align: center; }
    .col-lg-9 {
      width: 100%; }
    .col-lg-3 {
      width: 100%; } }
  .article-container {
    .article-aside {
      display: block; } }
  .article-aside {
    display: none;
    >img {
      display: none; }
    .caption {
      text-align: center;
      margin-bottom: 10px; }
    .catalog-list__link:nth-child(n) {
      width: 33.33%;
      float: left;
      margin-bottom: 0;
      margin-left: 0;
      padding-left: 15px;
      min-height: 90px; } }
  .article-posts {
    &-wrapper {
      margin-left: -11px; }
    &__link {
      width: calc(33.33% - 11px);
      float: left;
      margin-left: 11px; } }
  .publications {
    &-wrapper {
      margin-left: -30px;
      .sales-item {
        width: calc(50% - 30px);
        margin-left: 30px; } }
    h1 {
      text-align: center;
      margin-bottom: 25px; }
    &-wrapper {
      padding-left: 75px;
      padding-right: 75px; } }
  .sales-item {
    width: calc(50% - 30px); }
  .sales-banner {
    display: none; }
  .sale {
    &-wrapper {
      margin-left: 0;
      margin-bottom: 0;
      .card-mini {
        margin-left: 0;
        width: 33%; } } } }


@media screen and (max-width: 767px) {
  .article {
    &__author {
      font-size: 0;
      .inherits {
        font-size: 14px; } }
    &__date {
      font-size: 0;
      .inherits {
        font-size: 14px; }
      &_in {
        font-size: 14px; } } }
  .license {
    h1 {
      text-align: center;
      margin-top: -15px;
      margin-bottom: 10px; }
    &-wrapper {
      margin-left: 0;
      padding-left: 40px;
      padding-right: 40px;
      .sales-item {
        width: 100%;
        margin-left: 0;
        margin-bottom: 33px;
        &__image .img-bg {
          height: 200px; } } }
    .sales-banner {
      display: none; } }
  .article-content {
    blockquote {
      padding-right: 20px; }
    h2 {
      font-size: 17px; }
    h3 {
      font-size: 16px; }
    p {
      line-height: 24px;
      font-size: 16px;
      &:not(:last-child) {
        margin-bottom: 15px; } }
    li {
      line-height: 24px;
      margin-bottom: 7.5px;
      font-size: 16px; }
    iframe {
      margin-top: 20px;
      margin-bottom: 20px;
      height: 252px; }
    ol,ul {
      margin-top: 20px;
      margin-bottom: 20px; } }
  .article-bottom {
    position: relative;
    padding-left: 110px;
    .text {
      position: absolute;
      top: 7px;
      left: 0; } }
  .article__sharing {
    a {
      margin-bottom: 10px; } }
  .article-aside {
    .catalog-list__link:nth-child(n) {
      width: 100%; } }
  .article-posts__link {
    width: calc(50% - 11px);
    margin-bottom: 11px;
    .img-bg {
      height: 163px; } }
  .leave-form {
    label {
      width: 178px; }
    .form-button {
      margin-left: 182px;
      margin-top: 11px; } }
  .news .publications-nav {
    margin-right: -10px;
    li:nth-child(n) {
      margin-right: 10px; } }
  .publications {
    &-wrapper {
      margin-left: 0;
      .sales-item {
        width: 100%;
        margin-left: 0; } }
    h1 {
      margin-bottom: 10px;
      margin-top: -15px; } }
  .sales {
    h1 {
      text-align: center;
      margin-bottom: 10px;
      margin-top: -15px; }
    &-wrapper {
      margin-left: 0;
      padding-left: 40px;
      padding-right: 40px; }
    &-item {
      width: 100%;
      margin-bottom: 20px;
      margin-left: 0;
      .img-bg {
        background-size: cover; } } }
  .sale__img {
    position: relative;
    overflow: hidden;
    img {
      width: 171%;
      margin-left: -71%;
      max-width: none; } }
  .sale-page {
    h1 {
      margin-top: -15px;
      margin-bottom: 10px;
      text-align: center; }
    .caption {
      font-size: 18px; } }
  .sale-wrapper {
    .card-mini {
      width: 50%; } }
  .sale-page {
    .caption {
      margin-bottom: 15px; } }
  .sale__img {
    margin-bottom: 15px; } }

@media screen and (max-width: 479px) {
  .license {
    h1 {
      margin-bottom: 10px;
      margin-top: 0; } }
  .leave-comments__item {
    padding-left: 0;
    .person {
      position: static;
      float: left;
      margin-right: 10px;
      width: 30px;
      height: 30px;
      background-size: contain; } }
  .leave-comments__name .gray {
    margin-left: 0;
    padding-left: 0;
    border-left: none;
    display: block; }
  .leave-form {
    label {
      padding-right: 0;
      width: 100%; }
    .form-button {
      margin-left: 0;
      width: 100%; } }
  .leave-container .form-button {
    width: 100%; }
  .article-posts-wrapper {
    margin-left: 0;
    .article-posts__link {
      width: 100%;
      margin-left: 0;
      .img-bg {
        height: 200px; } } }
  .license {
    &-wrapper {
      padding-left: 0;
      padding-right: 0; } }
  .article {
    h1 {
      margin-top: 10px; } }
  .article-content {
    iframe {
      height: 162px; } }
  .news .publications-nav li:nth-child(n) {
    margin-right: 0;
    margin-bottom: 10px; }
  .publications {
    h1 {
      margin-top: 0;
      margin-bottom: 5px; }
    &-wrapper {
      padding: 0; } }
  .publications-nav {
    margin-bottom: 10px;
    ul {
      flex-wrap: wrap; }
    li {
      width: 100%;
      &:not(:last-child) {
        border-right: none;
        border-bottom: $border-metal; } } }
  .sales {
    h1 {
      margin-top: 0;
      margin-bottom: 5px; }
    &-wrapper {
      padding: 0; } }
  .sale-wrapper {
    .card-mini {
      width: 100%; } }
  .sale-page h1 {
    margin-top: 0;
    margin-bottom: 5px; } }
