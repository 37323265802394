.main_banner {
  margin-bottom: 25px;
  max-height: 360px;
  overflow: hidden;
  &__item {
    height: 360px;
    position: relative;
    display: block;
    color: $black;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 4px;
    .text {
      width: 320px;
      font-size: 15px;
      font-weight: bold;
      line-height: 20px;
      color: #555; }
    &:hover {
      text-decoration: none;
      color: $black; } }
  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-top: 90px;
    padding-left: 390px;
    background-image: url(../pictures/main-bannre-bg.png);
    background-repeat: no-repeat;
    background-position: 115px -169px; }
  &__logo {
    margin-bottom: 30px;
    background: url(../pictures/main-banner-logo.png) no-repeat 0 0;
    height: 43px; } }

.slick-arrow {
  position: absolute;
  width: 48px;
  height: 48px;
  margin-top: -24px;
  top: 50%;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, .5);
  line-height: 44px;
  border: 1px solid $black;
  text-align: center;
  font-size: 30px;
  z-index: 10;
  cursor: pointer;
  opacity: .5;
  transition-duration: .1s;
  &:hover {
    opacity: 1; }
  &:first-child {
    left: 21px; }
  &:nth-child(3) {
    right: 21px;
    &::before {
      position: relative;
      left: 2px; } } }

.home {
  &-promo {
    &__top {
      padding-bottom: 19px;
      border-bottom: 1px solid $bg-light-gray;
      .caption {
        float: left; } }
    &__slider {
      padding-left: 50px;
      padding-right: 50px;
      border-radius: 6px;
      box-shadow:  0 0 4px 0 rgba(0,0,0,0.1);
      position: relative;
      margin-bottom: 70px;
      max-height: 400px;
      overflow: hidden;
      &.slick-slider {
        overflow: visible; }
      .slick-list {
        border-right: 1px solid $light-gray; }
      .slick-dots {
        position: absolute;
        top: 100%;
        width: 100%;
        left: 0; } } } }

.show_all {
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 23px;
  background-color: $light-gray;
  color: $black;
  line-height: 40px;
  border-radius: 3px;
  &:hover {
    text-decoration: none;
    background-color: #d4d4d4;
    color: $black; } }

.stars {
  span {
    color: $turquoise;
    display: inline-block;
    margin-left: 4px;
    margin-bottom: 3px; }
  .fa {
    color: $light-gray; }
  &.stars-1 {
    .fa:first-child {
      color: $red; } }
  &.stars-2 {
    .fa:first-child, .fa:nth-child(2) {
      color: $red; } }
  &.stars-3 {
    .fa:nth-child(1), .fa:nth-child(3), .fa:nth-child(2) {
      color: $red; } }
  &.stars-4 {
    .fa:nth-child(4), .fa:nth-child(3), .fa:nth-child(2), .fa:nth-child(1) {
      color: $red; } }
  &.stars-5 {
    .fa {
      color: $red; } } }

.img-bg {
  display: block;
  max-width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  text-decoration: none;
  &:hover {
    text-decoration: none; } }

.sale {
  position: absolute;
  top: 13px;
  right: 13px;
  display: block;
  width: 86px;
  height: 86px;
  padding-top: 23px;
  padding-left: 1px;
  border-radius: 50%;
  background-color: $red;
  font-size: 20px;
  line-height: 20px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0;
  z-index: 10;
  span {
    display: block;
    font-size: 16px;
    text-transform: none;
    line-height: 20px;
    letter-spacing: .04em; } }

.price {
  margin-bottom: 3px;
  font-size: 20px;
  font-weight: bold;
  color: $red; }

.old-price {
  margin-bottom: 3px;
  color: $gray;
  text-decoration: line-through;
  font-size: 14px;
  line-height: 20px;
  min-height: 20px; }

.card-mini {
  border: 1px solid $light-gray;
  display: inline-block;
  border-right: none;
  .caption {
    display: block;
    height: 40px;
    margin-bottom: 3px;
    padding-left: 14px;
    padding-right: 14px;
    font-size: 16px;
    line-height: 20px;
    overflow: hidden; }
  .img-bg {
    margin-bottom: 17px;
    border-bottom: 1px solid $light-gray;
    height: 200px; }
  a {
    text-decoration: none;
    position: relative;
    display: block; }
  .stars, .price, .old-price {
    padding-left: 14px;
    padding-right: 14px; }
  &__bottom {
    margin-top: 7px;
    margin-bottom: 13px;
    padding-left: 14px;
    padding-right: 14px;
    height: 40px;
    .buy {
      float: left; }
    .add-favorites {
      float: right; } } }

.buy {
  display: inline-block;
  height: 40px;
  padding-left: 55px;
  padding-right: 42px;
  color: #fff;
  line-height: 40px;
  background: url(../img/buy-cart.png) 16px 7px no-repeat;
  background-color: $green;
  border-radius: 4px;
  border: none;
  white-space: nowrap;
  &:hover {
    background-color: $dark-green;
    text-decoration: none;
    color: #fff; } }

.add-favorites {
  padding: 0;
  height: 40px;
  line-height: 38px;
  background-color: #fff;
  border: 1px solid $turquoise;
  border-radius: 4px;
  width: 40px;
  color: $turquoise;
  position: relative;
  overflow: hidden;
  transition-duration: 0s;
  white-space: nowrap;
  span {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 51px;
    width: 150px; }
  .fa {
    float: right;
    width: 38px;
    font-size: 23px; }
  &:hover {
    width: 100%;
    margin-left: calc(40px - 100%);
    transition-duration: 0.2s; } }

.arrow-product {
  border: none;
  margin: -24px;
  background: transparent;
  &:hover {
    color: $turquoise; } }

.slick-dots {
  padding: 17px 0;
  list-style: none;
  margin: 0;
  text-align: center;
  li {
    display: inline-block;
    button {
      background-color: transparent;
      border: none;
      width: 30px;
      height: 30px;
      font-size: 0;
      &::before {
        display: inline-block;
        width: 17px;
        height: 17px;
        content: '';
        border-radius: 50%;
        border:  3px solid $gray;
        background-color: transparent; } }
    &.slick-active button::before {
      background-color: $gray; } } }

.home {
  &-advantages {
    margin-bottom: 32px;
    background: url(../img/pattern1.jpg) 0 0;
    padding-top: 48px;
    padding-bottom: 35px;
    &__item {
      float: left;
      width: 20%;
      display: block;
      font-weight: bold;
      font-size: 16px;
      color: $black;
      &:hover {
        color: $black; } } }
  &__icon {
    margin-top: -16px;
    margin-right: 22px;
    display: block;
    float: left;
    width: 85px;
    height: 85px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #fff; }
  &__car {
    background-image: url(../img/home-car.png); }
  &__time {
    background-position: 21px 13px;
    background-image: url(../img/home-time.png); }
  &__user {
    background-image: url(../img/home-user.png);
    margin-right: 10px; }
  &__money {
    margin-left: 10px;
    background-image: url(../img/home-money.png); }
  &__opt {
    background-image: url(../img/home-opt.png); } }

.home {
  &-posts {
    padding-bottom: 41px;
    background: url(../img/pattern2.jpg);
    .caption {
      margin-top: 35px;
      margin-bottom: 35px;
      color: #fff;
      a {
        color: #fff;
        font-size: 14px;
        font-weight: normal;
        text-transform: uppercase;
        .fa {
          margin-left: 20px;
          font-size: 20px; } }
      .text-right {
        float: right; } }
    &-container {
      border: 1px solid $light-gray;
      background-color: #fff;
      //.slick-list
 }      ////overflow: visible
    &__item {
      float: left;
      width: 25%;
      height: 300px;
      border-top: 1px solid $light-gray;
      margin-top: -1px;
      a {
        position: relative;
        display: block;
        .in-time {
          position: absolute;
          top: 9px;
          right: 9px; }
        .new {
          position: absolute;
          top: 9px;
          right: 9px; }
        .date {
          position: absolute;
          top: 0;
          left: 14px; }
        &:hover {
          text-decoration: none; } }
      &:not(:last-child) {
        border-right: 1px solid $light-gray; }
      &:not(:nth-child(4)) {
        border-right: 1px solid $light-gray; }
      .img-bg {
        height: 195px;
        border-bottom: 1px solid $light-gray; }
      .text {
        display: block;
        padding: 22px 30px 0 14px;
        margin-bottom: 22px;
        color: $black;
        line-height: 20px;
        position: relative;
        height: 82px;
        overflow: hidden;
        font-weight: bold;
        min-height: 82px;
        &::after {
          display: inline-block;
          content: '';
          height: 20px;
          position: absolute;
          bottom: 0;
          right: 0;
          width: 50%;
          background: linear-gradient(to left,  #fff 0%,  transparent 100%); } } } } }

.home-posts__item:hover {
  height: 300px;
  position: relative;
  z-index: 10;
  box-shadow: 0 0 5px 0 rgba(0,0,0,.2);
  .text {
    height: auto;
    background-color: #fff;
    margin-bottom: 0;
    padding-bottom: 10px;
    &::after {
      display: none; } } }

.date {
  display: block;
  width: 60px;
  padding-top: 35px;
  padding-bottom: 9px;
  line-height: 18px;
  text-align: center;
  color: #fff;
  background-image: url(../img/home-calendar.png);
  background-position: 20px 11px;
  background-repeat: no-repeat;
  background-color: $turquoise; }

.new {
  display: inline-block;
  text-align: center;
  height: 87px;
  width: 87px;
  font-size: 20px;
  padding-top: 20px;
  font-weight: bold;
  line-height: 20px;
  color: #fff;
  background-color: #9eda29;
  border-radius: 50%;
  span {
    display: block;
    font-size: 16px; } }

.in-time {
  display: inline-block;
  padding-top: 22px;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  background-color: #c364f9;
  color: #fff;
  font-size: 16px;
  line-height: 18px;
  font-weight: bold;
  text-align: center; }

.home {
  &-about {
    padding-bottom: 180px;
    background: url(../pictures/home-bg.jpg) center;
    background-size: cover;
    .float-50 {
      padding-right: 15px; }
    .caption {
      margin-top: 40px;
      margin-bottom: 40px;
      font-size: 26px;
      position: relative;
      .overton-logo {
        margin-top: 10px; }
      &::after {
        display: block;
        position: absolute;
        content: '';
        height: 3px;
        width: 570px;
        right: 0;
        bottom: -12px;
        background-color: $turquoise; } } }
  &-video {
    margin-top: 51px;
    position: relative;
    margin-right: -15px;
    border-radius: 5px;
    overflow: hidden;
    iframe {
      display: block;
      width: 100%;
      height: 330px; } } }

.overton-logo {
  display: block;
  height: 25px;
  background-position: 0 0;
  background-repeat: no-repeat; }

.float-50 {
  float: left;
  width: 50%; }

.video-preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  .img-bg {
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover; } }

.home {
  &-feed {
    padding-bottom: 40px;
    &-wrapper {
      border: 1px solid $light-gray; }
    .caption {
      margin-top: 27px;
      margin-bottom: 27px;
      font-size: 26px;
      .text-right {
        float: right; }
      a {
        font-size: 14px;
        font-weight: normal;
        text-transform: uppercase;
        &:hover {
          text-decoration: none;
          color: $black; }
        .fa {
          font-size: 20px;
          position: relative;
          top: 2px;
          margin-left: 10px; } } }
    &__item {
      float: left;
      width: 25%;
      padding: 10px;
      &:not(:last-child) {
        border-right: 1px solid $light-gray; }
      .text {
        display: block;
        color: #333;
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 10px; }
      .date {
        width: auto;
        display: inline-block;
        margin-bottom: 14px;
        padding-left: 32px;
        line-height: 29px;
        padding-top: 0;
        padding-right: 6px;
        padding-bottom: 0;
        background-position: 7px 5px; }
      .more {
        .fa {
          position: relative;
          top: 2px;
          font-size: 20px;
          margin-left: 11px; }
        &:hover {
          color: $black;
          text-decoration: none; } } } } }

.home-brands {
  background-color: $light-gray;
  .caption {
    margin-top: 33px;
    margin-bottom: 33px; } }

.brands-slider {
  margin-bottom: 33px;
  padding-left: 45px;
  padding-right: 45px;
  box-shadow: 0 3px 3px 0 rgba(0,0,0,.35);
  background-color: #fff;
  border-radius: 6px;
  position: relative;
  .slick-list {
    border-right: 1px solid $light-gray;
    border-left: 1px solid $light-gray; }
  &__item {
    .img-bg {
      height: 112px; } } }

.arrow-blue {
  width: 47px;
  height: 66px;
  margin-top: -33px;
  line-height: 62px;
  background-color: $turquoise;
  border-radius: 5px;
  color: #fff;
  border: 1px solid #fff;
  opacity: 1;
  &:first-child {
    left: -35px; }
  &:nth-child(3) {
    right: -35px; } }

.slick-dots_white {
  position: absolute;
  width: 100%;
  li button::before {
    border-color: #fff; }
  li.slick-active button::before {
    background-color: #fff; } }

@media all and (max-width: 1199px) {
  .main_banner {
    max-height: 310px; }
  .main_banner__item {
    height: 310px; }
  .main_banner__content {
    background-size: 78%;
    background-position: 126px -169px; }
  .home-promo__slider {
    padding-left: 95px;
    padding-right: 95px; }
  .home-posts__item:nth-child(n) {
    border-right: 1px solid $light-gray;
    margin-right: -1px;
    height: 235px;
    &:hover {
      height: 235px; }
    .img-bg {
      height: 155px; }
    .text {
      min-height: 0;
      height: 60px;
      &:hover {
        height: 60px; }
      &::after {
        display: none; } } }
  .home-posts-container {
    margin-left: 35px;
    margin-right: 35px; }
  .home-about {
    display: none; } }


@media all and (max-width: 1023px) {
  .main_banner {
    max-height: 226px; }
  .add-favorites:hover {
    width: 40px; }
  .main_banner__item {
    height: 226px;
    .text {
      font-size: 10px;
      line-height: 14px; } }
  .main_banner__content {
    padding-top: 54px;
    padding-left: 245px;
    background-position: 73px -139px; }
  .main_banner__logo {
    margin-bottom: 15px;
    height: 23px;
    background-size: contain; }
  .home-promo__slider {
    padding-left: 0;
    padding-right: 0; }
  .home-advantages {
    display: none; }
  .home-promo_popular {
    display: none; }
  .home-posts {
    padding-bottom: 64px; }
  .brands-slider__item .img-bg {
    background-size: 110px; } }


@media all and (max-width: 767px) {
  .main_banner {
    max-height: 240px; }
  .main_banner__item {
    height: 240px; }
  .main_banner__content {
    padding-left: 199px;
    padding-top: 20px;
    padding-right: 30px;
    background-image: url(../pictures/main-bannre-bg-xs.png);
    background-position: 40px -70px;
    background-size: auto;
    .text {
      padding-right: 50px;
      font-size: 13px;
      line-height: 19px;
      width: 100%; } }
  .main_banner__logo {
    margin-bottom: 5px; }
  .home-posts-container {
    margin-left: -15px;
    margin-right: -15px; }
  .home-posts__item:nth-child(n) {
    height: 270px;
    .img-bg {
      height: 185px; } }
  .arrow-blue {
    &:first-child {
      left: 15px; }
    &:nth-child(3) {
      right: 15px; } }
  .home-feed-wrapper {
    padding-left: 42px;
    padding-right: 42px;
    border-radius: 5px;
    .slick-list {
      border-left: 1px solid $light-gray;
      border-right: 1px solid transparent; } }
  .home-feed .caption {
    font-size: 18px; }
  .caption {
    font-size: 18px; } }


@media all and (max-width: 479px) {
  .home-promo__top {
    text-align: center;
    .caption {
      float: none; }
    .show_all {
      display: inline-block;
      margin-left: 0; } }
  .main_banner__item {
    height: 170px; }
  .main_banner {
    margin-left: -15px;
    margin-right: -15px;
    max-height: 234px;
    overflow: visible;
    margin-bottom: 0;
    &__content {
      padding-left: 123px;
      padding-top: 13px;
      background-position: -23px -130px;
      .text {
        font-size: 10px;
        line-height: 16px;
        padding-right: 0; } }
    &__logo {
      height: 21px; } }
  .home-promo__slider {
    margin-left: 15px;
    margin-right: 15px;
    .arrow-product {
      &:first-child {
        left: -20px; }
      &:nth-child(3) {
        right: -20px; } } }
  .home-posts {
    .caption .text-right {
      float: none;
      font-size: 12px; } }
  .home-feed {
    padding-bottom: 0;
    &-wrapper {
      margin-left: -15px;
      margin-right: -15px;
      padding-left: 33px;
      padding-right: 33px;
      .slick-list {
        border-right-color: $light-gray; } }
    &__item {
      width: 184px; }
    .caption {
      margin-top: 9px;
      margin-bottom: 9px;
      a {
        font-size: 12px;
        position: relative;
        top: -2px; } } }
  .home-brands {
    .caption {
      text-align: center;
      margin-top: 9px;
      margin-bottom: 9px; } }
  .brands-slider {
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 12px;
    &__item {
      .img-bg {
        width: 108px; } } } }
