$turquoise: #00adee;
$black: #333;
$light-gray: #e6e6e6;
$gray: #777;
$text-gray: #666;
$green: #39b455;
$red: #ed3b4d;
$facebook-bg: #2d409f;
$vk-bg: #3d5e8b;
$google-bg: #bb3b02;
$twitter-bg: #00adee;
$ok-bg: #ff8226;
$instagram-bg: url(../img/instagramm-bg.png) center no-repeat;
$bg-light-gray: #fafafa;
$dark-green: #32a14b;
$border-metal: 1px solid #e4e6ed;
