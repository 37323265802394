.registration {
  h1 {
    margin-bottom: 40px; }
  &-container {
    margin-bottom: 34px;
    padding: 30px;
    border: 1px solid $light-gray; }
  &-form {
    padding-left: 32px;
    width: 630px;
    float: left;
    &__checkboxes {
      margin-bottom: 30px; }
    &__inputs {
      margin-bottom: 28px; }
    label {
      display: block;
      width: 278px;
      font-size: 16px;
      letter-spacing: 0;
      margin-bottom: 11px; }
    .invalid-text {
      left: 100%;
      top: 50%;
      width: 100%;
      transform: translate(14px,4px); }
    .checkbox {
      max-width: 100%;
      padding-left: 52px;
      margin-bottom: 18px;
      width: 538px;
      line-height: 24px; }
    .check {
      width: 36px;
      height: 36px;
      top: -5px;
      border-color: #999;
      border-radius: 3px;
      transform: scale(.75);
      will-change: transform; }
    input:checked+.check {
      &::before {
        width: 21px;
        height: 16px;
        top: 50%;
        left: 50%;
        margin-top: -8px;
        margin-left: -10px;
        background: url(../img/check-black.png) center no-repeat; } }
    &__bottom {
      width: 275px;
      margin-bottom: 25px; }
    .text {
      width: 565px;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0; } }
  &-socials {
    width: 461px;
    float: left;
    height: 500px;
    padding-right: 40px;
    border-left: 1px solid $light-gray;
    .text {
      margin-bottom: 30px;
      font-size: 16px;
      letter-spacing: 0; } } }

.registration {
  &-banner {
    margin-bottom: 44px;
    padding-left: 825px;
    padding-top: 19px;
    padding-bottom: 38px;
    background-position: 0 0;
    background-repeat: no-repeat;
    .text {
      margin-bottom: 15px;
      font-size: 18px;
      font-weight: bold; }
    .form-button {
      width: auto;
      padding-left: 15px;
      padding-right: 15px;
      border-radius: 3px; } }
  &__logo {
    display: inline-block;
    height: 36px;
    width: 200px;
    background-repeat: no-repeat;
    vertical-align: middle;
    background-position: 0 0;
    background-size: contain;
    margin-right: 10px; } }

.cart {
  &-page {
    h1 {
      margin-bottom: 30px; } }
  &-left {
    border: $border-metal; }
  &__reviews {
    &:hover {
      color: $black;
      text-decoration: none; } }
  &-card {
    padding: 18px;
    &::after {
      display: table;
      clear: both;
      content: ''; }
    .stars {
      display: inline-block;
      margin-right: 7px;
      span {
        color: $gray;
        margin-left: 10px; } }
    &-actions {
      margin-bottom: 28px; }
    &-middle {
      float: left;
      width: 528px;
      padding-right: 30px;
      .caption-16 {
        margin-bottom: 20px;
        line-height: 20px;
        color: $black;
        &:hover {
          color: $black; } } }
    &__img {
      display: block;
      float: left;
      width: 160px;
      padding-right: 17px;
      .img-bg {
        height: 142px;
        border: 1px solid $light-gray;
        background-size: 90%; } } }
  &__adiing {
    border-bottom: 1px dashed $turquoise;
    &:hover {
      text-decoration: none;
      border-bottom-color: transparent; } } }

.cart {
  &__delete {
    display: inline-block;
    color: $turquoise;
    cursor: pointer;
    span {
      color: $black;
      border-bottom: 1px dashed $turquoise;
      &:hover {
        border-bottom-color: transparent; } }
    &:hover {
      text-decoration: none; } }
  &-container {
    margin-bottom: 36px; }
  &-card {
    &:not(:last-child) {
      border-bottom: 1px solid $light-gray; }
    &-right {
      float: left;
      width: 140px;
      .text {
        color: $gray; }
      .price {
        margin-bottom: 7px;
        color: $black;
        line-height: 13px;
        span:not(.inherits) {
          font-size: 14px;
          margin-left: 5px; } } } } }

.cart {
  &-right {
    border: 1px solid $light-gray; }
  &__confirm {
    padding: 14px;
    border-bottom: 1px solid $light-gray;
    .text {
      margin-bottom: 14px;
      font-size: 16px;
      line-height: 18px; }
    .price {
      margin-bottom: 16px;
      font-size: 30px;
      color: $black; } }
  &-back {
    padding: 14px;
    .fa {
      margin-right: 10px;
      font-size: 20px;
      position: relative;
      top: 1px; }
    a {
      &:hover {
        text-decoration: none;
        color: $black; } } } }


.confirm {
  display: inline-block;
  padding-left: 14px;
  padding-right: 14px;
  color: #fff;
  background-color: $green;
  border-radius: 3px;
  line-height: 40px;
  &:hover {
    background-color: $dark-green;
    color: #fff;
    text-decoration: none; } }

.quantity-inputs {
  margin-bottom: 20px;
  &::after {
    display: table;
    clear: both;
    content: ''; }
  button {
    width: 34px;
    height: 34px;
    margin-right: 2px;
    border-radius: 3px;
    border: 1px solid $turquoise;
    color: $turquoise;
    background-color: #fff;
    float: left;
    &.plus::after {
      content: '+'; }
    &.minus::after {
      content: '-';
      position: relative;
      top: 2px; } }
  input {
    width: 66px;
    height: 34px;
    float: left;
    margin-right: 2px;
    border: none;
    border-radius: 3px;
    background-color: $light-gray;
    text-align: center; } }

.gray {
  color: $gray; }
.order {
  h1 {
    margin-bottom: 30px; } }
.order-left {
  margin-bottom: 27px;
  .caption-16 {
    margin-bottom: 0;
    padding: 15px 16px;
    border-radius: 5px 5px  0 0;
    background-color: $turquoise;
    color: #fff;
    span {
      font-weight: normal; } }
  &-wrapper {
    border: $border-metal;
    border-top: none; }
  &__link {
    display: block;
    padding: 15px 16px;
    border-bottom: $border-metal;
    color: $black;
    font-weight: bold;
    &:hover {
      text-decoration: none;
      background-color: $bg-light-gray;
      color: $black; }
    span {
      font-weight: normal; } } }

.order__sum {
  padding: 15px 16px;
  border-bottom: $border-metal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  .inherits {
    margin-right: 20px; }
  .price {
    display: inline-block;
    vertical-align: middle;
    color: $black; } }

#coupon {
  border-color: $turquoise;
  color: $turquoise;
  &:-ms-input-placeholder {
    color: $turquoise; }
  &::placeholder {
    color: $turquoise; }
  &::-moz-placeholder {
    color: $turquoise; }
  &::-webkit-input-placeholder {
    color: $turquoise; } }

.order-banner {
  position: relative;
  height: 385px;
  margin-bottom: 30px;
  background-repeat: no-repeat;
  background-size: cover;
  .main_banner__logo {
    position: absolute;
    right: 11px;
    top: 90px;
    width: 140px;
    height: 52px;
    background-color: #fff;
    border-radius: 50%;
    background-size: 100px;
    background-position: center; }
  .caption {
    position: absolute;
    left: 13px;
    bottom: 70px;
    font-size: 25px;
    text-transform: uppercase;
    color: #fff;
    line-height: 31px;
    span {
      display: block;
      font-size: 32px;
      line-height: 32px; } }
  .text {
    position: absolute;
    bottom: 23px;
    left: 13px;
    font-size: 16px;
    font-weight: bold; } }

.order-form {
  margin-bottom: 40px;
  textarea {
    width: 100%;
    height: 105px;
    resize: none;
    border-radius: 3px;
    border: 1px solid $gray;
    padding: 10px;
    vertical-align: middle; }
  &-top {
    border: $border-metal;
    padding-top: 22px;
    padding-bottom: 29px; }
  &-left {
    width: 216px;
    padding-left: 33px;
    float: left;
    min-height: 1px;
    .text {
      font-size: 16px;
      line-height: 34px;
      margin-bottom: 18px; } }
  &-right {
    float: left;
    .ui-selectmenu-button.ui-button {
      width: 280px; }
    label {
      display: block;
      width: 280px;
      margin-bottom: 18px; }
    .checkbox {
      padding-left: 51px;
      margin-top: 30px;
      margin-bottom: 30px;
      width: 100%;
      font-size: 14px; }
    .check {
      top: -8px;
      width: 34px;
      height: 34px;
      border-radius: 3px;
      transform: scale(.75);
      will-change: transform; }
    input:checked+.check {
      &::before {
        position: static;
        width: 100%;
        height: 100%;
        background: url(../img/check-black.png) center no-repeat; } } }
  &-middle {
    padding-bottom: 22px;
    padding-top: 22px;
    border: $border-metal;
    border-top: none; } }

.legal-inputs {
  padding-left: 33px;
  padding-right: 33px;
  margin-bottom: -10px;
  label {
    width: 50%;
    margin-bottom: 10px;
    float: left;
    font-size: 16px;
    &:nth-child(2n) {
      padding-left: 6px; }
    &:nth-child(2n-1) {
      padding-right: 6px; } } }

.order {
  &-comment {
    label {
      width: 605px;
      max-width: 100%;
      margin-bottom: 0; } }
  &__coupon {
    padding: 22px 0;
    border: $border-metal;
    border-top: none;
    input {
      @extend #coupon; }
    label {
      margin-bottom: 0;
      width: 280px; } }
  &-self {
    margin-top: 30px; }
  &-method {
    margin-bottom: 30px;
    padding-top: 22px;
    padding-bottom: 22px;
    border: $border-metal;
    border-top: none; }
  &__choose {
    display: inline-block;
    height: 40px;
    padding-left: 50px;
    padding-right: 50px;
    line-height: 38px;
    background-color: #fff;
    border: 1px solid $turquoise;
    border-radius: 4px;
    color: $turquoise; }
  &-where {
    margin-bottom: 25px;
    padding-left: 34px;
    padding-right: 34px;
    max-height: 335px;
    overflow: auto;
    background-color: $bg-light-gray;
    counter-reset: order-where;
    border-top: 1px solid $light-gray;
    border-bottom: 1px solid $light-gray;
    &__item {
      position: relative;
      padding: 38px  185px 20px 80px;
      &:not(:last-child) {
        border-bottom: 1px solid $light-gray; }
      &::before {
        content: counter(order-where);
        counter-increment: order-where;
        display: block;
        position: absolute;
        width: 56px;
        height: 56px;
        border-radius: 50%;
        background-color: #fff;
        text-align: center;
        line-height: 56px;
        font-size: 18px;
        font-weight: bold;
        top: 21px;
        left: 0; }
      .caption-16 {
        color: $turquoise; }
      .text {
        font-weight: 200; }
      label {
        position: absolute;
        top: 71px;
        right: 16px;
        cursor: pointer; } } } }

input:checked+.order__choose {
  background-color: $turquoise;
  color: #fff;
  font-size: 0;
  .text-alt {
    display: inline;
    font-size: 14px; } }

.text-alt {
  display: none; }

.text-alert {
  color: $turquoise; }

.order {
  &-free {
    margin-bottom: 30px;
    position: relative;
    padding: 25px 35px 15px;
    border: 1px solid $light-gray;
    .text {
      font-size: 16px;
      font-weight: bold;
      line-height: 18px;
      margin-bottom: 10px;
      width: 533px;
      max-width: 100%;
      .price {
        color: $turquoise;
        font-size: 20px;
        margin-left: 5px; } }
    .return {
      position: absolute;
      bottom: 30px;
      right: 22px;
      width: auto;
      height: 40px;
      line-height: 38px;
      padding-left: 61px;
      padding-right: 61px;
      color: $turquoise;
      background-color: #fff;
      border: 1px solid $turquoise;
      &:hover {
        background-color: $turquoise;
        color: #fff; }
      &:active {
        box-shadow: inset 4px 4px 2px 0 rgba(0,0,0,0.2); } } } }

.order {
  &-total {
    &-right {
      float: left;
      width: 334px;
      padding: 20px;
      border: $border-metal;
      .text {
        margin-bottom: 5px; }
      .caption-16 {
        margin-bottom: 17px;
        line-height: 20px;
        .price {
          color: $black;
          font-size: 30px;
          margin-left: 10px; } }
      .form-button {
        height: 40px;
        line-height: 38px;
        background-color: $green;
        border-color: $green;
        text-transform: none;
        &:hover {
          color: $green;
          background-color: #fff; } } }

    &-left {
      margin-right: 27px;
      float: left;
      width: 509px;
      padding: 20px 20px 20px 34px;
      background-color: $bg-light-gray;
      border: $border-metal;
      .caption-16 {
        margin-bottom: 15px; }
      .price {
        margin-left: 15px;
        font-size: 20px;
        line-height: 20px;
        color: $black; }
      .ui-selectmenu-button.ui-button {
        width: 264px;
        float: right; }
      label {
        width: 100%;
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 34px; }
      .text {
        width: 294px;
        max-width: 100%;
        a {
          color: $black;
          text-decoration: underline;
          &:hover {
            text-decoration: none; } } } } } }

.payment {
  margin-bottom: 40px;
  &-container {
    padding: 25px 35px;
    border: $border-metal;
    .caption {
      margin-bottom: 25px;
      font-size: 22px; }
    .text {
      width: 450px;
      max-width: 100%;
      margin-bottom: 20px;
      padding-bottom: 15px;
      border-bottom: 1px solid $light-gray;
      font-size: 16px;
      line-height: 34px;
      letter-spacing: 0;
      .gray {
        margin-left: 20px; } }
    .caption-16 {
      margin-bottom: 45px;
      .price {
        margin-left: 20px;
        color: $black; } }
    .form-button {
      width: auto;
      height: 40px;
      line-height: 38px;
      padding-left: 110px;
      padding-right: 100px;
      border-color: $green;
      background-color: $green;
      text-transform: none;
      &:hover {
        color: $green;
        background-color: #fff; } } }
  &-table {
    margin-bottom: 20px;
    table {
      border-collapse: collapse;
      border: 1px solid $light-gray; }
    td {
      height: 97px;
      width: 175px;
      text-align: center;
      border: 1px solid $light-gray;
      font-size: 16px;
      b {
        display: block;
        line-height: 27px; }

      &:nth-child(2) {
        width: 180px; } } } }

.thanks {
  &-container {
    margin-top: 30px;
    margin-bottom: 40px;
    padding: 35px 15px 15px;
    border: $border-metal; }
  &__img {
    float: left;
    width: 243px;
    margin-right: 50px;
    .img-bg {
      height: 182px; } }
  .text {
    width: 606px;
    max-width: 100%;
    float: left;
    margin-bottom: 25px;
    font-size: 16px; }
  &-links {
    width: 606px;
    max-width: 100%;
    float: left;
    margin-top: 30px;
    margin-bottom: 15px;
    a {
      display: inline-block;
      height: 40px;
      line-height: 38px;
      margin-right: 20px;
      min-width: 280px;
      text-align: center;
      border: 1px solid $green;
      border-radius: 3px;
      &:hover {
        text-decoration: none; }
      &:active {
        box-shadow: inset 4px 4px 2px 0 rgba(0,0,0,0.2); } } } }


.to-main {
  color: $green;
  &:hover {
    color: $green; } }
.to-blog {
  color: #fff;
  background-color: $green;
  &:hover {
    color: #fff; } }


@media screen and (max-width: 1199px) {
  .order {
    .col-lg-3.col-lg-push-9 {
      float: right;
      width: 300px; }
    .col-lg-9.col-lg-pull-3 {
      float: left;
      width: 724px; } }
  .order-form-right {
    width: 472px; }
  .legal-inputs label:nth-child(2n-1) {
    padding-right: 13px; }
  .legal-inputs label:nth-child(2n) {
    padding-left: 13px; }
  .order__choose {
    padding-left: 40px;
    padding-right: 40px; }
  .order-free {
    padding-bottom: 25px; }
  .order-total {
    &-left {
      width: 100%;
      margin-right: 0; }
    &-right {
      width: 100%;
      position: relative;
      .form-button {
        position: absolute;
        width: auto;
        padding-left: 73px;
        padding-right: 73px;
        top: 45px;
        right: 21px; } } }
  .cart-page {
    .col-lg-9 {
      width: 724px;
      float: left; }
    .col-lg-3 {
      width: 300px;
      float: left; } }
  .cart-card-middle {
    width: 356px; }
  .registration {
    &-form {
      width: 50%;
      .checkbox {
        padding-right: 35px; }
      .text {
        width: 536px; } }
    &-socials {
      width: 50%;
      padding-left: 30px;
      padding-right: 0; } }
  .registration-banner {
    padding-left: 666px;
    padding-top: 10px;
    padding-bottom: 20px;
    background-size: contain; } }


@media screen and (max-width: 1023px) {
  .thanks {
    .text {
      max-width: 100%;
      width: 411px;
      margin-top: 25px;
      &:nth-child(2) {
        width: 300px; } } }
  .thanks-links {
    width: 100%;
    text-align: center; }
  .order .col-lg-3.col-lg-push-9 {
    width: 100%; }
  .order .col-lg-9.col-lg-pull-3 {
    width: 100%; }
  .order-total-left .text {
    width: 100%; }
  .order-banner {
    height: 100px;
    .main_banner__logo {
      top: 30px;
      right: -3px;
      transform: scale(.7); } }
  .order-banner {
    .text {
      bottom: 17px;
      font-size: 19px;
      br {
        display: none; } }
    .caption {
      top: 20px;
      font-size: 34px;
      span {
        margin-left: 10px;
        font-size: 34px;
        display: inline; } } }
  .cart-page {
    .col-lg-9 {
      width: 475px; }
    .col-lg-3 {
      width: 290px; } }
  .cart-card {
    position: relative;
    padding-bottom: 75px;
    &-actions {
      position: absolute;
      width: 140px;
      left: 17px;
      top: 172px;
      .stars {
        display: inline;
        span {
          margin-left: 0; }
        i:last-of-type {
          margin-right: 35%; } } }
    &-middle {
      width: 247px; } }
  .cart__adiing {
    position: absolute;
    bottom: 30px;
    left: 179px; }


  .registration-form {
    padding-left: 14px;
    .checkbox {
      font-size: 14px;
      line-height: 18px; }
    .text {
      max-width: 100%; } }
  .registration-banner {
    margin-bottom: 26px;
    padding-left: 400px;
    background-size: cover; } }


@media all and (max-width: 767px) {
  .thanks {
    &__img {
      display: none; }
    h1 {
      text-align: center;
      margin-top: -15px;
      margin-bottom: 0; } }
  .thanks .text {
    margin-top: 0; }
  .thanks-container {
    margin-top: 10px;
    padding-top: 20px; }
  .thanks-links {
    margin-top: 0;
    a {
      margin-right: 0; }
    .to-main {
      margin-bottom: 10px; } }
  .payment {
    h1 {
      margin-bottom: 15px;
      margin-top: -15px;
      text-align: center; }
    &-container {
      padding: 15px;
      .caption {
        font-size: 17px; } } }
  .payment-table td {
    padding-left: 15px;
    padding-right: 15px;
    b {
      line-height: 23px; } }
  .order-banner {
    background-position: -200px center;
    .text {
      bottom: 27px;
      font-size: 12px; }
    .caption {
      bottom: auto;
      top: 25px;
      font-size: 22px;
      span {
        font-size: 22px; } } }
  .order-form {
    &-left {
      padding-left: 15px;
      width: 150px;
      .text {
        font-size: 14px;
        letter-spacing: 0; } }
    &-right {
      width: 288px;
      max-width: 100%;
      .checkbox {
        width: 450px;
        margin-left: -135px;
        max-width: 450px; } } }
  .legal-inputs label {
    width: 100%; }
  .legal-inputs label:nth-child(2n),
  .legal-inputs label:nth-child(2n-1) {
    padding-left: 50px;
    padding-right: 50px;
    font-size: 14px; }
  .order-where {
    &__item {
      padding-right: 0;
      padding-bottom: 66px;
      label {
        top: auto;
        bottom: 15px;
        right: -15px; } } }
  .order-free {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 65px;
    .return {
      bottom: 16px; }
    .text {
      font-size: 14px;
      &:nth-child(2) {
        font-size: 16px; } } }
  .order-total {
    &-left {
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 30px;
      .text {
        width: 304px; } }
    &-right {
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 66px;
      .form-button {
        top: auto;
        bottom: 20px; } } }
  h1 {
    font-size: 18px; }

  .cart-page {
    h1 {
      text-align: center;
      position: relative;
      top: -12px;
      margin-bottom: 0; }
    .col-lg-9 {
      margin-bottom: 30px;
      width: 100%; }
    .col-lg-3 {
      width: 100%; } }
  .cart__confirm {
    padding-bottom: 28px;
    .price {
      float: right; }
    .text {
      float: left;
      width: 66%;
      min-height: 50px; }
    .confirm {
      float: right; } }
  .registration-container {
    padding: 0;
    border: none;
    margin-bottom: 10px; }
  .registration-form {
    width: 100%;
    padding-left: 0;
    margin-bottom: 16px;
    &__bottom {
      margin-left: auto;
      margin-right: auto; }
    .text {
      padding-left: 30px;
      padding-right: 30px;
      font-size: 14px;
      line-height: 18px; }
    label {
      margin-left: auto;
      margin-right: auto;
      max-width: 100%; } }
  .registration-socials {
    width: 100%;
    padding-left: 0;
    height: auto;
    border-left: none;
    padding-top: 16px;
    border-top: 1px solid $light-gray; }
  .registration-form__checkboxes {
    width: 278px;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%; }
  .registration-banner {
    display: none; }
  .order {
    h1 {
      text-align: center;
      margin-bottom: 10px;
      margin-top: -15px; } } }


@media all and (max-width: 479px) {
  .thanks {
    h1 {
      margin-top: 0;
      margin-bottom: 5px; } }
  .thanks-links {
    a {
      width: 100%;
      min-width: 0; } }
  .payment {
    h1 {
      margin-top: 0;
      margin-bottom: 5px; }
    &-table td {
      font-size: 14px;
      padding: 0; } }
  .payment-container .form-button {
    width: 100%;
    padding: 0; }
  .payment-container .caption-16 .price {
    display: block;
    margin-left: 0; }
  .payment-container .text {
    font-size: 14px; }
  .order {
    h1 {
      margin-top: 0;
      margin-bottom: 5px; } }
  .order-banner .main_banner__logo {
    display: none; }
  .order-form-left {
    width: 100%;
    .text {
      margin-bottom: 40px; } }
  .order-form-right {
    position: relative;
    padding-left: 15px;
    top: 40px;
    margin-bottom: 40px;
    margin-left: -100%;
    width: 100%;
    .ui-selectmenu-button.ui-button {
      width: 260px; }
    .checkbox {
      margin-left: 0;
      padding-right: 15px;
      width: 100%; }
    label {
      margin-bottom: 40px;
      width: 260px; } }
  .legal-inputs label:nth-child(2n),
  .legal-inputs label:nth-child(2n-1) {
    padding-left: 0;
    padding-right: 0; }
  .order-where__item {
    padding-left: 0;
    padding-top: 15px;
    &::before {
      margin-bottom: 15px;
      position: static;
      margin-left: auto;
      margin-right: auto; } }
  .order-free {
    .return {
      width: calc(100% - 30px);
      padding-left: 0;
      padding-right: 0;
      right: 15px; } }
  .cart-page {
    h1 {
      position: static;
      margin-bottom: 5px; } }
  .registration-form .text {
    padding-left: 5px;
    padding-right: 5px; }
  .cart__adiing {
    left: 33px;
    bottom: 14px; }
  .cart-card {
    padding-bottom: 50px;
    .quantity-inputs {
      margin-bottom: 0; }
    &__img {
      float: none;
      margin-left: auto;
      margin-right: auto;
      padding-right: 0;
      margin-bottom: 5px; }
    &-middle {
      width: 100%;
      padding-right: 0;
      margin-bottom: 5px; }
    &-actions {
      position: static;
      width: 100%;
      margin: 0;
      .stars {
        display: inline-block;
        i:last-of-type {
          margin-right: 10px; } } }
    .caption-16 {
      margin-bottom: 5px; } }
  .cart-card-right {
    width: 100%;
    .text {
      float: left;
      margin-right: 10px; } }
  .cart__delete {
    font-size: 12px;
    position: absolute;
    right: 13px;
    bottom: 97px;
    width: 67px; } }
