.breadcrumbs {
  margin-bottom: 36px;
  font-size: 12px;
  box-shadow: 0 0 5px 0 rgba(0,0,0,.2);
  .list-inline {
    height: 40px;
    align-items: center;
    display: flex;
    margin: 0;
    li {
      margin-right: 4px;
      padding: 0;
      &.separator {
        margin-right: 7px;
        width: 10px;
        height: 10px;
        background: url(../img/arrow.png) center no-repeat; } }
    span {
      color: $gray; }
    a {
      color: #333; } } }


@media screen and (max-width: 1023px) {
  .breadcrumbs {
    margin-bottom: 24px; } }

@media screen and (max-width: 767px) {
  .breadcrumbs .list-inline {
    flex-wrap: wrap;
    height: auto; } }


@media screen and (max-width: 479px) {
  .breadcrumbs {
    margin-bottom: 5px; } }
