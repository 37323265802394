.instructions {
  h1 {
    margin-top: -10px;
    margin-bottom: 35px; }
  &-wrapper {
    margin-bottom: 40px;
    columns: 4;
    .caption-16 {
      font-size: 14px;
      margin-bottom: 7px; } }
  &__item {
    font-weight: 200;
    margin-bottom: 7px;
    a:hover {
      text-decoration: none;
      color: #333; } } }


.leave-comments__item {
  position: relative;
  .leave-stars {
    position: absolute;
    top: -7px; }
  .leave-star {
    width: 15px;
    background-size: 13px;
    cursor: default; } }

.card-adding {
  .home-promo__slider {
    max-height: none; } }

.leave-stars {
  height: 25px;
  margin-top: 10px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 10px; }

.leave-star {
  display: inline-block;
  width: 28px;
  height: 25px;
  vertical-align: middle;
  background: url(../img/star-lg-gray.svg) 0 0 no-repeat;
  background-size: 26px;
  cursor: pointer;
  float: left; }

.leave-stars {
  &-1 {
    .leave-star:first-child {
      background-image: url(../img/star-lg-red.svg); } }
  &-2 {
    .leave-star:first-child {
      background-image: url(../img/star-lg-red.svg); }
    .leave-star:nth-child(2) {
      background-image: url(../img/star-lg-red.svg); } }
  &-3 {
    .leave-star:first-child {
      background-image: url(../img/star-lg-red.svg); }
    .leave-star:nth-child(2) {
      background-image: url(../img/star-lg-red.svg); }
    .leave-star:nth-child(3) {
      background-image: url(../img/star-lg-red.svg); } }
  &-4 {
    .leave-star:not(:nth-child(5)) {
      background-image: url(../img/star-lg-red.svg); } } }


.leave-form .leave-stars:hover {
  .leave-star {
    background-image: url(../img/star-lg-red.svg);
    &:hover~.leave-star {
      background-image: url(../img/star-lg-gray.svg); } } }

.leave-stars-5 {
  .leave-star {
    background-image: url(../img/star-lg-red.svg); } }

.leave-hover {
  opacity: 0;
  position: absolute;
  left: 110%;
  top: 50%;
  width: 125px;
  height: 40px;
  margin-top: -17px;
  line-height: 40px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 0 7px 0 rgba(0,0,0,.3);
  transition-duration: .2s; }

.leave-form .stars-label~.form-button {
  margin-left: 27px; }

.leave-star:hover~.leave-hover {
  opacity: 1; }

.root {
  &-wrapper {
    margin-left: -30px;
    margin-bottom: 50px; }
  &__item {
    display: block;
    position: relative;
    float: left;
    margin-bottom: 30px;
    width: calc(33.33% - 30px);
    margin-left: 30px;
    border: $border-metal;
    padding-bottom: 44px;
    .img-bg {
      height: 200px; }
    .caption-16 {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      margin-bottom: 0;
      padding: 11px 17px;
      background-color: $turquoise;
      color: #fff;
      font-weight: 500;
      &:hover {
        color: $black; } } } }

.search {
  &-products {
    margin-left: -30px;
    .card-mini {
      position: relative;
      width: calc(25% - 30px);
      margin-left: 30px;
      margin-bottom: 30px;
      border-right: 1px solid $light-gray;
      float: left;
      .caption {
        margin-bottom: 15px; }
      .price {
        margin-bottom: 20px; }
      .add-favorites {
        position: absolute;
        top: 16px;
        right: 18px;
        &:hover {
          width: calc(100% - 36px); } }
      &__bottom {
        text-align: center;
        .buy {
          float: none; } } }
    &-container {
      margin-bottom: 50px; } } }

.search-publication-container {
  margin-bottom: 50px;
  .sales-item {
    width: calc(25% - 30px); } }

.catalog {
  &-container {
    margin-top: 44px; }
  h1 {
    height: 0;
    margin: 0;
    padding-left: 300px; }
  &__erase {
    display: inline-block;
    text-transform: uppercase;
    text-decoration: underline;
    color: $gray;
    &:hover {
      text-decoration: none;
      color: $gray; } }
  &-range {
    margin-top: 4px;
    margin-bottom: 13px;
    padding: 13px 16px;
    background-color: $bg-light-gray;
    border: 1px solid #e4e6ed;
    border-radius: 4px;
    .text {
      margin-bottom: 15px;
      font-size: 12px; }
    .form-button {
      height: 40px;
      line-height: 39px;
      width: auto;
      padding-left: 50px;
      padding-right: 50px;
      margin-bottom: 17px;
      &:hover {
        background-color: transparent; } } } }

.erase {
  display: inline-block;
  width: 13px;
  margin-left: 15px;
  height: 13px;
  background: url(../img/erase.png) center no-repeat; }


.caption-16 {
  display: block;
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: bold; }


.price {
  &-filter {
    display: block;
    margin-bottom: 19px;
    input {
      width: 90px;
      text-align: center;
      border-color: $light-gray;
      border-radius: 3px; } }
  &-inputs {
    display: block;
    margin-bottom: 21px; }
  &-separator {
    display: inline-block;
    width: 29px;
    height: 1px;
    background-color: $black; }
  &-range {
    display: block;
    position: relative;
    height: 5px;
    border: none;
    background-color: $gray;
    border-radius: 2.5px;
    cursor: pointer; }
  &-range-between {
    background-color: $turquoise; } }

.ui-slider-handle.price-range-start {
  width: 22px;
  height: 22px;
  top: -8px;
  border: none;
  background-color: #fff;
  border-radius: 50%;
  cursor: url(../img/grab.cur) 1 1, pointer;
  box-shadow: 0 3px 7px 0 rgba(0,0,0,.35);
  &.ui-state-active {
    height: 22px;
    padding: 0;
    cursor: url(../img/grabbing.cur) 1 1, pointer; } }

.ui-widget.ui-widget-content {
  border: none; }

.range-label {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.catalog {
  &-nav {
    border: 1px solid #e4e6ed;
    border-radius: 5px;
    overflow: hidden;
    .caption-16 {
      margin-bottom: 0;
      padding-left: 16px;
      padding-top: 14px;
      padding-bottom: 14px;
      padding-right: 50px;
      font-weight: normal;
      line-height: 18px;
      background-color: $bg-light-gray;
      border-bottom: 1px solid #e4e6ed;
      position: relative;
      a {
        color: $black;
        &:hover {
          text-decoration: none;
          color: $turquoise; } }
      .switcher {
        position: absolute;
        height: 50px;
        right: 0;
        top: 50%;
        width: auto;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: -25px;
        line-height: 50px;
        padding-left: 18px;
        padding-right: 18px;
        float: right;
        cursor: pointer;
        display: inline-block; }
      .caret {
        display: inline-block;
        vertical-align: middle;
        width: 0;
        padding: 0;
        border-bottom: none;
        border-top: 11px solid $gray;
        border-right: 6px solid transparent;
        border-left: 6px solid transparent;
        transition-duration: .1s; }
      &.active {
        .caret {
          transform: scale(1,-1); } } } } }

.ui-selectmenu-menu.ui-front.ui-selectmenu-open {
  border: 1px solid $light-gray; }

.catalog {
  &-nav {
    &__list {
      height: 0;
      overflow: hidden;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
      transition-duration: .2s;
      li {
        padding: 7px 16px;
        a {
          color: $black;
          &:hover {
            color: $turquoise;
            text-decoration: none; } } } } } }

.active+.catalog-nav__list {
  height: auto;
  padding-top: 7px;
  padding-bottom: 7px;
  &:not(:last-child) {
    border-bottom: 1px solid #e4e6ed; } }

.catalog {
  &-choose {
    margin-bottom: 17px;
    padding: 10px 19px;
    background-color: $bg-light-gray;
    border-radius: 3px;
    border: 1px solid #e4e6ed;
    .caption-16 {
      margin-bottom: 13px;
      width: 100%; }
    .check {
      height: 24px;
      width: 24px;
      top: -3px;
      background-color: #fff;
      border-radius: 3px;
      border-color: $light-gray; }
    .text {
      font-size: 15px; }
    .checkbox {
      min-height: 38px;
      width: 33.33%;
      float: left;
      padding-left: 36px; }
    input:checked+.check {
      background: url(../img/check-white.png) center no-repeat;
      background-color: $turquoise;
      border-color: $turquoise;
      &::before {
        display: none; } }
    .checkbox+.checkbox {
      margin-top: 10px; } } }

.catalog {
  &-actions {
    margin-bottom: 17px;
    .text-right {
      float: right; } }
  &__sort {
    display: inline-block;
    width: 225px;
    vertical-align: middle;
    margin-left: 26px; }
  &__quantity {
    display: inline-block;
    width: 78px;
    margin-left: 19px;
    .ui-selectmenu-button.ui-button {
      background-color: $bg-light-gray;
      border-color: #e4e6ed; } } }

.catalog {
  &-wrapper {
    margin-left: -30px;
    .card-mini {
      float: left;
      width: calc(33.33% - 30px);
      margin-bottom: 28px;
      margin-left: 30px;
      border-right: 1px solid $light-gray;
      transition-duration: .3s;
      &:hover {
        box-shadow: 0 0 5px 0 rgba(0,0,0,.2); } }
    .price {
      margin-bottom: 15px;
      color: $black; } } }

.content_block_ajax {
  position: relative; }

.fa-heart-o.break::before {
  background-image: url(../img/dislike.svg);
  background-size: contain;
  vertical-align: middle;
  height: 23px;
  width: 23px;
  display: inline-block;
  background-repeat: no-repeat;
  content: '';
  background-position: center; }



.card-mini__bottom.in-cart {
  a {
    display: inline-block;
    height: 40px;
    //padding-left: 15px
    //padding-right: 15px
    line-height: 38px;
    //border: 1px solid $turquoise
    border-radius: 4px;
    &:hover {
      text-decoration: none;
      color: #fff;
      background-color: $turquoise;
      span {
        color: #fff; } } } }

.paginator {
  height: 40px;
  padding-left: 5px;
  padding-right: 25px;
  margin-bottom: 29px;
  line-height: 40px;
  box-shadow: 0 0 3px 0 rgba(0,0,0,.3);
  border-radius: 4px;
  .fa {
    position: relative;
    top: 3px;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 24px; }
  &__prev {
    display: inline-block;
    color: $black;
    text-decoration: underline;
    text-transform: uppercase;
    &:hover {
      color: $black; } }
  &__list {
    margin-bottom: 0;
    margin-left: 40px;
    margin-right: 40px;
    display: inline-block;
    vertical-align: middle;
    span {
      padding-left: 3px;
      padding-right: 3px; }
    a {
      color: $black;
      display: inline-block;
      padding-left: 3px;
      padding-right: 3px;
      &:hover {
        text-decoration: underline; } } }
  &__next {
    text-transform: uppercase;
    text-decoration: underline; }
  .text-right {
    float: right;
    span {
      margin-left: 3px;
      margin-right: 3px; } }
  &__current {
    color: $turquoise; } }

.catalog {
  &-interes {
    margin-bottom: 25px;
    padding: 15px 0 0 20px;
    background-color: $bg-light-gray;
    border: $border-metal;
    .caption {
      font-size: 22px;
      margin-bottom: 15px; }
    &__link {
      display: block;
      float: left;
      width: calc(16.66% - 28px);
      margin-right: 28px;
      margin-bottom: 15px;
      padding: 15px 0;
      text-align: center;
      background-color: #fff;
      border: $border-metal;
      .img-bg {
        width: 70px;
        height: 70px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 9px;
        border: 1px solid #edeef3;
        background-size: contain; }
      &:hover {
        text-decoration: none;
        color: $black; } } } }

.catalog-list {
  margin-left: -30px;
  margin-bottom: 40px;
  &__item {
    float: left;
    margin-left: 30px;
    width: calc(33.33% - 30px);
    .caption {
      text-align: center;
      margin-bottom: 5px;
      a {
        font-size: 22px;
        color: #333;
        letter-spacing: 0;
        text-decoration: underline;
        &:hover {
          color: $turquoise;
          text-decoration: none; } } } }
  &__link {
    display: block;
    display: flex;
    align-items: center;
    margin-right: -6px;
    color: $black;
    line-height: 18px;
    &:hover {
      text-decoration: none;
      color: $turquoise; }
    &:not(:last-child) {
      margin-bottom: 20px; }
    .img-bg {
      flex-shrink: 0;
      width: 70px;
      height: 70px;
      display: inline-block;
      margin-right: 16px;
      background-size: contain; } }
  &-wrapper {
    padding: 20px;
    border: $border-metal; } }

.catalog-posts {
  &-slider {
    margin-bottom: 65px;
    padding-left: 35px;
    padding-right: 35px;
    border-radius: 5px;
    box-shadow: 0 0 3px 0 rgba(0,0,0,.2);
    .slick-list {
      border-right: $border-metal;
      border-left: $border-metal;
      overflow-y: visible;
      overflow-x: hidden; }
    .slick-dots {
      position: absolute;
      width: 100%;
      top: 100%;
      left: 0; } }
  &__link {
    display: block;
    padding: 10px 10px 14px;
    font-size: 16px;
    line-height: 18px;
    color: $black;
    font-weight: bold;
    .img-bg {
      height: 175px;
      margin-bottom: 15px;
      background-size: cover;
      border: $border-metal; }
    &:hover {
      box-shadow: 0 0 5px 0 rgba(0,0,0,0.35);
      text-decoration: none;
      color: $turquoise; } } }

.catalog-description {
  margin-bottom: 45px;
  p {
    color: $text-gray; } }

.card {
  h1 {
    margin-bottom: 10px; }
  &-top {
    a {
      color: $gray;
      &:hover {
        text-decoration: none; } }
    .stars {
      float: left;
      margin-right: 10px;
      span {
        margin-left: 10px;
        color: $gray; } } }
  &-slider {
    padding: 38px 46px 40px 15px;
    border: 1px solid $light-gray;
    &__main {
      margin-right: 40px;
      width: 610px;
      float: left;
      .img-bg {
        height: 434px;
        background-size: auto;
        border: 1px solid $light-gray; } }
    &-thumbs {
      position: relative;
      height: 434px;
      float: left;
      width: 150px;
      &__item {
        margin-bottom: 9px;
        .img-bg {
          height: 100px;
          border: 1px solid $light-gray;
          background-size: contain; }
        &.slick-current {
          .img-bg {
            border: 2px solid $turquoise; } } } } } }

.arrow-vertical {
  background-color: transparent;
  border: none;
  color: $gray;
  opacity: 1;
  transform: translate(-50%);
  &:hover {
    color: $turquoise; }
  &:first-child {
    top: -18px;
    left: 50%; }
  &:nth-child(3) {
    bottom: -44px;
    top: auto;
    left: 50%; } }

.card-right {
  position: relative;
  height: 514px;
  margin-bottom: 30px;
  padding: 25px;
  background-color: $bg-light-gray;
  border: $border-metal;
  .add-favorites {
    float: right;
    height: 64px;
    span {
      top: 12px; } }
  .clearfix {
    margin-bottom: 5px; }
  .text {
    color: $gray;
    margin-bottom: -3px; }
  .price {
    margin-bottom: -5px;
    font-size: 30px;
    color: $black; }
  .green {
    margin-bottom: 5px; }
  .buy {
    padding-right: 30px; } }

.card-options {
  margin-right: -15px;
  margin-bottom: 6px;
  li {
    margin-bottom: 10px;
    position: relative;
    padding-left: 27px;
    font-size: 13px;
    color: $turquoise;
    &:first-child {
      img {
        width: 21px;
        margin-left: -10px; } }
    &:nth-child(2) {
      img {
        width: 11px;
        margin-left: -5px;
        margin-top: -8px; } }
    img {
      position: absolute;
      top: 50%;
      left: 10px;
      //transform: translate(-50%, -50%)
      width: 16px;
      margin-left: -8px;
      margin-top: -9px;
      height: auto; }
    span {
      color: $black; } } }
.card__brand {
  width: 147px;
  display: inline-block;
  height: 64px;
  background-color: #fff;
  border-radius: 5px;
  border: $border-metal;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain; }

.color {
  margin-bottom: 15px;
  .text {
    margin-bottom: 10px;
    color: $black;
    font-size: 13px; }
  &__item {
    display: inline-block;
    height: 24px;
    width: 24px;
    vertical-align: middle;
    border-radius: 3px;
    cursor: pointer;
    margin-right: 3px;
    &.white {
      border: 1px solid $light-gray; } } }

input:checked+.color__item {
  border: 3px solid $turquoise; }

.card {
  &-popup {
    display: none;
    position: absolute;
    bottom: 32px;
    left: 0;
    padding: 33px 20px 15px;
    background-color: #fff;
    box-shadow: 0 0 7px 0 rgba(0,0,0,0.2);
    &__close {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 0;
      right: 0;
      background: url(../img/close.png) center no-repeat;
      cursor: pointer; }
    .text {
      padding-left: 36px;
      position: relative;
      margin-bottom: 20px; }
    .green-check {
      position: absolute;
      top: 3px;
      left: 0; }
    .buy {
      margin-bottom: 10px;
      padding-right: 57px; } } }

.green-check {
  display: inline-block;
  width: 21px;
  height: 21px;
  border-radius: 3px;
  background: url(../img/check-white.png) center no-repeat;
  background-color: $green; }

.return {
  display: inline-block;
  width: 100%;
  line-height: 40px;
  border-radius: 4px;
  text-align: center;
  color: $black;
  background-color: $light-gray;
  &:hover {
    text-decoration: none;
    color: $black;
    background-color: #d4d4d4; } }

.card {
  &-content {
    margin-bottom: 37px; }
  &-tabs {
    &__item {
      display: inline-block;
      float: left;
      position: relative;
      line-height: 50px;
      margin-right: 24px;
      border: $border-metal;
      border-bottom: none;
      padding-left: 28px;
      padding-right: 28px;
      border-radius: 5px 5px 0 0;
      transition-duration: .2s;
      overflow: hidden;
      font-size: 16px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      &+.card-tabs-content {
        display: none; }
      &.active {
        padding-left: 55px;
        padding-right: 55px;
        background-color: $light-gray; }
      &::after {
        position: absolute;
        top: 100%;
        left: -10%;
        display: block;
        content: '';
        width: 120%;
        height: 100%;
        box-shadow: 0 0 10px 0 rgba(0,0,0,0.5); } }
    &-container {
      padding: 27px 18px;
      border: $border-metal;
      iframe {
        float: left;
        margin-right: 20px;
        width: 353px;
        height: 195px; }
      p {
        font-size: 18px;
        line-height: 30px;
        letter-spacing: 0;
        &:last-child {
          margin-bottom: 0; } } } } }

.catalog-posts-slider_card {
  position: relative;
  padding-left: 53px;
  padding-right: 53px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 5px 0 rgba(0,0,0,.35);
  .slick-list {
    border: $border-metal; }
  .slick-dots {
    left: 0;
    top: 100%;
    width: 100%;
    position: absolute; } }

.card {
  &-interes {
    padding-bottom: 74px;
    padding-top: 30px;
    background-color: $light-gray;
    .caption {
      margin-bottom: 20px; } }
  .catalog-list {
    margin-bottom: 36px; }
  &-adding {
    .caption {
      margin-bottom: 19px;
      font-size: 24px;
      float: left; }
    .card-mini {
      .caption {
        font-size: 16px; } } } }

a.card-mini__link {
  display: inline-block;
  line-height: 38px;
  padding-left: 40px;
  padding-right: 40px;
  border: 1px solid $turquoise;
  border-radius: 4px;
  &:hover {
    text-decoration: none; } }

@media screen and (max-width: 1199px) {
  .leave-form .stars-label {
    width: 235px; }
  .root {
    &-wrapper {
      margin-left: -25px; }
    &__item {
      width: calc(33.33% - 25px);
      margin-left: 25px;
      margin-bottom: 25px;
      .img-bg {
        height: 170px;
        background-size: contain; } } }
  .search-products {
    margin-left: -93px;
    .card-mini {
      width: calc(33.33% - 93px);
      margin-left: 93px;
      &:nth-child(7) {
        display: none; }
      &:nth-child(8) {
        display: none; } } }
  .search {
    .publications-wrapper .sales-item {
      &:nth-child(7) {
        display: none; }
      &:nth-child(8) {
        display: none; } } }
  .search-products-container {
    margin-bottom: 35px; }
  .search-publication-container {
    margin-bottom: 35px; }
  .card {
    .col-lg-9 {
      width: 724px;
      float: left; }
    .col-lg-3 {
      width: 300px;
      float: left; }
    &-slider {
      padding: 17px 20px 38px 17px;
      &-thumbs {
        margin-top: 20px; }
      &__main {
        width: 483px;
        margin-right: 18px;
        .img-bg {
          width: 483px;
          height: 340px;
          background-size: contain; } } } }

  .catalog-root {
    .catalog-range {
      display: none; } }
  .catalog-nav {
    display: none; }
  .catalog-range {
    padding-top: 17px;
    padding-bottom: 17px;
    &:after {
      display: table;
      content: '';
      clear: both; }
    .caption-16 {
      float: left;
      margin-right: 15px;
      margin-bottom: 0;
      position: relative;
      top: 9px; }
    .price-filter {
      float: left;
      margin-bottom: 0; }
    form {
      float: left; }
    .range-label {
      width: 220px;
      position: relative;
      top: 3px; }
    .price-separator {
      width: 18px; }
    .text {
      position: relative;
      top: 11px;
      float: left; }
    .price-inputs {
      float: left;
      margin-right: 27px;
      margin-bottom: 0; }
    .price-range {
      position: relative;
      top: 18px;
      float: left;
      width: 220px;
      margin-right: 27px; }
    .text-center {
      float: left;
      width: 290px;
      display: flex;
      justify-content: space-around;
      align-items: center; }
    .form-button {
      margin-bottom: 0; } }
  .catalog {
    &-container {
      margin-top: 15px; }
    h1 {
      height: auto;
      margin-bottom: 25px;
      padding-left: 0; } }
  .catalog-wrapper {
    .card-mini {
      width: calc(25% - 30px); } }
  .catalog-posts-slider {
    padding-left: 95px;
    padding-right: 95px; }
  .card-popup .buy {
    width: 100%; } }


@media screen and (max-width: 1023px) {
  .instructions-wrapper {
    columns: 3; }
  .root {
    h1 {
      text-align: center;
      margin-bottom: 30px; }
    &-wrapper {
      margin-left: -20px; }
    &__item {
      width: calc(33.33% - 20px);
      margin-left: 20px;
      margin-bottom: 20px;
      .caption-16 {
        font-size: 14px; }
      .img-bg {
        height: 170px;
        background-size: contain; } } }
  .search {
    h1 {
      text-align: center; }
    h2 {
      text-align: center; } }
  .search-products {
    margin-left: 0;
    .card-mini {
      width: calc(33.33% + 1px);
      margin-left: 0;
      margin-right: -1px; } }
  .search .publications-wrapper .sales-item {
    &:nth-child(8) {
      display: block; }
    &:nth-child(7) {
      display: block; } }
  .card {
    h1 {
      font-size: 22px; }
    .col-lg-9 {
      width: 468px; }
    .col-lg-3 {
      width: 300px;
      padding-left: 0; } }
  .card-slider__main {
    width: 100%;
    margin-right: 0; }
  .card-slider-thumbs {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    height: 58px;
    .slick-arrow:first-child {
      left: 6px; }
    .slick-arrow:nth-child(3) {
      right: 7px; }
    .img-bg {
      height: 58px;
      margin-right: 6px; } }
  .catalog {
    h1 {
      font-size: 24px;
      text-align: center; } }
  .price-filter input {
    width: 65px;
    font-size: 12px;
    padding-left: 9px;
    padding-right: 9px; }
  .catalog-range {
    padding-top: 14px;
    padding-bottom: 14px;
    .price-inputs {
      margin-right: 16px; }
    .price-range {
      width: 111px; }
    .form-button {
      height: 30px;
      line-height: 28px;
      padding-left: 35px;
      padding-right: 35px;
      font-size: 12px; }
    .catalog__erase {
      font-size: 12px; }
    .text-center {
      width: 230px;
      position: relative;
      top: 5px; }
    .range-label {
      width: 163px; }
    .caption-16 {
      margin-right: 5px;
      font-size: 12px; } }
  .catalog-wrapper {
    margin-left: 0;
    .card-mini {
      width: calc(33.33% + 1px);
      margin-left: 0;
      margin-right: -1px; } }
  .paginator__list {
    margin-left: 30px;
    margin-right: 30px; }
  .catalog-interes__link {
    width: calc(20% - 28px); }
  .catalog-list {
    display: none; }
  .catalog-posts-slider {
    padding-left: 32px;
    padding-right: 32px; } }


@media screen and (max-width: 767px) {
  .instructions {
    h1 {
      text-align: center;
      margin-bottom: 15px; }
    &-wrapper {
      columns: 2; } }
  .root {
    h1 {
      margin-top: -15px;
      margin-bottom: 10px; }
    &-wrapper {
      margin-left: -14px; }
    &__item {
      width: calc(50% - 14px);
      margin-left: 14px;
      margin-bottom: 14px;
      .caption-16 {
        text-align: center;
        min-height: 62px;
        display: flex;
        align-items: center;
        justify-content: center; }
      .img-bg {
        height: 170px;
        background-size: contain; } } }
  h2 {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px; }
  .search-products {
    margin-left: 0;
    .card-mini {
      width: calc(50% + 1px); } }
  .catalog-posts-slider_card {
    padding-left: 30px;
    padding-right: 30px;
    .slick-arrow:first-child {
      left: 15px; }
    .slick-arrow:nth-child(3) {
      right: 15px; } }
  a.card-mini__link {
    padding-left: 32px;
    padding-right: 32px; }
  .card {
    h1 {
      font-size: 18px; }
    .col-lg-9 {
      width: 100%; }
    .col-lg-3 {
      width: 100%;
      padding-left: 15px; } }
  .card-slider {
    margin-bottom: 20px;
    padding: 13px; }
  .card-right {
    height: auto;
    .add-favorites {
      width: 217px; }
    .color {
      position: absolute;
      right: 30px;
      top: 99px; } }
  .card-popup {
    margin-left: -135px;
    left: 50%;
    width: 270px; }
  .card-tabs__item {
    float: none;
    width: 100%;
    margin-bottom: 9px;
    &.active {
      &+.card-tabs-content {
        padding: 22px;
        border: $border-metal;
        display: block;
        background-color: #fff;
        margin-bottom: 20px;
        margin-top: -20px;
        iframe {
          display: block;
          width: 351px;
          margin-left: auto;
          margin-right: auto;
          float: none;
          margin-bottom: 10px;
          height: 196px; } } } }
  .card-tabs-container {
    display: none; }
  .catalog {
    h1 {
      font-size: 18px;
      margin-bottom: 5px; }
    &-range {
      padding-bottom: 65px;
      form {
        width: 365px;
        position: relative; }
      .price-filter {
        float: none;
        display: block; }
      .range-label {
        width: 206px; }
      .price-range {
        float: none;
        width: 206px;
        margin-top: 20px; }
      .price-inputs {
        float: none; }
      .caption-16 {
        margin-right: 15px; }
      .text {
        position: absolute;
        top: 11px;
        right: 3px;
        &-center {
          position: absolute;
          bottom: -40px;
          top: auto;
          right: 12px;
          flex-wrap: wrap;
          width: 100px; } }
      .form-button {
        margin-bottom: 10px; } } }

  .catalog-choose {
    .columns {
      columns: 1; }
    .text {
      font-size: 14px; }
    .checkbox {
      min-height: 30px;
      width: 50%; } }

  .catalog {
    &-actions {
      .text {
        display: block;
        margin-bottom: 3px; }
      .text-right {
        .text {
          float: left;
          line-height: 34px; } } }
    &__sort {
      margin-left: 0;
      width: 194px; } }

  .catalog-wrapper {
    .card-mini {
      width: 50%;
      margin-bottom: 26px; } }

  .paginator {
    padding-right: 0;
    .text-right {
      display: none; }
    &__list {
      a {
        padding-left: 0;
        padding-right: 0; } }
    &__next {
      float: right; } }

  .catalog-interes__link {
    width: calc(33.33% - 25px);
    margin-right: 25px; }
  .catalog-posts-slider {
    .img-bg {
      height: 128px;
      margin-bottom: 8px; } }
  .catalog-description {
    text-align: center;
    h2 {
      font-size: 18px; } } }


@media all and (max-width: 479px) {
  .instructions {
    h1 {
      margin-top: 0;
      margin-bottom: 10px; }
    &-wrapper {
      columns: 1; } }
  .card-adding .caption {
    float: none; }
  .root {
    h1 {
      margin-top: 0; }
    &__item {
      width: 100%; } }
  .search-products {
    margin-left: 0;
    .card-mini {
      width: 100%;
      margin-right: 0; } }
  .card__brand {
    width: 100%; }
  .card-tabs__item.active + .card-tabs-content iframe {
    width: 100%;
    height: 139px; }
  .card-slider__main {
    .img-bg {
      height: 225px; } }
  .card-right {
    padding-top: 99px;
    .color {
      position: static; }
    .add-favorites {
      width: 238px;
      position: absolute;
      left: 25px;
      top: 25px; } }
  .catalog {
    h1 {
      margin-bottom: 0;
      margin-top: 10px; } }
  .catalog-range {
    padding-bottom: 15px;
    form {
      width: 210px; }
    .text {
      position: static;
      text-align: center;
      width: 100%;
      margin-top: 40px;
      &-center {
        position: static;
        width: 200px;
        margin-left: auto;
        margin-right: auto; } } }
  .catalog-wrapper .card-mini {
    width: 100%; }
  .catalog-interes__link {
    width: calc(50% - 15px);
    margin-right: 15px; }
  .catalog-choose .checkbox {
    width: 100%; }
  .catalog-actions {
    .text-right {
      float: none;
      margin-top: 15px; } }
  .paginator {
    &__prev {
      font-size: 0; }
    &__next {
      font-size: 0;
      position: relative;
      top: 2px;
      right: 5px; }
    &__list {
      margin-right: 0;
      margin-left: 10px; } } }
