.instructions {
  &-nav {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    li {
      display: inline-block;
      flex-grow: 1;
      width: 15px;
      margin-bottom: 15px;
      a {
        font-size: 18px;
        text-transform: uppercase; } } }
  &-item {
    font-size: 16px;
    table {
      width: 100%;
      border-collapse: collapse; }
    thead {
      font-weight: bold;
      background-color: $light-gray;
      td {
        padding-bottom: 20px; } }
    td {
      padding: 5px;
      vertical-align: top;
      &:first-child {
        width: 200px; }
      &:nth-child(2) {
        width: 500px; }
      &:nth-child(3) {
        a {
          display: block; } } }
    tbody {
      tr:nth-child(2n) {
        background-color: $bg-light-gray; } } }
  .caption {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    a {
      font-weight: normal;
      font-size: 16px;
      text-transform: none;
      .fa {
        margin-right: 10px;
        margin-left: 10px; } } } }
.about-shops {
  &>.caption-16 {
    display: none; }
  .catalog-nav {
    margin-top: 13px; } }
.lk-message {
  &__nav {
    margin-top: 30px;
    border: $border-metal;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 6px;
    ul {
      margin: 0; }
    li {
      font-size: 16px;
      line-height: 18px;
      vertical-align: middle;
      .gray {
        font-size: 12px;
        margin-left: 5px; }
      &>span {
        display: inline-block;
        padding-left: 10px;
        padding-right: 10px; }
      &:not(:last-child) {
        border-right: $border-metal; }
      a {
        display: inline-block;
        padding-left: 10px;
        padding-right: 10px;
        color: $black;
        &:hover {
          color: $turquoise;
          text-decoration: none; } } } } }

.lk-message {
  margin-bottom: 30px;
  &__tr {
    border: $border-metal;
    border-top: none;
    &:not(:nth-child(2)) {
      border-bottom-width: 2px; } }
  &__th {
    float: left;
    line-height: 44px;
    background-color: #5ccbf5;
    border-right: 1px solid #49b5de;
    font-weight: bold;
    font-size: 15px;
    &:nth-child(1) {
      width: 140px; }
    &:nth-child(2) {
      width: 623px; }
    &:nth-child(3) {
      width: 105px;
      border-right: none; } }
  &__td {
    float: left;
    border-right: $border-metal;
    padding: 15px 25px;
    p {
      font-size: 16px;
      font-weight: 200;
      line-height: 23px;
      &:last-child {
        margin-bottom: 0; } }
    &:nth-child(1) {
      font-size: 16px;
      font-weight: 200;
      line-height: 23px;
      color: #a0a0a0;
      width: 140px;
      text-align: center;
      border-left: $border-metal; }
    &:nth-child(2) {
      position: relative;
      width: 623px;
      cursor: pointer;
      max-height: 213px;
      overflow: hidden;
      &::after {
        position: absolute;
        content: '';
        display: block;
        width: 100%;
        height: 40px;
        left: 0;
        bottom: 0;
        background: linear-gradient(to bottom,transparent, #fff);
        transition-duration: .2s; }
      &.active {
        max-height: none;
        &::after {
          height: 0; } } }
    &:nth-child(3) {
      font-size: 16px;
      font-weight: 200;
      line-height: 23px;
      color: #a0a0a0;
      width: 105px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: center; } } }

.lk-favorites {
  margin-left: -4px;
  margin-top: 18px;
  margin-bottom: 6px;
  .card-mini {
    float: left;
    width: calc(33.33% - 34px);
    margin-left: 34px;
    margin-bottom: 34px;
    border-right: 1px solid $light-gray;
    .fa-heart-o {
      &::before {
        background-image: url(../img/dislike.svg);
        background-size: contain;
        vertical-align: middle;
        height: 23px;
        width: 23px;
        display: inline-block;
        background-repeat: no-repeat;
        content: '';
        background-position: center; } }
    .add-favorites span {
      width: 165px; } } }
.lk {
  &-tabs-wrapper {
    margin-bottom: 40px; }
  &-order {
    margin-bottom: 35px;
    border: $border-metal;
    border-width: 2px;
    &-top {
      margin: -2px -1px 0;
      padding: 17px 33px;
      border-bottom: $border-metal;
      background-color: #5ccbf5;
      .text {
        font-size: 15px;
        &:not(:last-child) {
          margin-bottom: 5px; }
        b {
          display: inline-block;
          width: 125px; } } } }
  &-orders-item {
    position: relative;
    padding: 36px 33px;
    border-bottom: $border-metal;
    .lk__leave-comment {
      position: absolute;
      top: 33px;
      right: 33px; }
    a:hover {
      text-decoration: none;
      color: $black; }
    .stars {
      margin-right: 10px;
      i {
        margin-right: 2px; }
      span {
        margin-left: 10px;
        color: $black; } }
    &__bottom {
      position: absolute;
      bottom: 32px;
      left: 310px;
      right: 33px;
      .color__item {
        margin-right: 10px;
        border-radius: 0;
        cursor: default;
        border: $border-metal; }
      .price {
        color: $black;
        margin-left: 10px; } }
    &__color {
      float: left; }
    .img-bg {
      float: left;
      height: 187px;
      width: 250px;
      margin-right: 27px;
      background-size: contain;
      border: $border-metal; }
    .caption-16 {
      display: inline-block;
      margin-top: -7px;
      margin-bottom: 30px;
      font-weight: normal;
      letter-spacing: 0;
      color: $black;
      span {
        font-weight: bold;
        font-size: 20px; }
      &:hover {
        text-decoration: none;
        color: $turquoise; } } } }

.lk-order-bottom {
  padding: 20px;
  border-top: $border-metal;
  font-weight: bold;
  font-size: 16px;
  .price {
    font-size: 24px;
    color: $black;
    margin-left: 20px; } }

.lk__leave-comment {
  font-size: 12px; }

.lk {
  &-tabs {
    border: $border-metal;
    &__item {
      float: left;
      width: 33.33%;
      line-height: 45px;
      text-align: center;
      font-size: 16px;
      cursor: pointer;
      transition-duration: .2s;
      &:not(:last-child) {
        border-right: $border-metal; }
      &.active {
        background-color: #f4f4f4; }
      &:hover {
        background-color: $turquoise;
        color: #fff; } } } }

.lk {
  h1 {
    margin-bottom: 35px;
    margin-top: -10px; }
  .about__banner {
    margin-top: 0; }
  &-info {
    margin-bottom: 37px;
    padding: 20px 38px;
    border: $border-metal;
    a:hover {
      text-decoration: none;
      color: $black; }
    h2 {
      margin-top: 0;
      margin-bottom: 40px; } }
  &-inputs {
    label {
      display: block;
      margin-bottom: 30px;
      line-height: 34px;
      cursor: pointer;
      font-size: 12px;
      input {
        width: 279px;
        margin-right: 12px;
        float: left;
        border-color: #afafaf;
        font-size: 13px; } } }
  &__icon {
    display: inline-block;
    width: 40px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    float: left;
    margin-right: -3px;
    position: relative;
    z-index: 10;
    border-radius: 3px 0 0 3px;
    background-color: #afafaf;
    &::before {
      position: relative;
      top: -1px;
      display: inline-block;
      content: '';
      width: 24px;
      height: 24px;
      vertical-align: middle;
      background: url(../img/lk-sprites.png); } }
  &__mail::before {
    background-position: 0 -26px; }
  &__phone::before {
    background-position: 0 -52px; }
  &__change {
    float: left;
    margin-right: 10px;
    line-height: 34px;
    display: inline-block;
    color: $turquoise;
    transition-duration: .2s;
    &:hover {
      color: $black; } }
  &__save {
    float: left;
    line-height: 34px;
    display: none;
    height: 34px;
    &:hover {
      color: $black;
      text-decoration: none; }
    &::before {
      display: inline-block;
      content: url(../img/lk-arrow.png);
      vertical-align: middle;
      position: relative;
      top: -2px;
      margin-right: 8px; } } }

.lk {
  &-passwords {
    display: none;
    margin-top: 30px;
    label {
      display: block;
      margin-bottom: 15px;
      line-height: 34px;
      input {
        float: right;
        width: 315px;
        max-width: 100%;
        margin-right: 200px;
        border-color: #afafaf; } }
    .form-button {
      margin-right: 200px;
      width: auto;
      padding-left: 50px;
      padding-right: 50px; } } }

input:focus~.lk__save {
  display: inline-block; }

label:hover .lk__save {
  display: inline-block; }

input:focus~.lk__change {
  color: $black; }

a.caption-16 {
  color: #333;
  &:hover {
    color: $turquoise;
    text-decoration: none; } }

.faq {
  margin-bottom: 20px;
  h1 {
    float: left;
    margin-right: 45px; }
  .ui-selectmenu-button.ui-button {
    float: left;
    width: 475px; }
  p {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 30px; }
  &-wrapper {
    margin-top: 38px;
    padding-bottom: 0;
    margin-bottom: 40px; }
  &-question {
    position: relative;
    padding: 30px 37px;
    border: 1px solid $light-gray;
    border-radius: 6px;
    margin-bottom: 24px;
    height: 122px;
    overflow: hidden;
    cursor: pointer;
    &::before {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      content: '';
      display: block;
      width: 4.5px;
      background-color: $turquoise;
      border-radius: 5px 0 0 5px; }
    &::after {
      display: block;
      content: '';
      position: absolute;
      width: 100%;
      left: 7px;
      height: 60px;
      top: 62px;
      background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 90%); } }
  &-answer {
    @extend .faq-question;
    height: auto;
    margin-left: 36px;
    margin-bottom: 40px;
    cursor: default;
    &::before {
      background-color: $bg-light-gray; }
    &::after {
      display: none; } } }

.active {
  .faq-question {
    height: auto;
    &::after {
      height: 0; } } }

.where-tabs__item {
  @extend .card-tabs__item;
  min-width: 200px;
  padding: 0 10px;
  text-align: center;
  &.active {
    padding: 0 10px; } }
.where {
  &-tabs {
    &-container {
      padding: 0 20px;
      border: $border-metal;
      margin-bottom: 30px; } }
  &-item {
    position: relative;
    padding: 28px 0 28px 150px;
    &:not(:last-child) {
      border-bottom: $border-metal; }
    &__img {
      position: absolute;
      top: 28px;
      left: 0;
      width: 123px; }
    .caption-16 {
      margin-bottom: 25px; }
    a {
      font-weight: bold;
      &:hover {
        text-decoration: none;
        color: $black; } }
    .text {
      font-weight: 200;
      line-height: 21px;
      &:nth-child(2) {
        margin-bottom: 25px; } }
    &__list {
      color: $turquoise;
      line-height: 21px;
      p {
        font-weight: 200; }
      span {
        margin-left: 5px;
        margin-right: 5px; } }
    &__middle {
      float: left;
      width: 327px;
      padding-right: 40px; } } }

.map-link {
  display: inline-block;
  padding-left: 23px;
  background: url(../img/map-marker.png) 0 0 no-repeat;
  text-decoration: underline;
  color: $turquoise;
  text-decoration-style: dashed;
  line-height: 22px;
  span {
    color: $black;
    cursor: pointer; } }

#where-map {
  padding-top: 28px;
  padding-bottom: 28px;
  height: 550px; }

.where {
  .ui-selectmenu-button.ui-button {
    display: none; } }

.about {
  .catalog-nav {
    ul {
      border: none; }
    a {
      padding: 0; }
    .caption-16 {
      .switcher {
        height: 46px;
        line-height: 46px;
        margin-top: -23px; } } }
  .ui-selectmenu-button.ui-button {
    display: none; }
  &-shops {
    .caption {
      margin-bottom: 25px;
      font-size: 22px; }
    a {
      display: inline-block;
      width: 100%;
      padding: 13px 18px;
      color: $black;
      font-size: 16px;
      &:hover {
        text-decoration: none;
        color: $turquoise; } }
    span {
      @extend a;
      background-color: $bg-light-gray;
      &:hover {
        color: $black; } }
    ul {
      border: $border-metal; }
    li:not(:last-child) {
      border-bottom: $border-metal; } }
  .home-advantages {
    padding-top: 25px;
    margin-bottom: 0;
    padding-bottom: 0;
    background: #fff;
    .caption {
      margin-bottom: 35px;
      font-weight: normal;
      font-size: 25px; }
    &__item {
      width: 33.33%;
      margin-bottom: 41px;
      &:nth-child(4) {
        margin-left: 16.66%; } } }
  .home__icon {
    border-radius: 50%;
    box-shadow: 0 1px 1px 2px rgba(0, 0, 0, .1); }
  &__banner {
    margin-bottom: 15px;
    margin-top: 13px; } }

.content {
  margin-bottom: 30px;
  h2 {
    font-weight: normal;
    font-size: 25px; }
  p {
    font-weight: 200;
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0; } } }

.about-partner {
  margin-top: 57px;
  margin-bottom: 20px;
  line-height: 111px;
  .caption {
    display: inline-block;
    vertical-align: middle;
    padding-left: 20px;
    font-weight: normal;
    font-size: 25px; } }

.support {
  h1 {
    margin-bottom: 35px; }
  h2 {
    font-size: 22px; }
  &-wrapper {
    padding-left: 70px;
    ol {
      padding: 0;
      list-style: none;
      counter-reset: support-count;
      & > li::before {
        position: absolute;
        content: counter(support-count);
        counter-increment: support-count;
        font-weight: bold;
        width: 57px;
        height: 57px;
        top: -15px;
        left: -77px;
        text-align: center;
        line-height: 57px;
        color: $turquoise; } }
    li {
      position: relative;
      margin-bottom: 30px;
      font-size: 18px;
      line-height: 30px;
      font-weight: 200;
      p {
        margin-bottom: 10px; } }
    p {
      @extend li; } } }

@media screen and (max-width: 1199px) {
  .lk-message {
    &__th {
      &:nth-child(1) {
        width: 109px; }
      &:nth-child(2) {
        width: 480px; }
      &:nth-child(3) {
        width: 95px; } }
    &__td {
      &:nth-child(1) {
        width: 109px;
        padding-left: 0;
        padding-right: 0; }
      &:nth-child(2) {
        width: 480px; }
      &:nth-child(3) {
        width: 95px;
        padding-left: 0;
        padding-right: 0; } } }
  .lk-favorites {
    margin-left: 0;
    .card-mini {
      margin-left: 0;
      margin-right: -1px;
      width: calc(33.33% + 1px); }
    .add-favorites span {
      right: 32px; } }
  .lk-orders-item {
    padding-right: 7px;
    padding-left: 30px;
    .caption-16 {
      width: 349px;
      margin-bottom: 50px; }
    .lk__leave-comment {
      right: auto;
      left: 309px;
      top: 95px; } }
  .lk {
    h1 {
      margin-bottom: 30px; }
    .col-lg-3 {
      float: left;
      width: 312px; }
    .col-lg-9 {
      width: 712px;
      float: left; }
    .publications-nav {
      ul {
        display: block; }
      li {
        width: 100%;
        &:not(:last-child) {
          border-right: none;
          border-bottom: $border-metal; } } } }
  .lk-passwords {
    label {
      input {
        margin-right: 45px; } }
    .form-button {
      margin-right: 45px; } }
  .where {
    .col-lg-3 {
      float: left;
      width: 312px; }
    .col-lg-9 {
      width: 712px;
      float: left; }
    &-item__middle {
      width: 310px; } }
  .about {
    .catalog-nav {
      display: block; }
    .col-lg-3 {
      float: left;
      width: 312px; }
    .col-lg-9 {
      width: 712px;
      float: left; }
    .home__icon {
      width: 60px;
      height: 60px;
      margin-top: -5px;
      margin-bottom: 15px;
      background-size: 30px auto; }
    .home__time {
      background-position: 16px 13px; }
    .home__car {
      position: relative;
      top: 5px; }
    .home-advantages {
      display: block; } } }

@media screen and (max-width: 1023px) {
  .about-shops {
    &>.caption-16 {
      display: block; } }
  .about {
    .catalog-nav {
      display: none; } }
  .lk-message {
    &__nav {
      margin-top: 0; }
    &__th {
      &:nth-child(1) {
        width: 92px; }
      &:nth-child(2) {
        width: 280px; }
      &:nth-child(3) {
        width: 77px; } }
    &__td {
      &:nth-child(1) {
        width: 92px;
        font-size: 12px; }
      &:nth-child(2) {
        width: 280px; }
      &:nth-child(3) {
        width: 77px;
        font-size: 12px; } } }
  .lk-favorites {
    .card-mini {
      width: calc(50% + 1px);
      margin-bottom: 24px;
      &:last-child:nth-child(2n-1) {
        margin-left: calc(50%/2); } } }
  .lk-orders-item {
    padding: 30px 25px;
    &__bottom {
      position: static; }
    &__color {
      float: none;
      margin-bottom: 20px; }
    .lk__leave-comment {
      top: auto;
      bottom: 30px;
      left: 165px; }
    .img-bg {
      width: 120px;
      height: 90px;
      margin-right: 20px;
      margin-bottom: 100px; }
    .caption-16 {
      margin-top: -6px;
      width: 228px;
      font-size: 14px;
      span {
        font-size: 18px; } }
    .cart-card-actions {
      position: static;
      width: auto;
      .stars i:last-of-type {
        margin-right: 0px; } } }
  input:focus~.lk__save {
    display: none; }

  label:hover .lk__save {
    display: none; }

  .lk-passwords {
    label {
      padding-right: 10px;
      margin-bottom: 5px;
      input {
        width: 100%;
        margin-right: 0;
        float: none; } }
    .form-button {
      margin-right: 11px;
      margin-top: 20px; } }
  .lk {
    h1 {
      text-align: center;
      margin-bottom: 20px; }
    .col-lg-3 {
      width: 277px;
      margin-top: 148px; }
    .col-lg-9 {
      width: 476px; }
    .about__banner {
      margin-left: -277px;
      max-width: 723px;
      margin-bottom: 23px; }
    &-info {
      padding-right: 10px; }
    &__change {
      margin-right: 0; } }
  .faq {
    h1 {
      margin-right: 0;
      text-align: center;
      float: none;
      margin-top: -15px;
      margin-bottom: 10px; }
    .ui-selectmenu-button.ui-button {
      float: none;
      display: block;
      margin-left: auto;
      margin-right: auto; }
    p {
      font-size: 16px;
      line-height: 24px; }
    &-answer {
      height: 122px;
      &::after {
        display: block; } } }
  .where {
    .ui-selectmenu-button.ui-button {
      width: 477px;
      display: inline-block;
      float: right; }
    .col-lg-9, .col-lg-3 {
      width: 100%; } }
  .about {
    .col-lg-9, .col-lg-3 {
      width: 100%; }
    h1 {
      text-align: center; }
    .ui-selectmenu-button.ui-button {
      width: 477px;
      display: inline-block;
      float: right; }
    &-shops {
      ul {
        display: none; }
      .caption {
        float: left; } } } }

@media screen and (max-width: 767px) {
  .lk-message {
    &__th {
      &:nth-child(1) {
        width: 70px;
        flex-shrink: 0; }
      &:nth-child(3) {
        width: 70px;
        flex-shrink: 0; } }
    &__td {
      padding-top: 10px;
      padding-bottom: 10px;
      p {
        font-size: 13px;
        line-height: 18px; }
      &:nth-child(1) {
        width: 70px;
        flex-shrink: 0; }
      &:nth-child(2) {
        padding-left: 10px;
        padding-right: 10px; }
      &:nth-child(3) {
        width: 70px;
        flex-shrink: 0; } } }
  .lk-favorites {
    margin-top: 0; }
  .lk {
    h1 {
      margin-top: -15px;
      margin-bottom: 10px; }
    h2 {
      margin-bottom: 30px;
      font-size: 22px; }
    .col-lg-3 {
      width: 100%;
      margin-top: 0; }
    .col-lg-9 {
      width: 100%; }
    .about__banner {
      margin-bottom: 20px;
      display: none;
      margin-top: 0;
      max-width: 100%;
      margin-left: 0; }
    &-info {
      margin-bottom: 30px; } }
  .lk-inputs label input {
    width: 190px;
    margin-right: 10px; }
  .faq {
    .ui-selectmenu-button.ui-button {
      width: 267px; }
    &-wrapper {
      margin-top: 30px; }
    &-question {
      padding: 13px 23px; } }
  .where-item__middle {
    padding-left: 0;
    max-width: 100%;
    margin-bottom: 15px; }
  .content {
    p {
      font-size: 16px;
      line-height: 24px; } }
  .support {
    h1 {
      text-align: center; }
    h2 {
      font-size: 16px; } }
  .support-wrapper li, .support-wrapper p {
    font-size: 16px;
    line-height: 24px; }
  .about-partner {
    text-align: center;
    margin-top: 0;
    .caption {
      display: block;
      margin-top: 17px;
      text-align: left;
      padding-left: 0; } }
  .where {
    .ui-selectmenu-button.ui-button {
      width: 263px; }
    h1 {
      margin-top: -10px;
      text-align: center; }
    .caption {
      font-size: 18px; } }
  .about {
    .caption {
      font-size: 18px; }
    .ui-selectmenu-button.ui-button {
      width: 263px; }
    .home-advantages__item:nth-child(n) {
      margin-left: 0;
      width: 50%;
      font-size: 14px;
      margin-bottom: 25px;
      .home__icon {
        margin-right: 15px; } } } }

@media screen and (max-width: 479px) {
  .lk-favorites {
    margin-top: 10px;
    .card-mini {
      width: 100%;
      &:last-child:nth-child(2n-1) {
        margin-left: 0; } } }
  .lk-tabs__item {
    width: 100%;
    &:not(:last-child) {
      border-right: none;
      border-bottom: $border-metal; } }
  .lk-orders-item {
    padding: 15px;
    .img-bg {
      float: none;
      margin-bottom: 10px;
      margin-left: auto;
      margin-right: auto; }
    .caption-16 {
      width: 100%;
      margin-bottom: 10px; }
    .cart-card-actions {
      margin-bottom: 10px; }
    &__color {
      margin-bottom: 10px; }
    .lk__leave-comment {
      bottom: 58px;
      left: auto;
      right: 15px; } }
  .lk {
    h1 {
      margin-top: 0; }
    h2 {
      font-size: 16px; } }
  .lk-inputs label input {
    width: 190px;
    margin-right: 10px; }

  .lk__change {
    margin-right: 10px; }

  .lk__save {
    display: inline-block; }

  input:focus~.lk__save {
    display: inline-block; }

  label:hover .lk__save {
    display: inline-block; }
  .faq {
    h1 {
      margin-top: 0; } }

  .where {
    h1 {
      margin-top: 0;
      margin-bottom: 10px; } }
  .where-item {
    padding-left: 10px;
    padding-right: 10px; }
  .where-item__img {
    position: static;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 15px; }
  .where {
    .ui-selectmenu-button.ui-button {
      width: 155px;
      float: none;
      margin-bottom: 15px;
      margin-right: auto;
      margin-left: auto;
      display: block; }
    .about-shops .caption {
      float: none;
      text-align: center; } }
  .support-wrapper ol li::before {
    left: -49px; }
  .support {
    h1 {
      margin-bottom: 5px; }
    &-wrapper {
      padding-left: 35px; } }
  .about {
    .caption {
      font-size: 16px; }
    .ui-selectmenu-button.ui-button {
      width: 155px; }
    .home-advantages__item:nth-child(n) {
      width: 100%; } } }
