.footer {
  &-subscribe {
    height: 88px;
    line-height: 89px;
    background-color: $turquoise;
    color: #fff;
    position: relative;
    .text {
      width: 467px;
      float: left;
      position: relative;
      top: 15px;
      display: inline-block;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: 0;
      vertical-align: middle; }
    &__form {
      display: inline-block;
      vertical-align: middle;
      label {
        height: 40px;
        float: left;
        width: 490px;
        background-color: #fff;
        border-radius: 4px;
        vertical-align: middle;
        input {
          border: none;
          height: 22px;
          margin-left: 24px;
          width: 215px;
          position: relative;
          top: -24px;
          border-left: 1px solid $turquoise;
          border-radius: 0;
          vertical-align: middle; } }
      .footer__envelope {
        position: relative;
        top: -24px;
        margin-left: 30px; }
      button {
        float: left;
        height: 40px;
        line-height: 36px;
        margin-left: 10px;
        padding-left: 54px;
        padding-right: 54px;
        background-color: $turquoise;
        border: 2px solid #fff;
        border-radius: 4px; } } }
  &__envelope {
    display: inline-block;
    width: 30px;
    height: 21px;
    vertical-align: middle;
    background: url(../img/footer-envelope.png) center no-repeat; } }

.footer {
  color: #939393;
  a {
    color: #939393; }
  &-main {
    background: url(../pictures/footer-bg.jpg) center no-repeat;
    -webkit-background-size: cover;
    background-size: cover; }
  &-top {
    padding-top: 35px;
    padding-bottom: 30px; }
  &__caption {
    margin-bottom: 25px;
    font-size: 16px;
    text-transform: uppercase; }
  &__list {
    margin: 0;
    li:not(:last-child) {
      margin-bottom: 10px; } }
  &-item {
    float: left;
    width: 270px;
    &_2 {
      width: 330px; }
    &_3 {
      width: 370px;
      padding-left: 15px; }
    &_4 {
      width: 200px; } }
  &__phone {
    margin-bottom: 12px;
    a {
      display: inline-block;
      width: auto;
      margin-bottom: 11px;
      text-decoration: none;
      color: #fff;
      font-size: 18px;
      line-height: 20px;
      border-bottom: 1px dashed #fff;
      &:hover {
        border-color: transparent; } } }
  &__socials {
    margin: 0 0 35px -2px;
    li {
      padding-left: 2px;
      padding-right: 19px; }
    i {
      display: inline-block;
      color: #fff;
      background-color: $turquoise;
      width: 25px;
      height: 25px;
      border-radius: 5px;
      text-align: center;
      line-height: 25px; } }
  &__icon {
    display: inline-block;
    vertical-align: middle;
    height: 28px;
    width: 51px;
    background-position: center;
    background-repeat: no-repeat;
    &:not(:last-child) {
      margin-right: 17px; } }
  &__visa {
    background-image: url(../img/footer-visa.png); }
  &__master {
    background-image: url(../img/footer-master-card.png); }
  &__card {
    background-image: url(../img/footer-card.png); } }

.footer {
  &-bottom {
    height: 39px;
    line-height: 39px;
    background-color: rgba(0, 0, 0, .4);
    .text-right {
      float: right;
      a {
        margin-left: 4px;
        color: #fff;
        display: inline-block;
        line-height: 16px;
        border-bottom: 1px dashed #fff;
        &:hover {
          text-decoration: none;
          border-bottom-color: transparent; } } }
    .text-left {
      float: left; } } }

.footer__scroll-top {
  padding-right: 20px;
  display: block;
  z-index: 10;
  font-size: 0;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  .fa {
    font-size: 20px;
    margin-right: 20px;
    vertical-align: middle; } }


@media all and (min-width: 1500px) {
    .footer__scroll-top {
      font-size: 14px; } }

@media all and (max-width: 1140px) {
  .footer__scroll-top {
    display: none!important; } }


.scroll-top {
  display: none;
  width: 0;
  height: 0;
  position: sticky;
  bottom: 10px;
  right: 10px;
  z-index: 50;
  button {
    width: 49px;
    position: absolute;
    left: -49px;
    top: -49px;
    height: 49px;
    padding-top: 0px;
    font-size: 30px;
    color: #fff;
    border-radius: 50%;
    background-color: #b5b4b4;
    text-align: center;
    border: none;
    &:hover {
      background-color: $gray; } } }

@media all and (max-width: 1199px) {
  .footer-subscribe {
    .text {
      width: 380px; }
    &__form {
      label {
        width: 387px; }
      button {
        margin-left: 25px; } } }
  .footer-item {
    width: 210px;
    &_2 {
      width: 300px; }
    &_3 {
      width: 275px; }
    &_4 {
      width: 200px; } } }



@media all and (max-width: 1024px) {
  .footer-bottom__links {
    margin-left: 52px;
    float: left;
    a {
      font-size: 12px; } } }

@media all and (max-width: 1023px) {
  .footer__socials li {
    padding-right: 9px; }
  .footer__icon:not(:last-child) {
    margin-right: 0; }
  .footer__caption {
    line-height: 18px; }
  .footer-subscribe .text {
    width: 310px;
    font-size: 16px;
    line-height: 18px; }
  .footer-subscribe__form label {
    width: 200px; }
  .footer-subscribe__form .footer__envelope {
    display: none; }
  .footer-subscribe__form label input {
    width: 150px;
    border-left: none;
    margin-left: 0;
    padding-left: 20px; }
  .footer-item {
    width: 25%;
    &_4 {
      padding-left: 30px; } }
  .footer__socials i {
    margin-bottom: 5px; } }

@media all and (max-width: 767px) {
  .footer {
    text-align: center;
    &-top {
      background-position: center bottom;
      background-size: auto; }
    &-subscribe {
      height: auto;
      text-align: center;
      .text {
        margin-bottom: 10px;
        width: 100%; } } }
  .footer-subscribe__form {
    label {
      width: 150px;
      display: inline-block;
      input {
        width: 100%;
        font-size: 12px; } }
    button {
      padding-left: 20px;
      padding-right: 20px; } }
  .footer-item {
    width: 100%; }
  .footer-item_1 .footer__caption::after,
  .footer-item_2 .footer__caption::after {
    display: inline-block;
    vertical-align: middle;
    content: '+';
    font-size: 20px;
    margin-left: 10px;
    color: #fff; }
  .footer {
    &__list {
      display: none; }
    &__caption {
      margin-top: 8px;
      margin-bottom: 8px;
      font-size: 14px;
      &.active {
        color: #fff;
        &::after {
          content: '–'; } } } }
  .active + .footer__list {
    display: block; }
  .footer-top {
    padding-top: 20px; }
  .footer-item {
    &_2 {
      padding-bottom: 13px;
      border-bottom: 1px solid #676767; }
    &_3 {
      padding-left: 0;
      padding-top: 13px;
      .footer__caption {
        display: none; } }
    &_4 {
      padding-left: 0;
      .footer__caption {
        position: relative;
        &:first-child {
          top: 65px; }
        &:nth-child(3) {
          top: -65px; } } } }
  .footer__socials {
    position: relative;
    top: 65px; }
  .footer__methods {
    position: relative;
    top: -65px; }
  .footer__phone {
    margin-bottom: 17px;
    line-height: 16px;
    width: 266px;
    margin-left: auto;
    margin-right: auto;
    &:nth-child(2) {
      width: 313px; }
    br {
      display: none; }
    a {
      margin-bottom: 0;
      margin-right: 15px;
      &::after {
        content: '-';
        display: inline-block;
        margin-left: 4px;
        width: 0;
        position: absolute; } } }
  .footer-bottom__links {
    display: none; }
  .footer-bottom {
    height: auto;
    .text-left {
      float: none;
      text-align: center;
      position: relative;
      top: 36px; }
    .text-right {
      float: none;
      text-align: center;
      position: relative;
      top: -36px; } }
  .footer__socials {
    margin-bottom: 5px; } }

@media all and (max-width: 479px) {
  .footer {
    &-subscribe__form {
      button {
        margin-left: 16px;
        padding-left: 15px;
        padding-right: 15px; } } }
  .footer__phone:nth-child(2) {
    width: 302px; } }

