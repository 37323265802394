@import "variables";

@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto-Light.ttf) format('truetype');
  font-weight: 200; }

@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto-Regular.ttf) format('truetype');
  font-weight: normal; }

@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto-LightItalic.ttf) format('truetype');
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto-Bold.ttf) format('truetype');
  font-weight: bold; }

@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto-Medium.ttf) format('truetype');
  font-weight: 500; }

@font-face {
  font-family: Arial;
  src: url(../fonts/ARIALN.TTF) format('truetype');
  font-weight: normal; }

@font-face {
  font-family: Arial;
  src: url(../fonts/ARIALNB.TTF) format('truetype');
  font-weight: bold; }

img {
  max-width: 100%; }

a {
  transition-duration: .2s;
  color: $turquoise;
  &:hover {
    color: $turquoise; }
  &:focus {
    text-decoration: none;
    color: inherit;
    outline: none; }
 }  //i
//transition-duration: .2s

h1 {
  margin-top: 0;
  margin-bottom: 19px;
  font-size: 26px;
  font-weight: bold; }

h2 {
  font-size: 24px;
  font-weight: bold;
  color: $black;
  margin-bottom: 20px; }

html {
  overflow-x: hidden;
  width: 100%; }

p {
  font-size: 16px;
  line-height: 24px;
  color: #333;
  letter-spacing: 0; }

iframe {
  border: none; }

* {
  outline: none; }

input[type="text"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
input[type="tel"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
input[type="search"]:-ms-input-placeholder {
  color: $gray; }

.container {
  width: 1200px; }

.inherits {
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  text-decoration: inherit; }

.caption {
	font-weight: bold;
	font-size: 26px;
	line-height: 1.5;
	letter-spacing: 0; }

button {
  transition-duration: .1s; }

.flex {
  display: flex;
  & > * {
    display: inline-block; } }

.overflow {
  overflow: hidden; }

body {
  position: relative;
  font-family: Roboto, Arial, sans-serif;
  letter-spacing: .02em; }

.fa- {
  &instagram:hover {
    background: $instagram-bg; }
  &google-plus:hover {
    background-color: $google-bg; }
  &odnoklassniki:hover {
    background-color: $ok-bg; }
  &twitter:hover {
    background-color: $twitter-bg; }
  &youtube-play:hover {
    background-color: red; } }

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="password"],
input[type="search"] {
  width: 100%;
  height: 34px;
  border: 1px solid #999;
  border-radius: 3px;
  font-weight: normal;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 13px;
  color: $black; }

.btn.btn--blue-transparent {
  display: inline-block;
  height: 40px;
  padding: 0  42px 0 55px;
  color: #fff;
  line-height: 40px;
  background: url(../img/buy-cart.png) 16px 7px no-repeat;
  background-color: #39b455;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  border: none;
  white-space: nowrap;
  &:hover {
    background-color: #32a14b;
    text-decoration: none;
    color: #fff; } }
label {
  font-weight: normal;
  position: relative; }

.red {
  color: $red; }

.ui-selectmenu-button.ui-button {
  background-color: #fff;
  width: 100%;
  height: 34px;
  border-color: $light-gray;
  color: #333;
  font-size: 13px;
  line-height: 21px;
  padding-left: 17px;
  &:active {
    color: #333; }
  .ui-selectmenu-icon.ui-icon {
    background: url(../img/popup-sprites.png) 3px -13px no-repeat; } }

.ui-menu .ui-menu-item-wrapper {
  height: 35px;
  line-height: 29px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 17px; }

.ui-state-active, .ui-widget-content .ui-state-active {
  background-color: $bg-light-gray;
  color: $turquoise;
  height: 37px;
  border-color: #d0cfcf;
  line-height: 29px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 17px; }

//li:first-child
//  .ui-state-active, .ui-widget-content .ui-state-active
//    margin-top: 0

.form-button {
  width: 100%;
  display: inline-block;
  background-color: $turquoise;
  color: #fff;
  border: 1px solid $turquoise;
  border-radius: 5px;
  height: 34px;
  line-height: 32px;
  font-size: 13px;
  text-transform: uppercase;
  transition-duration: .2s;
  text-align: center;
  &:hover {
    background-color: #fff;
    text-decoration: none;
    color: $turquoise; }
  &:active {
    box-shadow: inset 4px 4px 2px 0 rgba(0,0,0,0.2); } }

.text-16 {
  font-size: 16px; }

.request {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0;
  text-align: center;
  &__icon {
    width: 47px;
    height: 47px;
    margin-bottom: 25px;
    background: url(../img/form-request.png) center no-repeat; } }

.checkbox {
  font-size: 13px;
  line-height: 18px;
  padding-left: 18px;
  cursor: pointer;
  user-select: none;
  a {
    color: $turquoise;
    text-decoration: underline;
    &:hover {
      text-decoration: none; } } }
.check {
  position: absolute;
  left: 0;
  top: 3px;
  display: inline-block;
  width: 11px;
  height: 11px;
  vertical-align: middle;
  border: 1px solid $black; }
input:checked+.check::before {
  content: '';
  display: block;
  width: 15px;
  height: 15px;
  position: absolute;
  bottom: -4px;
  left: -1px;
  background: url(../img/popup-sprites.png) 0 0 no-repeat; }

.columns {
  columns: 3; }

.forget {
  font-size: 16px;
  color: $turquoise;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
    color: $turquoise; } }

.overton {
  &-sm {
    display: none; } }

#content {
  position: relative;
  &>* {
    transition-duration: .2s;
    will-change: transform; }
  &.content_270 {
    overflow: hidden; } }

.card-mini .price.red {
  color: $red; }

.green {
  color: $green; }

.cart-green {
  display: inline-block;
  width: 28px;
  height: 25px;
  background: url(../img/mob-cart.png) center no-repeat; }

@media all and (max-width: 1199px) {
  .container {
    width: 1024px; } }

@media all and (max-width: 1024px) {
  .overton-sm {
    display: block; }
  #content.content_270>* {
    //left: 275px
    transform: translate(285px, 0); } }

@media all and (max-width: 1023px) {
  html.html-over {
    overflow: hidden; }
  .container {
    width: 768px; }
  h1 {
    font-size: 24px; } }

@media all and (max-width: 767px) {
  .container {
    width: 480px; } }

@media all and (max-width: 479px) {
  .container {
    width: 320px; } }

